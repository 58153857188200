import React, { useCallback } from "react";
import { styled, useToasts } from "@puzzle/ui";
import { FullTransactionFragment } from "../graphql.generated";
import { useActiveCompany } from "components/companies";
import Analytics from "lib/analytics";
import { useAskAI } from "components/AI/useAskAI";
import { BaseAskAIButton } from "./BaseAIButton";

const A = styled("a", {
  color: "$greenalpha",
  textDecoration: "none",
});

export const TriggerLastMileButton = ({
  transaction,
  onClick,
  includeText = true,
}: {
  transaction: FullTransactionFragment;
  onClick?: () => void;
  includeText?: boolean;
}) => {
  const { toast } = useToasts();
  const { company } = useActiveCompany<true>();
  const { triggerLastMileWorkflow } = useAskAI();

  const handleError = useCallback(() => {
    toast({
      title: `Could not connect to AI`,
      message: `Our AI provider, OpenAI, may be down right now`,
      status: "error",
      onAction: () => {
        triggerLastMileWorkflow(transaction, { onError: handleError });
      },
      actionText: "Try Again",
      duration: 10000,
    });
  }, [toast, triggerLastMileWorkflow, transaction]);

  const handleOnClick = useCallback(() => {
    triggerLastMileWorkflow(transaction, { onError: handleError });
    if (onClick) {
      onClick();
    }
    Analytics.transactionAiButtonClicked({
      transactionId: transaction.id,
      companyId: company.id,
      companyConsentsToAI: company.consentsToAI ? "true" : "false",
    });
  }, [triggerLastMileWorkflow, onClick, transaction, company, handleError]);

  return (
    <BaseAskAIButton onClick={handleOnClick} text={includeText ? "AI Categorizer" : undefined} />
  );
};
