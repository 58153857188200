import React from "react";
import { Button } from "ve";
import { Dialog, useToasts } from "@puzzle/ui";
import { useVoidFixedAssetMutation, GetFixedAssetsDocument } from "components/dashboard/Accounting/FixedAssetsV2/graphql.generated";
import { useCallback } from "react";
import Analytics from "lib/analytics";
import { GetFixedAssetByIdQuery } from "../../graphql.generated";
import { zIndex } from "@puzzle/utils";

type VoidFixedAssetDialogProps = {
    open: boolean
    asset: GetFixedAssetByIdQuery;
    onCancel: () => void;
    onFixedAssetVoided: () => void
}

export const VoidFixedAssetDialog = ({
    open,
    asset,
    onCancel,
    onFixedAssetVoided
}: VoidFixedAssetDialogProps) => {

    const { toast } = useToasts();
    const [voidFixedAsset, { loading }] = useVoidFixedAssetMutation({
        refetchQueries: [GetFixedAssetsDocument],
    });

    const handleVoid = useCallback(async () => {
        return await voidFixedAsset({
            variables: { id: asset.fixedAsset.id },
            onCompleted: ({ voidFixedAsset }) => {
                toast({
                    title: "Fixed asset voided",
                    message: `You voided the fixed asset for ${voidFixedAsset.description}. The changes are being processed and will be reflected on the general ledger page shortly.`,
                    status: "success",
                });

                Analytics.fixedAssetVoided({ fixedAssetId: voidFixedAsset.id });

                onFixedAssetVoided();
            },
            onError: () => {
                toast({
                    message: `Something went wrong, and our team has been notified. We apologize for the inconvenience. Please try again later.`,
                    status: "warning",
                });
            }
        });
    }, [voidFixedAsset, toast, onFixedAssetVoided, asset]);

    return (
        <Dialog
            style={{ zIndex: zIndex('modal') }}
            size="xsmall"
            open={open}
            onOpenChange={onCancel}
        >
            <Dialog.Title>Are you sure you want to void this fixed asset?</Dialog.Title>
            <Dialog.Body>This action cannot be undone.</Dialog.Body>
            <Dialog.Footer align="right">
                <Dialog.Actions>
                    <Dialog.Close asChild>
                        <Button variant="minimal">Cancel</Button>
                    </Dialog.Close>
                    <Button variant="negative" loading={loading} onClick={handleVoid}>
                        Void
                    </Button>
                </Dialog.Actions>
            </Dialog.Footer>
        </Dialog>
    );
};
