import { ADDONS } from "components/monetization/PricingModal/plans";
import { AddOnNames, PricingPlanNames, AddOn } from "components/monetization/types";
import { differenceInDays } from "date-fns";

export const PROD_FREE_TRIAL_DAYS = 14;

export const FORMATION_SPENDING_LIMIT = 15000;

export const getAveragePercentage = (
  expenses: number,
  limit: number = FORMATION_SPENDING_LIMIT
) => {
  const progress =
    expenses > limit
      ? Math.max(0, (1 - (expenses - limit) / limit) * 100)
      : Math.max(0, (expenses / limit) * 100);
  return {
    progress,
    exceeding: expenses > limit,
  };
};

export function getFreeTrialValues(freeTrialStartDate: Date, freeTrialExpirationDate: Date) {
  const totalDaysOnFreeTrial = differenceInDays(freeTrialExpirationDate, freeTrialStartDate);
  const daysPassedOnFreeTrial = differenceInDays(new Date(), freeTrialStartDate);
  const daysLeftOnFreeTrial = totalDaysOnFreeTrial - daysPassedOnFreeTrial;
  const percentage = (daysPassedOnFreeTrial / totalDaysOnFreeTrial) * 100;
  return {
    percentage,
    daysLeftOnFreeTrial,
    totalDaysOnFreeTrial,
  };
}

export const getAvailableAddOnsBilling = (
  currentAddOns: AddOnNames[],
  showRipplingAddOn = false,
  plan?: string
) => {
  if (plan === PricingPlanNames.FREE) {
    return [];
  }

  if (plan === PricingPlanNames.PAID_PLAN_2) {
    if (showRipplingAddOn) {
      return [ADDONS.find((addon) => addon.id === AddOnNames.ADDON_RIPPLING) as AddOn];
    } else {
      return [];
    }
  }

  return ADDONS.filter((addon) => {
    if (currentAddOns.includes(addon.id)) {
      return false;
    }
    if (addon.id === AddOnNames.ADDON_RIPPLING && !showRipplingAddOn) {
      return false;
    }
    return true;
  });
};
