import React from "react";
import { DateInput, CurrencyInput, Input, Autocomplete, IconButton, Tooltip } from "@puzzle/ui";
import { toCalendarDate, zIndex } from "@puzzle/utils";
import { Controller, UseFormReturn } from "react-hook-form";
import { Duplicate, Delete } from "@puzzle/icons";
import { CategoryFragment } from "graphql/types";
import { ManualImportFormValues } from "./useManualImportForm";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";

const ManualImportRow = ({
  index,
  onRemove,
  onDuplicate,
  form,
  categoriesByPermaKey,
  categories,
}: {
  index: number;
  onRemove?: () => void;
  onDuplicate: () => void;
  form: UseFormReturn<ManualImportFormValues>;
  categoriesByPermaKey?: Partial<Record<string, CategoryFragment>>;
  categories: CategoryFragment[];
}) => {
  const formStateErrors = form.formState.errors.items?.[index] ?? {};
  return (
    <tr onBlur={() => form.trigger()}>
      <td>
        <Controller
          control={form.control}
          name={`items.${index}.date`}
          render={({ field }) => {
            return (
              <DateInput
                {...form.register(field.name)}
                size="mini"
                value={field.value ?? undefined}
                feedback={formStateErrors.date ? "negative" : undefined}
                aria-label="Import date picker"
                onChange={(value) => {
                  field.onChange({
                    target: {
                      value: value && toCalendarDate(value),
                      name: field.name,
                    },
                  });
                }}
              />
            );
          }}
        />
      </td>
      <td>
        <Controller
          control={form.control}
          name={`items.${index}.amount`}
          render={({ field }) => {
            return (
              <CurrencyInput
                size="small"
                truncateWhenInactive={false}
                placeholder="$0"
                value={field.value}
                feedback={formStateErrors.amount ? "negative" : undefined}
                onValueChange={({ value }) => {
                  field.onChange({
                    target: { value, name: field.name },
                  });
                }}
              />
            );
          }}
        />
      </td>
      <td>
        <Controller
          control={form.control}
          name={`items.${index}.description`}
          render={({ field }) => {
            return (
              <Input
                {...form.register(field.name)}
                size="small"
                feedback={formStateErrors.description ? "negative" : undefined}
                {...field}
              />
            );
          }}
        />
      </td>
      <td>
        <Controller
          control={form.control}
          name={`items.${index}.category`}
          render={({ field }) => {
            return (
              <Autocomplete<CategoryFragment, false, true, false>
                {...form.register(field.name)}
                placeholder="Select"
                size="mini"
                getOptionLabel={(option) => option.name}
                getOptionKey={(o) => o.name}
                isOptionEqualToValue={(x, y) => x.permaKey === y.permaKey}
                value={field.value ? categoriesByPermaKey?.[field.value.permaKey] : undefined}
                options={categories}
                onChange={(_, newValue) => {
                  field.onChange({
                    target: {
                      value: newValue,
                      name: field.name,
                    },
                  });
                }}
                css={{
                  zIndex: isPosthogFeatureFlagEnabled(FeatureFlag.Z) ? zIndex("modalMenu") : "auto",
                }}
              />
            );
          }}
        />
      </td>
      <td>
        <Tooltip content="Duplicate" side="bottom">
          <IconButton onClick={onDuplicate}>
            <Duplicate />
          </IconButton>
        </Tooltip>
      </td>
      <td>
        <Tooltip content="Remove" side="bottom">
          {onRemove && (
            <IconButton onClick={onRemove}>
              <Delete />
            </IconButton>
          )}
        </Tooltip>
      </td>
    </tr>
  );
};
export default ManualImportRow;
