import { useMemo, useEffect } from "react";
import { useLocalStorage } from "react-use";

import useAppRouter from "./useAppRouter";

/**
 * Hook to manage a state value that is stored in local stoage -and- passed as a query parameter.
 *
 * The use cases are as follows:
 * - Generate query params to reflect the current state of the component for sharing
 * - Persist the state in local storage when it changes in the component only, not when passed in as query param
 * - If a query param is present, the returned value will be the query param
 * - If no query param is present, the returend value will be the local storage value
 * - otherwise, the default value will be returned
 */

const usePersistedBoolQueryParam = (
  localStorageKey: string,
  queryParam: string,
  defaultValue: boolean
) => {
  const { router, replaceQueryParam } = useAppRouter();
  const [localStorageValue, setLocalStorageValue] = useLocalStorage(localStorageKey, defaultValue);
  const queryParamValue = router.query[queryParam];

  const value = useMemo(() => {
    if (queryParamValue !== undefined) {
      return queryParamValue === "1";
    }
    if (localStorageValue !== undefined) {
      return localStorageValue;
    }
    return defaultValue;
  }, [queryParamValue, localStorageValue, defaultValue]);

  const setValue = (newValue: boolean) => {
    setLocalStorageValue(newValue);
    replaceQueryParam(queryParam, newValue ? "1" : "0");
  };

  // Ensure there's always a query param so that the url can always be shared
  useEffect(() => {
    if (queryParamValue === undefined) {
      replaceQueryParam(queryParam, value ? "1" : "0");
    }
  }, []);

  return [value, setValue] as const;
};

export default usePersistedBoolQueryParam;
