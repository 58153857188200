import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Disclaimer({
  width = 14,
  height = 20,
  //   color = "currentColor",
  fill = "none",
  viewBox = "0 0 14 20",
  ...iconProps
}: IconProps) {
  return (
    <Icon height={height} width={width} fill={fill} {...iconProps} viewBox={viewBox}>
      <path
        stroke="#95949B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.2 12.824a3.65 3.65 0 00-1.833-.491h-2.2A3.666 3.666 0 001.5 16h5.133M6.266 10.133a2.567 2.567 0 100-5.133 2.567 2.567 0 000 5.133zM8.1 14.532L9.566 16l2.934-2.934"
      ></path>
    </Icon>
  );
}
