import { useMemo, useCallback } from "react";
import { mergeDeep } from "@apollo/client/utilities";

import { useActiveCompany } from "components/companies";
import { CashFlow, FixedAssetStatus } from "graphql/types";

import {
  useFixedAssetCapitalizationQuery,
  useGetFixedAssetsQuery,
  useGetTransactionsForFixedAssetsQuery,
} from "../graphql.generated";
import { FilterState, FixedAssetTransactionsFilterState } from "./useFixedAssetsFilter";
import { toCalendarMonthString } from "@puzzle/utils";
import { isEmpty } from "lodash";

const DEFAULT_PER_PAGE = 25;

export function usePendingFixedAssetsQuery(skipInitial: boolean = false) {
  const { company } = useActiveCompany<true>();
  const { data, loading, fetchMore, variables, refetch } = useGetFixedAssetsQuery({
    skip: !company?.id || skipInitial,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    variables: {
      companyId: company.id,
      filterBy: {
        statuses: [FixedAssetStatus.Draft], // TODO: add FixedAssetStatus.Capitalized and don't allow editing for those in table.
      },
      page: { count: DEFAULT_PER_PAGE },
    },
  });

  const hasMore = data?.company?.fixedAssets?.pageInfo.hasNextPage;
  const fetchNextPage = useCallback(() => {
    fetchMore({
      variables: mergeDeep(variables, {
        page: {
          after: data?.company?.fixedAssets?.pageInfo.endCursor,
          count: DEFAULT_PER_PAGE,
        },
      }),
    });
  }, [fetchMore, variables, data]);

  return useMemo(
    () => ({
      pendingAssets: data?.company?.fixedAssets?.items,
      pendingAssetsLoading: loading,
      hasMorePendingAssets: hasMore,
      fetchNextPendingAssetsPage: fetchNextPage,
      refetchPendingAssets: refetch,
    }),
    [data?.company?.fixedAssets, loading, hasMore, fetchNextPage, refetch]
  );
}

export function useInServiceAssetsQuery(filter: FilterState) {
  const { company } = useActiveCompany<true>();
  const { data, loading, fetchMore, variables } = useGetFixedAssetsQuery({
    skip: !company?.id,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    variables: {
      companyId: company.id,
      filterBy: {
        statuses: filter.status
          ? [filter.status]
          : [FixedAssetStatus.InService, FixedAssetStatus.OnHold, FixedAssetStatus.Disposed],
      },
      page: { count: DEFAULT_PER_PAGE },
    },
  });

  const hasMore = data?.company?.fixedAssets?.pageInfo.hasNextPage;
  const fetchNextPage = useCallback(() => {
    fetchMore({
      variables: mergeDeep(variables, {
        page: {
          after: data?.company?.fixedAssets?.pageInfo.endCursor,
          count: DEFAULT_PER_PAGE,
        },
      }),
    });
  }, [fetchMore, variables, data]);

  return useMemo(
    () => ({
      inServiceAssets: data?.company?.fixedAssets?.items,
      inServiceAssetsLoading: loading,
      hasMoreInServiceAssets: hasMore,
      fetchNextInServiceAssetsPage: fetchNextPage,
    }),
    [data?.company?.fixedAssets, loading, hasMore, fetchNextPage]
  );
}

export function useFirstFixedAssetQuery() {
  const { company } = useActiveCompany<true>();
  const { data, loading, fetchMore } = useGetFixedAssetsQuery({
    skip: !company?.id,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    variables: {
      companyId: company.id,
      page: { count: 1 },
    },
  });
  return useMemo(
    () => ({
      hasAnyFixedAsset: !isEmpty(data?.company?.fixedAssets?.items),
      loading,
      fetchMore,
    }),
    [data?.company?.fixedAssets, loading, fetchMore]
  );
}

export function useFixedAssetsRollforwardQuery(filter: FilterState) {
  const { company } = useActiveCompany<true>();
  const fixedAssetCapitalizationQueryResult = useFixedAssetCapitalizationQuery({
    variables: {
      input: {
        companyId: company.id,
        filterBy: {
          periodRange: {
            fromInclusive: toCalendarMonthString(filter.rollforwardStartDate),
            toInclusive: toCalendarMonthString(filter.rollforwardEndDate),
          },
        },
      },
    },
  });
  return useMemo(
    () => ({
      fixedAssetCapitalization: fixedAssetCapitalizationQueryResult.data?.fixedAssetCapitalization,
      fixedAssetCapitalizationLoading: fixedAssetCapitalizationQueryResult.loading,
    }),
    [
      fixedAssetCapitalizationQueryResult.data?.fixedAssetCapitalization,
      fixedAssetCapitalizationQueryResult.loading,
    ]
  );
}

const DEFAULT_TRANSACTIONS_PER_PAGE = 25;

export function useFixedAssetsTransactionsQuery(filter: FixedAssetTransactionsFilterState) {
  const { company } = useActiveCompany<true>();
  const { data, loading, fetchMore, variables } = useGetTransactionsForFixedAssetsQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      companyId: company.id,
      page: { count: DEFAULT_TRANSACTIONS_PER_PAGE },
      filterBy: {
        includeLinkedToFixedAsset: false,
        cashFlow: CashFlow.MoneyOut,
        startDate: filter.startDate.toString(),
        endDate: filter.endDate.toString(),
        descriptor: filter.descriptor,
      },
    },
  });

  const hasMore = data?.company?.transactions.pageInfo.hasNextPage;
  const fetchNextPage = useCallback(() => {
    fetchMore({
      variables: mergeDeep(variables, {
        page: {
          after: data?.company?.transactions.pageInfo.endCursor,
          count: DEFAULT_TRANSACTIONS_PER_PAGE,
        },
      }),
    });
  }, [fetchMore, variables, data]);

  return useMemo(() => {
    return {
      transactions: data?.company?.transactions,
      loading,
      fetchNextPage,
      hasMore,
    };
  }, [data, loading, fetchNextPage, hasMore]);
}
