import React from "react";

import { Text } from "ve";

import useSingleTransaction from "components/dashboard/Transactions/hooks/useSingleTransaction";
import { PrimaryStatusActionButton } from "./ActionButtons";

import { FullTransactionFragment } from "../graphql.generated";

import { rootStyle, subSection } from "./detailsSection.css";

export const DetailsSection = React.memo(function HeaderSection({
  transaction,
}: {
  transaction: FullTransactionFragment;
}) {
  const { isLockedPeriod } = useSingleTransaction(transaction);

  return (
    <div className={rootStyle}>
      {!isLockedPeriod && <PrimaryStatusActionButton transaction={transaction} />}

      {transaction?.detail?.memo && (
        <div className={subSection}>
          <Text variant="bodyS" color="gray300">
            Memo
          </Text>
          <div>{transaction?.detail?.memo}</div>
        </div>
      )}
    </div>
  );
});
