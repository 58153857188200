import React, { useMemo } from "react";
import { CalendarDate, parseAbsolute } from "@internationalized/date";
import { DataTable } from "components/common/DataTable";
import {
  AccountTransactionDetailFragment,
  AccountTransactionDetailQuery,
} from "./graphql.generated";
import { styled } from "@puzzle/ui";
import { LedgerAccountFragment } from "../shared/chartOfAccounts.graphql.generated";
import LoadingReport from "../../Report/LoadingReport";
import LedgerAccountTable from "./LedgerAccountDataTable";
import LedgerAccountMRT from "./LedgerAccountMRT";
import { formatMoney } from "@puzzle/utils";
import { getAccountLabel } from "../shared/utils";
import { useActiveCompany } from "components/companies";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";

const BodyWrapper = styled("div", {
  overflow: "hidden",
});

const AccountBody = styled("div", {
  width: "100%",
  paddingTop: "$3",
  height: "$3",
});

const AccountRow = styled("div", {
  display: "inline-flex",
  justifyContent: "space-between",
  background: "$mauve850",
  color: "$gray400",
  width: "100%",
  padding: "$1",
  height: "$5",
});

const TotalText = styled("div", {
  fontStyle: "italic",
  display: "inline-flex",
});

const NumberText = styled("div", {
  fontStyle: "italic",
  display: "inline-flex",
  marginLeft: "$2",
});

const TableContainer = styled("div", {
  "tfoot tr td": {
    color: "$gray400",
  },
});

export const glAmountToDisplayString = (amount: number | string | null | undefined): string => {
  if (amount === undefined || amount === null || amount === "" || isNaN(Number(amount))) {
    return "";
  }
  return formatMoney({ currency: "USD", amount }, { currencySymbol: false });
};

interface LedgerAccountTablesProps {
  loading: boolean;
  startDate?: CalendarDate;
  endDate?: CalendarDate;
  showContributingEntriesOnly: boolean;
  subaccountsWithTransactionsOnly: boolean;
  selectedAccount: LedgerAccountFragment;
  selectedAccountIds: string[];
  companyEventsQueryResponse?: AccountTransactionDetailQuery;
  selectedAccountWithSubaccounts: LedgerAccountFragment[];
  chartOfAccountsById: Record<string, LedgerAccountFragment>;
  onItemUpdated: () => void;
}

const LedgerAccountViewBody = ({
  loading,
  startDate,
  endDate,
  showContributingEntriesOnly,
  selectedAccount,
  selectedAccountIds,
  companyEventsQueryResponse,
  selectedAccountWithSubaccounts,
  subaccountsWithTransactionsOnly,
  chartOfAccountsById,
  onItemUpdated,
}: LedgerAccountTablesProps) => {
  const { timeZone } = useActiveCompany<true>();
  const selectedAccountDisplayIds = useMemo(() => {
    return selectedAccountWithSubaccounts.map((account) => {
      return account.displayId;
    });
  }, [selectedAccountWithSubaccounts]);

  const accountTotals: Record<string, number> = useMemo(() => {
    const totals = Object.fromEntries(selectedAccountDisplayIds.map((id) => [id, 0]));
    totals["total"] = 0;
    return totals;
  }, [selectedAccountDisplayIds]);

  const eventsDataByAccDisplayId = useMemo(() => {
    const allEvents =
      companyEventsQueryResponse?.accountTransactionDetail?.accountTransactionDetails || [];
    accountTotals["total"] = 0;
    selectedAccountDisplayIds.forEach((id: string) => {
      accountTotals[id] = 0;
    });

    return allEvents.reduce(
      (
        prev: Record<string, AccountTransactionDetailFragment[]>,
        row: AccountTransactionDetailFragment
      ) => {
        const accountId = row.accountId;
        const displayId = row.displayId;
        if (
          !(showContributingEntriesOnly && Number(row.amount) === 0) &&
          accountId &&
          selectedAccountIds.includes(accountId) &&
          displayId
        ) {
          prev[displayId].push(row);
          // sort by date asc
          prev[displayId].sort((a, b) => {
            if (!a.effectiveAt) {
              return -1;
            } else if (!b.effectiveAt) {
              return 1;
            }

            return parseAbsolute(a.effectiveAt, timeZone).compare(
              parseAbsolute(b.effectiveAt, timeZone)
            );
          });

          const amountValue = Number(row.amount);
          accountTotals[displayId] = accountTotals[displayId] + amountValue;
          accountTotals["total"] = accountTotals["total"] + amountValue;
          accountTotals[`${displayId}CreditSubtotal`] =
            (accountTotals[`${displayId}CreditSubtotal`] || 0) +
            (amountValue < 0 ? 0 : amountValue);
          accountTotals[`${displayId}DebitSubtotal`] =
            (accountTotals[`${displayId}DebitSubtotal`] || 0) + (amountValue > 0 ? 0 : amountValue);
        }
        return prev;
      },
      Object.fromEntries(selectedAccountDisplayIds.map((id) => [id, []]))
    );
  }, [
    companyEventsQueryResponse?.accountTransactionDetail?.accountTransactionDetails,
    accountTotals,
    selectedAccountDisplayIds,
    showContributingEntriesOnly,
    selectedAccountIds,
    timeZone,
  ]);

  let numDisplayedTables = 0;

  return (
    <BodyWrapper>
      <AccountBody>
        <AccountRow>
          <div>{getAccountLabel(selectedAccount)}</div>
          <div>
            <TotalText>Total</TotalText>
            <NumberText>{glAmountToDisplayString(accountTotals["total"])}</NumberText>
          </div>
        </AccountRow>
      </AccountBody>
      {loading ? (
        <LoadingReport />
      ) : (
        <>
          {selectedAccountWithSubaccounts.map((account, idx) => {
            const numEntries = eventsDataByAccDisplayId[account.displayId]?.length || 0;
            if (subaccountsWithTransactionsOnly && numEntries === 0) {
              return <React.Fragment key={idx} />;
            }
            numDisplayedTables++;
            return isPosthogFeatureFlagEnabled(FeatureFlag.GeneralLedgerMRT) ? (
              <LedgerAccountMRT
                key={idx}
                startDate={startDate}
                endDate={endDate}
                idx={idx}
                account={account}
                numEntries={numEntries}
                eventsDataByAccDisplayId={eventsDataByAccDisplayId}
                accountTotals={accountTotals}
                chartOfAccountsById={chartOfAccountsById} 
                onItemUpdated={onItemUpdated}
              />
            ) : (
              <LedgerAccountTable
                key={idx}
                startDate={startDate}
                endDate={endDate}
                idx={idx}
                account={account}
                numEntries={numEntries}
                eventsDataByAccDisplayId={eventsDataByAccDisplayId}
                accountTotals={accountTotals}
                chartOfAccountsById={chartOfAccountsById}
                onItemUpdated={onItemUpdated}
              />
            );
          })}
          {numDisplayedTables === 0 ? (
            // empty/no data view
            <TableContainer>
              <DataTable<AccountTransactionDetailFragment>
                bordered
                loading={loading}
                data={[]}
                columns={[]}
              />
            </TableContainer>
          ) : (
            <></>
          )}
        </>
      )}
    </BodyWrapper>
  );
};

export default LedgerAccountViewBody;
