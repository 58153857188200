import React, { useCallback } from "react";
import { useToasts } from "@puzzle/ui";
import { FullTransactionFragment } from "../graphql.generated";
import { useActiveCompany } from "components/companies";
import { useAskAI } from "components/AI/useAskAI";
import { BaseAskAIButton } from "./BaseAIButton";

export const AskAIAboutTransactionButton = ({
  transaction,
  onClick,
  includeText = true,
}: {
  transaction: FullTransactionFragment;
  onClick?: () => void;
  includeText?: boolean;
}) => {
  const { toast } = useToasts();
  const { company } = useActiveCompany<true>();
  const { askAboutTransaction } = useAskAI();

  const handleError = useCallback(() => {
    toast({
      title: `Could not connect to AI`,
      message: `Our AI provider, OpenAI, may be down right now`,
      status: "error",
      onAction: () => {
        askAboutTransaction(transaction, { onError: handleError });
      },
      actionText: "Try Again",
      duration: 10000,
    });
  }, [toast, askAboutTransaction, transaction]);

  const handleOnClick = useCallback(() => {
    askAboutTransaction(transaction, { onError: handleError });
    if (onClick) {
      onClick();
    }
  }, [askAboutTransaction, onClick, transaction, handleError]);

  return <BaseAskAIButton onClick={handleOnClick} text={includeText ? "AI Lookup" : undefined} />;
};
