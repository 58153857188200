import React from "react";
import { Stack, Tag, Text } from "@puzzle/ui";
import { formatMoney, parseAbsolute } from "@puzzle/utils";
import { useActiveCompany, useCompanyDateFormatter } from "components/companies";
import { Route } from "lib/routes";
import { TransactionDetail } from "graphql/types";
import Link from "components/common/Link";

const AssociatedTransaction = ({ transactionDetail }: { transactionDetail: TransactionDetail }) => {
  const { timeZone } = useActiveCompany<true>();
  const dateFormatter = useCompanyDateFormatter({
    month: "numeric",
    day: "numeric",
    year: "numeric",
  });

  if (!transactionDetail || !transactionDetail.id) return null;

  return (
    <Link
      href={`${Route.transactions}/${transactionDetail.id}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Stack
        direction="horizontal"
        gap="2"
        css={{
          alignItems: "center",
          backgroundColor: "$porpoise400",
          border: "1px solid $mauve650",
          padding: "$2",
          borderRadius: "$2",
          marginTop: "$1h",
          cursor: "pointer",
        }}
      >
        <div>
          {transactionDetail?.postedAt
            ? dateFormatter.format(parseAbsolute(transactionDetail?.postedAt, timeZone))
            : "-"}
        </div>
        <div>{transactionDetail?.vendor?.name}</div>
        <Tag size="medium">{transactionDetail?.category?.displayName}</Tag>
        <Text as="div" css={{ marginLeft: "auto" }}>
          {formatMoney({
            currency: "USD",
            amount: transactionDetail?.amount ?? 0,
          })}
        </Text>
      </Stack>
    </Link>
  );
};

export default AssociatedTransaction;
