import React from "react";
import Icon, { IconProps } from "./Icon";
import { getNumericRotate } from "./utils";

interface ChevronDownProps extends IconProps {
  gradient?: boolean;
}

export function ChevronDown({
  viewBox = "0 0 14 14",
  width = 14,
  height = 14,
  color = "currentColor",
  fill = color,
  gradient = false,
  ...iconProps
}: ChevronDownProps) {
  return (
    <Icon width={width} height={height} {...iconProps} fill="none" viewBox={viewBox}>
      <path
        d="M2.46967 4.96967C2.76256 4.67678 3.23744 4.67678 3.53033 4.96967L7 8.43934L10.4697 4.96967C10.7626 4.67678 11.2374 4.67678 11.5303 4.96967C11.8232 5.26256 11.8232 5.73744 11.5303 6.03033L7 10.5607L2.46967 6.03033C2.17678 5.73744 2.17678 5.26256 2.46967 4.96967Z"
        fill={gradient ? "url(#linear-gradient-1)" : fill}
      />
      <defs>
        <linearGradient
          id="linear-gradient-1"
          x1="3.93403"
          y1="6.74487"
          x2="5.25603"
          y2="4.22677"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#AB7DE3" />
          <stop offset="1" stop-color="#ADFFE2" />
        </linearGradient>
      </defs>
    </Icon>
  );
}

export function ChevronUp(props: IconProps) {
  const totalRotate = 180 + getNumericRotate(props.rotate);
  return <ChevronDown {...props} rotate={totalRotate} />;
}

export function ChevronRight(props: IconProps) {
  const totalRotate = -90 + getNumericRotate(props.rotate);
  return <ChevronDown {...props} rotate={totalRotate} />;
}

export function ChevronLeft(props: IconProps) {
  const totalRotate = 90 + getNumericRotate(props.rotate);
  return <ChevronDown {...props} rotate={totalRotate} />;
}
