import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Download2({
  viewBox = "0 0 20 20",
  width = 20,
  height = 20,
  color = "currentColor",
  stroke = color,
  ...iconProps
}: IconProps) {
  return (
    <Icon height={height} width={width} {...iconProps} viewBox={viewBox} fill="none">
      <path
        d="M16.875 12.2917V15.2083C16.875 16.1288 16.1288 16.875 15.2083 16.875H4.79167C3.87119 16.875 3.125 16.1288 3.125 15.2083V12.2917M9.99999 12.5V3.125M9.99999 12.5L7.08333 9.58333M9.99999 12.5L12.9167 9.58333"
        stroke={stroke}
        strokeWidth="1.25"
      />
    </Icon>
  );
}
