import { FixedAssetType } from "graphql/types";
import { CalendarDateString } from "scalars";
import * as yup from "yup";

export const transformType = (type?: string | null) => {
  if (!type) return null;
  return type
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join("");
};

export const isValidFixedAssetType = (type: string | null) => {
  if (!type) return false;
  return (Object.values(FixedAssetType) as string[]).includes(type);
};

export type NewAssetFromPieceFormValues = {
  inServiceAt: CalendarDateString;
  type?: FixedAssetType;
  description?: string;
  usefulLifeYears?: string;
};

export const validationSchema = yup.object({
  type: yup.string().required().oneOf(Object.values(FixedAssetType)),
  inServiceAt: yup.string().required(),
  description: yup.string().required().min(1),
  usefulLifeYears: yup.string().required(),
});
