import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Calendar2({
  viewBox = "0 0 12 12",
  color = "currentColor",
  fill = color,
  stroke = color,
  width = 12,
  height = 12,
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} {...iconProps} viewBox={viewBox} fill="none">
      <path
        d="M1.875 3.875H10.125M3.875 10.125H8.125C9.22957 10.125 10.125 9.22957 10.125 8.125V3.875C10.125 2.77043 9.22957 1.875 8.125 1.875H3.875C2.77043 1.875 1.875 2.77043 1.875 3.875V8.125C1.875 9.22957 2.77043 10.125 3.875 10.125Z"
        stroke={stroke}
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 6C3.5 5.72386 3.72386 5.5 4 5.5C4.27614 5.5 4.5 5.72386 4.5 6C4.5 6.27614 4.27614 6.5 4 6.5C3.72386 6.5 3.5 6.27614 3.5 6Z"
        fill={fill}
      />
      <path
        d="M3.5 8C3.5 7.72386 3.72386 7.5 4 7.5C4.27614 7.5 4.5 7.72386 4.5 8C4.5 8.27614 4.27614 8.5 4 8.5C3.72386 8.5 3.5 8.27614 3.5 8Z"
        fill={fill}
      />
      <path
        d="M5.5 8C5.5 7.72386 5.72386 7.5 6 7.5C6.27614 7.5 6.5 7.72386 6.5 8C6.5 8.27614 6.27614 8.5 6 8.5C5.72386 8.5 5.5 8.27614 5.5 8Z"
        fill={fill}
      />
      <path
        d="M5.5 6C5.5 5.72386 5.72386 5.5 6 5.5C6.27614 5.5 6.5 5.72386 6.5 6C6.5 6.27614 6.27614 6.5 6 6.5C5.72386 6.5 5.5 6.27614 5.5 6Z"
        fill={fill}
      />
      <path
        d="M7.5 6C7.5 5.72386 7.72386 5.5 8 5.5C8.27614 5.5 8.5 5.72386 8.5 6C8.5 6.27614 8.27614 6.5 8 6.5C7.72386 6.5 7.5 6.27614 7.5 6Z"
        fill={fill}
      />
    </Icon>
  );
}
