import React, { useCallback, useRef, useState, useMemo } from "react";
import { useToasts } from "@puzzle/ui";
import useFile from "components/common/files/useFile";
import { Contact, Paperclip } from "@puzzle/icons";
import Loader from "components/common/Loader";
import { AssociatedEntity } from "graphql/types";
import { FullTransactionFragment } from "components/dashboard/Transactions/graphql.generated";
import useSingleTransaction from "components/dashboard/Transactions/hooks/useSingleTransaction";
import Analytics from "lib/analytics";
import { useAskAI } from "components/AI/useAskAI";
import { useDetailPaneContext } from "./DetailPaneContext";

import { Button } from "ve";

import { containerStyle, footerStyle, textAreaStyle } from "./commentBox.css";

const CommentBox = ({
  transaction,
  onSave,
}: {
  transaction: FullTransactionFragment;
  onSave?: () => void;
}) => {
  const { refetch, addMessage } = useSingleTransaction(transaction);
  const { toast } = useToasts();
  const [message, setMessage] = useState("");
  const { commentBoxMode } = useDetailPaneContext();
  const { provideAdditionalContextForTransaction } = useAskAI();

  const handleSaveMessage = async (newMessage: string) => {
    if (newMessage && transaction) {
      if (commentBoxMode === "messaging") {
        await addMessage({
          text: newMessage,
          transaction,
        });
        Analytics.transactionCommentAdded({
          transactionId: transaction.id,
        });
      } else if (commentBoxMode === "provide_ai_context") {
        // todo this will have to be retrieved on the backend, or sent as part of the notification data
        await provideAdditionalContextForTransaction(transaction, newMessage);
      } else {
        throw new Error("unsupported comment box mode");
      }

      onSave?.();
    }
  };

  const handleChangeMemoMessage = (event: React.ChangeEvent<{ value: string }>) =>
    setMessage(event.target.value);

  const handleError = (msg = "There was an error uploading your file") => {
    toast({ message: msg, status: "error" });
  };

  const handleSave = () => {
    if (!message) {
      return;
    }

    handleSaveMessage(message);
    setMessage("");
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    if (e.key === "Enter" && (e.metaKey || e.ctrlKey)) {
      handleSave();
    }
  };

  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const openFilePicker = useCallback(() => hiddenFileInput.current?.click(), []);
  const { dropAreaProps, isDropping, isUploading, onFiles } = useFile({
    entityId: transaction.id,
    entityType: AssociatedEntity.Transaction,
    onError: handleError,
    onUploadComplete: () => {
      Analytics.transactionDocumentationAdded({
        transactionId: transaction.id,
      });
      refetch?.();
    },
  });

  const rowCount = useMemo(() => {
    const lineCount = message.split("\n").length;
    return lineCount > 3 ? 3 : lineCount;
  }, [message]);

  return (
    <div className={containerStyle}>
      {/* todo more ui differentiation between two modes */}
      <textarea
        className={textAreaStyle}
        placeholder={
          commentBoxMode === "provide_ai_context"
            ? "Leave some context and let Puzzle AI categorize for you"
            : "Leave a comment..."
        }
        {...dropAreaProps}
        onChange={handleChangeMemoMessage}
        onKeyDown={handleKeyDown}
        value={message}
        rows={rowCount}
      />
      <div className={footerStyle}>
        <Button
          variant="minimal"
          onClick={openFilePicker}
          css={{ paddingLeft: 0, paddingRight: 0 }}
        >
          {isUploading && <Loader size={16} css={{ width: "fit-content" }} />}
          {!isUploading && <Paperclip rotate="180" color="rgba(255, 255, 255, 0.7)" size={20} />}
        </Button>

        <Button onClick={handleSave} variant="primary" disabled={!message}>
          <Contact />
        </Button>
      </div>

      <input
        type="file"
        ref={hiddenFileInput}
        onChange={(e) => e.target.files && onFiles(e.target.files)}
        style={{ display: "none" }}
      />
    </div>
  );
};

export default CommentBox;
