import React, { useMemo, useCallback, useState } from "react";
import { useSetState, useUpdateEffect } from "react-use";
import { omit } from "lodash";
import { CalendarDate } from "@internationalized/date";
import { Nullable, queryTypes, useQueryStates, UseQueryStatesKeysMap } from "next-usequerystate";
import { mergeDeep } from "@apollo/client/utilities";
import { SortingState } from "@tanstack/react-table";

import { RangePreset, RangePresets } from "@puzzle/ui";
import { calendarDateSerializer, today, parseDate } from "@puzzle/utils";

import { ActiveCompanyFragment, useActiveCompany } from "components/companies";
import { BillFilterStatus, BillSortOrder } from "graphql/types";
import { useSelectedVendors } from "components/common/hooks/vendors";
import { sanitizeAmountFiltersForQuery } from "components/common/AmountMenu";

import {
  GetFirstBillDateQuery,
  useGetBillsQuery,
  useGetFirstBillDateQuery,
} from "../graphql.generated";

export enum BillTableView {
  All = "all",
  Unpaid = "unpaid",
}

export enum BillDateFilter {
  IssueDate = "issueDate",
  DueDate = "dueDate",
}

type OptionalFields = "minAmount" | "maxAmount";

export type QueryFilterState = {
  startDate: CalendarDate;
  endDate: CalendarDate;
  preset: RangePreset | null;
  activeTableView: BillTableView;
  dateFilter: BillDateFilter;
  statuses: BillFilterStatus[];
  vendorIds: string[];
  minAmount: number;
  maxAmount: number;
};

type FilterState = Omit<QueryFilterState, OptionalFields> &
  Nullable<Pick<QueryFilterState, OptionalFields>>;

export const DEFAULT_DATE_PRESET = RangePresets.Last90Days;
export const DEFAULT_DATE_RANGE = DEFAULT_DATE_PRESET.range!();
const DEFAULT_PER_PAGE = 50;

const useQueryFilterState = () => {
  return useQueryStates<UseQueryStatesKeysMap<Omit<QueryFilterState, "preset">>>({
    startDate: calendarDateSerializer,
    endDate: calendarDateSerializer,
    activeTableView: queryTypes.stringEnum<BillTableView>(Object.values(BillTableView)),
    dateFilter: queryTypes.stringEnum<BillDateFilter>(Object.values(BillDateFilter)),
    vendorIds: queryTypes.array(queryTypes.string, ".").withDefault([]),
    minAmount: queryTypes.float,
    maxAmount: queryTypes.float,
    statuses: queryTypes
      .array(queryTypes.stringEnum<BillFilterStatus>(Object.values(BillFilterStatus)), ".")
      .withDefault([]),
  });
};

const useBillsQuery = (filter: FilterState, sortBy?: BillSortOrder) => {
  const { company } = useActiveCompany<true>();
  const amountFilters = sanitizeAmountFiltersForQuery(filter);

  const { data, loading, variables, fetchMore, refetch } = useGetBillsQuery({
    skip: !company?.id,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    variables: {
      companyId: company.id,
      page: { count: DEFAULT_PER_PAGE },
      filterBy: {
        ...amountFilters,
        isPaid: filter.activeTableView === BillTableView.Unpaid ? false : undefined,
        isSubmitted: filter.activeTableView === BillTableView.Unpaid ? true : undefined,
        statuses: filter.statuses.length > 0 ? filter.statuses : undefined,
        vendorIds: filter.vendorIds.length > 0 ? filter.vendorIds : undefined,
        [filter.dateFilter === BillDateFilter.IssueDate ? "dateRange" : "dueDateRange"]: {
          from: filter.startDate.toString(),
          to: filter.endDate.toString(),
        },
      },
      sortBy,
    },
  });

  const hasMore = data?.company?.bills?.pageInfo?.hasNextPage;
  const fetchNextPage = useCallback(() => {
    fetchMore({
      variables: mergeDeep(variables, {
        page: {
          after: data?.company?.bills?.pageInfo?.endCursor,
          count: DEFAULT_PER_PAGE,
        },
      }),
    });
  }, [fetchMore, variables, data]);

  return useMemo(() => {
    return {
      billsData: data?.company?.bills.items,
      billsTotal: data?.company?.bills.total,
      billsLoading: loading,
      hasMore,
      fetchNextPage,
      refetch,
    };
  }, [data?.company?.bills, loading, hasMore, fetchNextPage, refetch]);
};

const useBillsFilter = () => {
  const [queryParamState, setQueryParamState] = useQueryFilterState();

  const initialState: FilterState = useMemo(() => {
    const [start, end] = DEFAULT_DATE_RANGE;

    return {
      ...queryParamState,
      startDate: queryParamState.startDate ?? start,
      endDate: queryParamState.endDate ?? end,
      activeTableView: queryParamState.activeTableView ?? BillTableView.All,
      dateFilter: queryParamState.dateFilter ?? BillDateFilter.IssueDate,
      statuses: queryParamState.statuses ?? [],
      vendorIds: queryParamState.vendorIds ?? [],
      minAmount: queryParamState.minAmount ?? null,
      maxAmount: queryParamState.maxAmount ?? null,
      preset:
        queryParamState.startDate || queryParamState.endDate
          ? { key: "customDay", label: "Custom" }
          : DEFAULT_DATE_PRESET,
    };
  }, [queryParamState]);

  const [filter, setFilter] = useSetState<FilterState>(initialState);

  useUpdateEffect(() => {
    setQueryParamState({
      ...omit(filter, ["preset"]),
      statuses: filter.statuses && filter.statuses?.length > 0 ? filter.statuses : null,
      vendorIds: filter.vendorIds && filter.vendorIds?.length > 0 ? filter.vendorIds : null,
    });
  }, [filter]);

  const [sortOptions, setSortOptions] = useState<SortingState>([]);
  const sortBy = useMemo(() => {
    const { id, desc } = sortOptions?.[0] || {};
    switch (id) {
      case "issueDate":
        return desc ? BillSortOrder.IssueDateDesc : BillSortOrder.IssueDateAsc;
      case "dueDate":
        return desc ? BillSortOrder.DueDateDesc : BillSortOrder.DueDateAsc;
      case "description":
        return desc ? BillSortOrder.DescriptorDesc : BillSortOrder.DescriptorAsc;
      case "vendor":
        return desc ? BillSortOrder.VendorDesc : BillSortOrder.VendorAsc;
      case "amount.amount":
        return desc ? BillSortOrder.AmountDesc : BillSortOrder.AmountAsc;
      default:
        undefined;
    }
  }, [sortOptions]);

  return useMemo(() => {
    return {
      filter,
      setFilter,
      sortOptions,
      setSortOptions,
      sortBy,
    };
  }, [filter, setFilter, sortOptions, setSortOptions, sortBy]);
};

export const useGetBillListAllTimeRange = (company: ActiveCompanyFragment) => {
  const queryParams = (sortBy: BillSortOrder) =>
    ({
      skip: !company?.id,
      conext: { batch: false },
      fetchPolicy: "no-cache",
      variables: {
        companyId: company.id,
        page: {
          after: "0",
          count: 1,
        },
        sortBy: sortBy,
      },
    } as const);
  const { data: oldestBillDueDataData, loading: oldestBillDueDataLoading } =
    useGetFirstBillDateQuery(queryParams(BillSortOrder.DueDateAsc));
  const { data: mostRecentBillDueDateData, loading: mostRecentBillDueDateLoading } =
    useGetFirstBillDateQuery(queryParams(BillSortOrder.DueDateDesc));
  const { data: oldestBillIssueDateData, loading: oldestBillIssueDateLoading } =
    useGetFirstBillDateQuery(queryParams(BillSortOrder.IssueDateAsc));
  const { data: mostRecentBillIsusueDateData, loading: mostRecentBillIssueDateLoading } =
    useGetFirstBillDateQuery(queryParams(BillSortOrder.IssueDateDesc));

  const date = (
    dateFilter: "issueDate" | "dueDate",
    data?: GetFirstBillDateQuery
  ): CalendarDate => {
    const item =
      data?.company?.bills.items &&
      data.company.bills.items.length > 0 &&
      data.company.bills.items[0];
    const date = item && item[dateFilter];
    if (date) {
      return parseDate(date);
    } else {
      return today(company.timeZone || "America/Los_Angeles");
    }
  };

  return {
    [BillDateFilter.DueDate]: [
      date("dueDate", oldestBillDueDataData),
      date("dueDate", mostRecentBillDueDateData),
    ],
    [BillDateFilter.IssueDate]: [
      date("issueDate", oldestBillIssueDateData),
      date("issueDate", mostRecentBillIsusueDateData),
    ],
    hasAnyBills: !!mostRecentBillDueDateData,
    allTimeRangeLoading:
      oldestBillDueDataLoading ||
      mostRecentBillDueDateLoading ||
      oldestBillIssueDateLoading ||
      mostRecentBillIssueDateLoading,
  };
};

export const useBillsTableContextValue = () => {
  const { filter, setFilter, sortOptions, setSortOptions, sortBy } = useBillsFilter();
  const { billsData, billsTotal, billsLoading, hasMore, fetchNextPage, refetch } = useBillsQuery(
    filter,
    sortBy
  );
  const { company } = useActiveCompany<true>();
  const selectedVendors = useSelectedVendors(filter.vendorIds);
  const allTimeRanges = useGetBillListAllTimeRange(company);
  return useMemo(() => {
    return {
      filter,
      setFilter,
      billsData,
      billsTotal,
      billsLoading,
      hasMore,
      fetchNextPage,
      sortOptions,
      setSortOptions,
      sortBy,
      selectedVendors,
      refetch,
      allTimeRanges,
    };
  }, [
    filter,
    setFilter,
    billsData,
    billsTotal,
    billsLoading,
    hasMore,
    fetchNextPage,
    sortOptions,
    setSortOptions,
    sortBy,
    selectedVendors,
    refetch,
    allTimeRanges,
  ]);
};

const BillsTableContext = React.createContext<ReturnType<typeof useBillsTableContextValue> | null>(
  null
);

export const useBillsTableContext = () => {
  const context = React.useContext(BillsTableContext);

  if (context === null) {
    throw new Error(
      "useBillsTableContext must be used as a child within BillsTableContextProvider"
    );
  }

  return context;
};

export const BillsTableContextProvider = ({ ...props }) => {
  return <BillsTableContext.Provider value={useBillsTableContextValue()} {...props} />;
};
