import DescriptionList from "components/common/DescriptionList";
import React from "react";
import StatusTag from "../StatusTag";
import { compact, startCase } from "lodash";
import { CATEGORY_OPTIONS, USEFUL_LIFE_OPTIONS } from "../hooks/usePendingAssetsForm";
import { PagedFixedAssetV2Fragment } from "../graphql.generated";
import { useActiveCompany, useCompanyDateFormatter } from "components/companies";
import { formatMoney, parseDate, toCalendarDate } from "@puzzle/utils";
import { FixedAssetDepreciationMethod } from "graphql/types";
import { Controller } from "react-hook-form";
import { DateInput, Input, Select } from "@puzzle/ui";
import { isEditorRole } from "lib/roles";
import { PendingAssetForm } from "../hooks/usePendingAssetForm";

type Props = {
  form: PendingAssetForm;
  asset?: PagedFixedAssetV2Fragment;
};

export const PendingDetails = ({ form, asset }: Props) => {
  const { membershipRole } = useActiveCompany<true>();
  const isEditor = isEditorRole(membershipRole);
  const dateFormatter = useCompanyDateFormatter({
    dateStyle: "medium",
  });

  return (
    <DescriptionList
      direction="horizontal"
      termWidth={"175px"}
      items={compact([
        ["Status", asset?.status ? <StatusTag status={asset.status} key="statusTag" /> : "-"],
        [
          "Purchase date",
          asset?.capitalizedAt ? dateFormatter.format(parseDate(asset.capitalizedAt)) : "-",
        ],
        [
          "Cost",
          formatMoney({
            currency: "USD",
            amount: asset?.originalValue ?? 0,
          }),
        ],
        [
          "Description",
          <Controller
            control={form.control}
            name="description"
            key="description"
            render={({ field }) => {
              return (
                <Input
                  {...field}
                  size="mini"
                  readOnly={!isEditor}
                  hideInitialBorder
                  onClick={(e) => e.stopPropagation()}
                />
              );
            }}
          />,
        ],
        [
          "Asset type",
          <Controller
            control={form.control}
            name="type"
            key="type"
            render={({ field }) => {
              return (
                <Select
                  options={CATEGORY_OPTIONS}
                  onSelectionChange={(value) => {
                    field.onChange({
                      target: { value },
                    });
                  }}
                  value={field.value}
                  size="mini"
                  css={{ width: "250px" }}
                  hideInitialBorder
                  menuCss={{ zIndex: 2 }}
                />
              );
            }}
          />,
        ],
        [
          "In-service date",
          <Controller
            control={form.control}
            name="inServiceAt"
            key="inServiceAt"
            render={({ field }) => {
              return (
                <DateInput
                  readOnly={!isEditor}
                  size="small"
                  placeholder="Pick a date"
                  value={field.value ? parseDate(field.value) : undefined}
                  onChange={(value) => {
                    field.onChange({
                      target: {
                        value: value && toCalendarDate(value).toString(),
                        name: field.name,
                      },
                    });
                  }}
                  iconSide="right"
                  hideInitialBorder
                  css={{ width: "125px", zIndex: 2 }}
                  onClick={(e) => e.stopPropagation()}
                />
              );
            }}
          />,
        ],
        [
          "Useful life",
          <Controller
            control={form.control}
            name="usefulLifeMonths"
            key="usefulLifeMonths"
            render={({ field }) => {
              return (
                <Select
                  options={USEFUL_LIFE_OPTIONS}
                  value={field.value?.toString()}
                  size="small"
                  readOnly={!isEditor}
                  onSelectionChange={(duration) => {
                    field.onChange(duration);
                  }}
                  css={{ width: "100px" }}
                  hideInitialBorder
                  menuCss={{ zIndex: 2 }}
                />
              );
            }}
          />,
        ],
        [
          "Method",
          startCase(asset?.depreciationMethod ?? FixedAssetDepreciationMethod.StraightLine),
        ],
        ["First month convention", "Half-month"],
      ])}
    />
  );
};
