import React from "react";
import { FixedAssetStatus } from "graphql/types";
import { assetStatusTagStyles } from "./StatusTag.css";
import { Box } from "ve";

type DisplayableFixedAssetStatus =
  | FixedAssetStatus.Draft
  | FixedAssetStatus.Disposed
  | FixedAssetStatus.OnHold
  | FixedAssetStatus.InService
  | FixedAssetStatus.Void;

const DisplayTextMap: Record<DisplayableFixedAssetStatus, string> = {
  [FixedAssetStatus.Draft]: "Draft",
  [FixedAssetStatus.Disposed]: "Disposed",
  [FixedAssetStatus.OnHold]: "Paused",
  [FixedAssetStatus.InService]: "In-service",
  [FixedAssetStatus.Void]: "Voided",
};

const ClassNameMap: Record<DisplayableFixedAssetStatus, keyof typeof assetStatusTagStyles> = {
  [FixedAssetStatus.Draft]: "draft",
  [FixedAssetStatus.Disposed]: "disposed",
  [FixedAssetStatus.OnHold]: "onHold",
  [FixedAssetStatus.InService]: "inService",
  [FixedAssetStatus.Void]: "voided",
};

type Props = {
  status?: FixedAssetStatus;
};

const isDisplayableStatus = (status: FixedAssetStatus): status is DisplayableFixedAssetStatus => {
  return [
    FixedAssetStatus.Draft,
    FixedAssetStatus.Disposed,
    FixedAssetStatus.OnHold,
    FixedAssetStatus.InService,
    FixedAssetStatus.Void,
  ].includes(status);
};

const getDisplayableStatus = (
  status: DisplayableFixedAssetStatus
): { displayText: string; className: keyof typeof assetStatusTagStyles } => {
  return {
    displayText: DisplayTextMap[status],
    className: ClassNameMap[status],
  };
};

const StatusTag = ({ status }: Props) => {
  if (!status || !isDisplayableStatus(status)) return null;
  const { displayText, className } = getDisplayableStatus(status);

  return <Box className={assetStatusTagStyles[className]}>{displayText}</Box>;
};

export default StatusTag;
