import React from "react";

import { Calendar2, CreditCard3 } from "@puzzle/icons";
import { formatMoney } from "@puzzle/utils";
import { Route } from "lib/routes";

import { FixedAssetStatus, PrepaidStatus } from "graphql/types";

import { Text, color } from "ve";
import Link from "components/common/Link";
import InstitutionLogo from "components/integrations/bank/InstitutionLogo";

import { innerStyle, rootStyle, fixedAssetRootStyle, fixedAssetBadgeRecipe } from "./cards.css";

export const InstitutionCard = ({ name }: { name: string }) => {
  return (
    <div className={rootStyle}>
      <div className={innerStyle}>
        <InstitutionLogo institution={{ name }} circular={true} />
      </div>
      <Text variant="bodyXS" color="white70">
        {name}
      </Text>
    </div>
  );
};

export const DateCard = ({ date }: { date: string }) => {
  return (
    <div className={rootStyle}>
      <Calendar2 color={color.white70} />
      <Text variant="bodyXS" color="white70">
        {date}
      </Text>
    </div>
  );
};

export const CardCard = ({ holderName, lastFour }: { holderName?: string; lastFour?: string }) => {
  if (!holderName || !lastFour) {
    return null;
  }
  const text = `${holderName} · ${lastFour}`;

  return (
    <div className={rootStyle}>
      <CreditCard3 size={12} color={color.white70} />
      <Text variant="bodyXS" color="white70">
        {text}
      </Text>
    </div>
  );
};

const fixedAssetStatusColorMap: Record<FixedAssetStatus, "red" | "yellow" | "green" | "grey"> = {
  [FixedAssetStatus.Deleted]: "red",
  [FixedAssetStatus.Disposed]: "red",
  [FixedAssetStatus.OutOfService]: "red",
  [FixedAssetStatus.Void]: "red",

  [FixedAssetStatus.Capitalized]: "green",
  [FixedAssetStatus.InService]: "green",

  [FixedAssetStatus.Draft]: "grey",
  [FixedAssetStatus.Manual]: "grey",

  [FixedAssetStatus.OnHold]: "yellow",
};

const fixedAssetStatusName: Record<FixedAssetStatus, string> = {
  [FixedAssetStatus.Deleted]: "Deleted",
  [FixedAssetStatus.Disposed]: "Disposted",
  [FixedAssetStatus.OutOfService]: "Out of service",
  [FixedAssetStatus.Void]: "Void",

  [FixedAssetStatus.Capitalized]: "Capitalized",
  [FixedAssetStatus.InService]: "In-service",

  [FixedAssetStatus.Draft]: "Draft",
  [FixedAssetStatus.Manual]: "Manual",

  [FixedAssetStatus.OnHold]: "Paused",
};

const FixedAssetStatusBadge = ({ status }: { status: FixedAssetStatus }) => {
  const colorVariant = fixedAssetStatusColorMap[status]; // Get the corresponding color variant

  return (
    <div className={fixedAssetBadgeRecipe({ color: colorVariant })}>
      <Text variant="bodyXS" weight="heavy">
        {fixedAssetStatusName[status]}
      </Text>
    </div>
  );
};

export const FixedAssetCard = ({
  description,
  amount,
  status,
}: {
  description: string;
  amount?: string;
  status?: FixedAssetStatus;
}) => {
  return (
    <Link
      key="fixedAsset"
      href={`${Route.fixedAssets}${
        status === FixedAssetStatus.Draft ? "" : `?view=summary&status=${status}`
      }`}
    >
      <div className={fixedAssetRootStyle}>
        <Text variant="bodyXS" weight="bold" color="white">
          {description}
        </Text>
        {status && <FixedAssetStatusBadge status={status} />}

        {amount && (
          <span style={{ marginLeft: "auto" }}>
            <Text variant="bodyXS" weight="bold" color="white">
              {formatMoney({
                currency: "USD",
                amount: amount,
              })}
            </Text>
          </span>
        )}
      </div>
    </Link>
  );
};

const prepaidStatusColorMap: Record<PrepaidStatus, "red" | "yellow" | "green" | "grey"> = {
  [PrepaidStatus.Active]: "green",
  [PrepaidStatus.Closed]: "red",
  [PrepaidStatus.Draft]: "grey",
  [PrepaidStatus.Paused]: "yellow",
  [PrepaidStatus.Void]: "red",
};

const prepaidStatusName: Record<PrepaidStatus, string> = {
  [PrepaidStatus.Active]: "Active",
  [PrepaidStatus.Closed]: "Closed",
  [PrepaidStatus.Draft]: "Pending",
  [PrepaidStatus.Paused]: "Paused",
  [PrepaidStatus.Void]: "Void",
};

const PrepaidStatusBadge = ({ status }: { status: PrepaidStatus }) => {
  const colorVariant = prepaidStatusColorMap[status]; // Get the corresponding color variant

  return (
    <div className={fixedAssetBadgeRecipe({ color: colorVariant })}>
      <Text variant="bodyXS" weight="heavy">
        {prepaidStatusName[status]}
      </Text>
    </div>
  );
};

export const PrepaidCard = ({
  description,
  amount,
  status,
}: {
  description: string;
  amount?: string;
  status?: PrepaidStatus;
}) => {
  return (
    <Link
      key="prepaid"
      href={`${Route.prepaidExpenses}${status === PrepaidStatus.Draft ? "" : `?status=${status}`}`}
    >
      <div className={fixedAssetRootStyle}>
        <Text variant="bodyXS" weight="bold" color="white">
          {description}
        </Text>

        {status && <PrepaidStatusBadge status={status} />}

        {amount && (
          <span style={{ marginLeft: "auto" }}>
            <Text variant="bodyXS" weight="bold" color="white">
              {formatMoney({
                currency: "USD",
                amount: amount,
              })}
            </Text>
          </span>
        )}
      </div>
    </Link>
  );
};
