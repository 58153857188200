import React from "react";
import Big from "big.js";

import { FullTransactionFragment, BasicTransactionFragment } from "../graphql.generated";

import { parseDate } from "@internationalized/date";
import { formatMoney, formatAccountName } from "@puzzle/utils";
import { External, MoneyIn, MoneyOut } from "@puzzle/icons";

import { Text, color, IconButton } from "ve";

import { useCompanyDateFormatter } from "components/companies";

import { ManualTransactionTooltip } from "../AsteriskTooltip";
import { InstitutionCard, DateCard, CardCard } from "./Cards";
import {
  rootStyle,
  topSectionStyle,
  badgeContainerStyle,
  amountContainerStyle,
} from "./headerSection.css";

export const HeaderSection = React.memo(function HeaderSection({
  transaction,
  basicTransaction,
  isRevenueTransaction,
}: {
  transaction: FullTransactionFragment | null;
  basicTransaction: BasicTransactionFragment | null;
  isRevenueTransaction: boolean;
}) {
  const dateFormatter = useCompanyDateFormatter({
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  if (!transaction) return null;

  const amountBig = Big(transaction.amount);
  const negative = amountBig.lte(0);

  const vendor = transaction?.detail?.vendor;
  const customer = transaction?.detail?.customer;
  const titleHeading = isRevenueTransaction
    ? customer?.name ?? "No customer"
    : vendor?.name ?? "No vendor";

  const card = transaction?.detail?.card;

  return (
    <div className={rootStyle}>
      <div className={topSectionStyle}>
        <div>
          <Text variant="heading3" weight="bold" color="white">
            {titleHeading}
          </Text>
        </div>
        <div className={amountContainerStyle}>
          {negative ? (
            <MoneyOut size={20} color={color.red500} />
          ) : (
            <MoneyIn size={20} color={color.greenalpha} />
          )}
          <Text variant="heading3" weight="bold" color="white">
            {formatMoney({ currency: "USD", amount: amountBig })}
            <ManualTransactionTooltip
              integrationType={transaction.integrationType}
              css={{ top: "-10px" }}
            />
          </Text>

          {transaction.linkToSourceTransaction && (
            <IconButton
              onClick={() => window.open(transaction.linkToSourceTransaction || "", "_blank")}
            >
              <External />
            </IconButton>
          )}
        </div>
      </div>
      <div>
        <Text variant="bodyS" color="white70">
          {transaction?.descriptor}
        </Text>
      </div>
      <div className={badgeContainerStyle}>
        <DateCard date={dateFormatter.format(parseDate(transaction.date))} />
        <InstitutionCard name={transaction.account.financialInstitution.name} />
        <CardCard holderName={card?.holderName} lastFour={card?.lastFour} />
      </div>
    </div>
  );
});
