import React from "react";
import { IconProps } from "./Icon";

export default function Contact({ color = "black", size = 16, viewBox = "0 0 16 16" }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={viewBox}
      fill="none"
    >
      <path
        d="M4.00001 7.99998H6.16668M4.00001 7.99998L2.33334 2.33331L13.5 7.99998L2.33334 13.6666L4.00001 7.99998Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
