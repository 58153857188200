import React from "react";
import Icon, { IconProps } from "./Icon";

export default function AddUser({
  width = 14,
  height = 14,
  //   color = "currentColor",
  fill = "none",
  viewBox = "0 0 14 14",
  ...iconProps
}: IconProps) {
  return (
    <Icon height={height} width={width} fill={fill} {...iconProps} viewBox={viewBox}>
      <g>
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity="0.8"
          d="M11.375 5.104v1.604m0 0v1.604m0-1.604H9.771m1.604 0h1.604M8.604 3.791a2.188 2.188 0 11-4.375 0 2.188 2.188 0 014.375 0zm-6.28 6.775c.614-1.662 2.115-2.837 4.093-2.837 1.978 0 3.479 1.175 4.093 2.837.239.644-.312 1.246-1 1.246H3.323c-.687 0-1.238-.602-1-1.246z"
        ></path>
      </g>
    </Icon>
  );
}
