import React, { useEffect, useState } from "react";
import { styled, IconButton, Tooltip } from "@puzzle/ui";
import { Copy } from "@puzzle/icons";
import { useCopyToClipboard, usePrevious } from "react-use";

const Root = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "$0h",
});

const Label = styled("div", {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const CopyCell = ({
  value,
  children = value,
}: React.PropsWithChildren<{ value: string; children: React.ReactNode }>) => {
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [copyState, copyToClipboard] = useCopyToClipboard();

  const wasOpen = usePrevious(open);
  useEffect(() => {
    if (open && !wasOpen) {
      setCopied(false);
    }
  }, [open, wasOpen]);

  return (
    <Root>
      <Label>{children}</Label>

      <Tooltip
        content={copied ? "Copied" : "Copy to clipboard"}
        side="right"
        open={open}
        onOpenChange={setOpen}
        triggerProps={{
          onMouseDown: (e) => e.preventDefault(),
        }}
      >
        <IconButton
          onClick={() => {
            setCopied(true);
            copyToClipboard(value);
          }}
        >
          <Copy />
        </IconButton>
      </Tooltip>
    </Root>
  );
};

export default CopyCell;
