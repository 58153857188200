import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Rule2({
  viewBox = "0 0 14 14",
  width = 14,
  height = 14,
  fill = "none",
  color = "currentColor",
  stroke = color,
  ...iconProps
}: IconProps) {
  return (
    <Icon height={height} width={width} fill={fill} {...iconProps} viewBox={viewBox}>
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity="0.8"
        d="M2.042 5.542v-3.5m0 0h3.5m-3.5 0L7 7m1.458-4.958h3.5m0 0v3.5m0-3.5L7 7m0 0v4.958"
      ></path>
    </Icon>
  );
}
