import { CalendarView, GroupBy, RangePreset, ReportGroupBy } from "@puzzle/ui";
import { RangePresets } from "@puzzle/ui";
import { keyBy } from "lodash";
import {
  getLocalTimeZone,
  today,
  startOfYear,
  startOfMonth,
  endOfMonth,
  CalendarDate,
} from "@internationalized/date";
import { startOfDay, startOfQuarter, subQuarters, endOfQuarter } from "date-fns";
import { dateToCalendarDate } from "@puzzle/utils";

// TODO Make  presets dynamic and company-aware via hook?
const TZ = () => getLocalTimeZone();

export const YearOverYearByPeriod = (groupBy: string): RangePreset => {
  return {
    key: "yearOverYear",
    label: "Year-over-year",
    view: CalendarView.Year,
    groupBy: groupBy as GroupBy,
    range: () => {
      const start = startOfYear(today(TZ())).subtract({ years: 1 });
      return [start, today(TZ())];
    },
  };
};

export const LastYearByMonth = (groupBy: string): RangePreset => {
  return {
    ...RangePresets.LastYear,
    view: CalendarView.Month,
    groupBy: groupBy as GroupBy,
  };
};

export const QuarterToDateByPeriod = (groupBy: string): RangePreset => {
  return {
    key: "quarterToDate",
    label: "Quarter to date",
    view: "month" as CalendarView,
    groupBy: groupBy as GroupBy,
    range: () => [startOfMonth(today(TZ())), today(TZ())],
  };
};

export const LastQuarterByPeriod = (groupBy: string): RangePreset => {
  return {
    key: "lastQuarter",
    label: "Last quarter",
    view: CalendarView.Quarter,
    groupBy: groupBy as GroupBy,
    range: () => {
      const start = subQuarters(startOfQuarter(startOfDay(new Date())), 1);
      return [dateToCalendarDate(start, TZ()), dateToCalendarDate(endOfQuarter(start), TZ())];
    },
  };
};

export const LastYearByYear = {
  ...RangePresets.LastYear,
  view: CalendarView.Year,
  label: "Last year",
};

export const CurrentYearByYear = () => {
  return {
    ...RangePresets.YearToDateByYear,
    label: "Current year",
  };
};

export const YearToDateByPeriod = (groupBy: string): RangePreset => {
  return {
    key: "yearToDate",
    label: "Year to date",
    view: CalendarView.Month,
    groupBy: groupBy as GroupBy,
    range: () => [startOfYear(today(TZ())), today(TZ())],
  };
};

export const Last3MonthsByPeriod = (groupBy: string): RangePreset => {
  return {
    ...RangePresets.Last3Months,
    groupBy: groupBy as GroupBy,
  };
};

export const Last3FullMonthsByPeriod = (groupBy: string): RangePreset => {
  return {
    key: "last3FullMonths",
    label: "Last 3 full months",
    view: CalendarView.Month,
    groupBy: groupBy as GroupBy,
    range: () => {
      const start = startOfMonth(today(TZ())).subtract({ months: 3 });
      return [start, endOfMonth(start.add({ months: 2 }))];
    },
  };
};

export const QuarterToDateByQuarterByPeriod = (groupBy: string): RangePreset => {
  return {
    key: "quarterToDate",
    label: "Quarter to date",
    view: CalendarView.Quarter,
    groupBy: groupBy as GroupBy,
    range: () => [dateToCalendarDate(startOfQuarter(new Date()), TZ()), today(TZ())],
  };
};

export const ReportPresets = (groupBy: `${GroupBy}`) =>
  keyBy(
    [
      { key: "quarter", value: [QuarterToDateByPeriod(groupBy), LastQuarterByPeriod(groupBy)] },
      {
        key: "year",
        value: [
          LastYearByMonth(groupBy),
          YearOverYearByPeriod(groupBy),
          RangePresets.YearToDateByYear,
        ],
      },
      {
        key: "month",
        value: [
          Last3MonthsByPeriod(groupBy),
          Last3FullMonthsByPeriod(groupBy),
          QuarterToDateByPeriod(groupBy),
          LastQuarterByPeriod(groupBy),
          YearToDateByPeriod(groupBy),
        ],
      },
      {
        key: "total",
        value: [
          Last3MonthsByPeriod(groupBy),
          Last3FullMonthsByPeriod(groupBy),
          QuarterToDateByPeriod(groupBy),
          LastQuarterByPeriod(groupBy),
          YearToDateByPeriod(groupBy),
        ],
      },
    ],
    "key"
  );

export const fixedReportPresets: Record<string, RangePreset[]> = {
  [GroupBy.Month]: [
    Last3MonthsByPeriod(GroupBy.Month),
    Last3FullMonthsByPeriod(GroupBy.Month),
    LastYearByMonth(GroupBy.Month),
    YearToDateByPeriod(GroupBy.Month),
    { key: "custom", label: "Custom", view: CalendarView.Month, groupBy: GroupBy.Month },
  ],
  [GroupBy.Quarter]: [
    RangePresets.QuarterOverQuarter,
    RangePresets.YearToDateByQuarter,
    { key: "custom", label: "Custom", view: CalendarView.Quarter, groupBy: GroupBy.Quarter },
  ],
  [GroupBy.Year]: [
    CurrentYearByYear(),
    LastYearByYear,
    YearOverYearByPeriod(GroupBy.Year),
    { key: "custom", label: "Custom", view: CalendarView.Year, groupBy: GroupBy.Year },
  ],
  [GroupBy.Total]: [
    RangePresets.CurrentMonthByMonth,
    RangePresets.LastFullMonth,
    Last3MonthsByPeriod(GroupBy.Total),
    Last3FullMonthsByPeriod(GroupBy.Total),
    QuarterToDateByPeriod(GroupBy.Total),
    YearToDateByPeriod(GroupBy.Total),
    RangePresets.MonthOverMonth,
    RangePresets.QuarterOverQuarter,
    YearOverYearByPeriod(GroupBy.Total),
    { key: "custom", label: "Custom", view: CalendarView.Month, groupBy: GroupBy.Month },
  ],
};

export const getCurrentReportPreset = (
  groupBy?: ReportGroupBy,
  allTimeStartDate?: CalendarDate
) => {
  let presets = fixedReportPresets[GroupBy.Total];
  if (groupBy) {
    presets = fixedReportPresets[groupBy] || fixedReportPresets[GroupBy.Total];
  }

  if (!allTimeStartDate) {
    return presets;
  }

  // we need to get the view and groupBy representing the
  // date group by of the current selected group by
  // this treats segment grouping and total group by
  // as month
  let view = CalendarView.Month;
  let dateTimeGroupBy = GroupBy.Month;
  if (groupBy === GroupBy.Year) {
    view = CalendarView.Year;
    dateTimeGroupBy = GroupBy.Year;
  } else if (groupBy === GroupBy.Quarter) {
    view = CalendarView.Quarter;
    dateTimeGroupBy = GroupBy.Quarter;
  }
  return [...presets, RangePresets.getAllTimePreset(allTimeStartDate, dateTimeGroupBy, view)];
};

export const getAllReportPresets = () => {
  const allValues = Object.values(fixedReportPresets);

  return allValues.flat();
};

function calculateYearDifference(startDate: Date, endDate: Date): number {
  let yearsApart = endDate.getFullYear() - startDate.getFullYear();

  // Adjust for cases where endDate is earlier in the year than startDate
  if (
    endDate.getMonth() < startDate.getMonth() ||
    (endDate.getMonth() === startDate.getMonth() && endDate.getDate() < startDate.getDate())
  ) {
    yearsApart--;
  }

  return yearsApart;
}

export const allowableCustomDateRange = (startStr: string, endStr: string) => {
  const startDate = new Date(startStr);
  const endDate = new Date(endStr);
  const yearDif = calculateYearDifference(startDate, endDate);

  const dateIsTooEarly = startDate.getFullYear() < 2000;
  const dateIsGreaterThanAllowableRange = yearDif > 10;

  if (dateIsTooEarly) {
    return { isAllowed: false, message: "Start date should be after the year 2000" };
  }
  if (dateIsGreaterThanAllowableRange) {
    return { isAllowed: false, message: "Please select a date range less than 10 years" };
  }

  return { isAllowed: true, message: "" };
};
