import { Button, ButtonGroup, AlertDialog } from "@puzzle/ui";
import { useDeleteLinkMutation } from "components/dashboard/Transactions/graphql.generated";
import Analytics from "lib/analytics";
import React, { useCallback } from "react";
import { useToasts } from "@puzzle/ui";

const errorToastConfig = {
  title: "The transaction hasn't been unlinked",
  message:
    "Something went wrong, and our team has been notified. We apologize for the inconvenience. Please try again later.",
  status: "warning",
} as const;

const successToastConfig = {
  title: "Transaction unlinked",
  message: "The transaction has been successfully unlinked",
  status: "success",
} as const;

type UnlinkTransactionModalProps = React.ComponentPropsWithoutRef<typeof AlertDialog> & {
  companyId: string;
  transactionId: string;
  bundleId?: string;
  refetch?: () => void;
};

export const useDeleteTransactionLink = () => {
  const [deleteLink, { loading }] = useDeleteLinkMutation();
  const { toast } = useToasts();

  return {
    mutation: useCallback(
      ({
        companyId,
        transactionId,
        bundleId,
      }: {
        companyId: string;
        transactionId: string;
        bundleId: string;
      }) => {
        if (!companyId || !transactionId || !bundleId) {
          return;
        }

        return deleteLink({
          variables: {
            input: {
              companyId,
              transactionId,
              bundleId,
            },
          },

          onCompleted: () => {
            toast(successToastConfig);
          },

          onError: () => {
            toast(errorToastConfig);
          },
          // Todo: update cache for links array? rather than force refetch
        });
      },
      [deleteLink, toast]
    ),
    loading,
  };
};

export const UnlinkTransactionModal = ({
  companyId,
  transactionId,
  bundleId,
  refetch,
  open,
  onOpenChange,
  ...props
}: UnlinkTransactionModalProps) => {
  const deleteTransactionLink = useDeleteTransactionLink();
  const [deleteLinkMutation] = useDeleteLinkMutation({
    onCompleted() {
      onOpenChange?.(false);
      Analytics.transactionUnlinked({ transactionId });
    },
    update: (cache, { data }) => {
      const transaction = data?.deleteLink.link.sentTransaction;
      cache.modify({
        id: cache.identify(transaction!.detail),
        fields: {
          linkedTransaction: () => transaction!.detail.linkedTransaction,
        },
      });
      const linkedTransaction = data?.deleteLink.link.receivedTransaction;
      if (linkedTransaction) {
        cache.modify({
          id: cache.identify(linkedTransaction.detail),
          fields: {
            linkedTransaction: () => linkedTransaction.detail.linkedTransaction,
          },
        });
      }
    },
  });

  const handleUnlink = useCallback(async () => {
    // Handle the links array case where we have a bundle ID (new way)
    if (bundleId) {
      await deleteTransactionLink.mutation({ companyId, transactionId, bundleId });
      refetch?.();
      return;
    }

    // Fallback to the old way of unlinking transactions
    await deleteLinkMutation({
      variables: { input: { companyId, transactionId } },
    });
  }, [deleteLinkMutation, transactionId, companyId]);

  return (
    <AlertDialog
      size="medium"
      {...props}
      open={open}
      onOpenChange={onOpenChange}
      onClick={(e) => e.stopPropagation()}
    >
      <AlertDialog.Title> Are you sure you want to unlink?</AlertDialog.Title>
      <AlertDialog.Body css={{ textVariant: "$bodyS" }}>
        <p>
          Puzzle automatically links transactions that reflect the same underlying activity in
          different accounts (e.g. credit card payments, bank transfers) based on transaction
          amounts and dates.
        </p>
        <p>
          If linking is incorrect, you can unlink the transactions and change the category of the
          transactions. The transactions cannot be re-linked.
        </p>
      </AlertDialog.Body>
      <AlertDialog.Footer>
        <ButtonGroup>
          <Button variant="secondary" onClick={() => onOpenChange?.(false)}>
            Cancel
          </Button>
          <Button variant="negative" onClick={handleUnlink}>
            Unlink
          </Button>
        </ButtonGroup>
      </AlertDialog.Footer>
    </AlertDialog>
  );
};
