import React from "react";
import { Drawer, IconButton, ScrollArea } from "@puzzle/ui";
import { Close } from "@puzzle/icons";
import { startCase } from "lodash";
import { parseDate } from "@internationalized/date";
import { Text, Stack, Box, S, color, } from "ve";
import { useGetFixedAssetByIdQuery } from "../../graphql.generated";
import { formatMoney, parseAbsolute, pluralize } from "@puzzle/utils";
import { useActiveCompany, useCompanyDateFormatter } from "components/companies";
import {
    BillLine,
    FixedAssetDepreciationMethod,
    FixedAssetStatus,
    TransactionDetail,
} from "graphql/types";
import { PendingDetails } from "../../../FixedAssetsV2/DepreciationScheduleDrawer/PendingDetails";
import { usePendingAssetForm } from "../../../FixedAssetsV2/hooks/usePendingAssetForm";
import DescriptionList from "components/common/DescriptionList";
import StatusTag from "../../../FixedAssetsV2/StatusTag";
import { useMemo } from "react";
import AssociatedTransaction from "../../../FixedAssetsV2/AssociatedTransaction";
import AssociatedBillLine from "../../../FixedAssetsV2/AssociatedBillLine";
import { DepreciationScheduleTable } from "../../../FixedAssetsV2/DepreciationScheduleTable";
import { ScrollAnchor } from "components/AI/shared";
import { ActionBar } from "./ActionBar";
import { styledStack, depreciationScheduleStack, fixedAssetsDrawer } from "./FixedAssetDetailsDrawer.css";

type FixedAssetDetailsDrawerProps = {
    open?: boolean;
    onOpenChanged(open: boolean): void;
    fixedAssetId: string;
    onDeleteAsset?: () => void;
    onItemUpdated: () => void;
}

export const FixedAssetDetailsDrawer = ({
    open,
    onOpenChanged,
    fixedAssetId,
    onDeleteAsset,
    onItemUpdated
}: FixedAssetDetailsDrawerProps) => {
    const { timeZone } = useActiveCompany<true>();

    const { data, loading, error } = useGetFixedAssetByIdQuery({
        variables: {
            fixedAssetId
        }
    });

    const pendingAssetForm = usePendingAssetForm({ asset: data?.fixedAsset, onCompleted: onOpenChanged });

    const dateFormatter = useCompanyDateFormatter({
        month: "numeric",
        day: "numeric",
        year: "numeric",
    });

    const purchasedAt = useMemo(() => {
        const pieces = data?.fixedAsset.pieces ?? [];
        if (data?.fixedAsset.capitalizedAt && !pieces.length)
            return dateFormatter.format(parseDate(data?.fixedAsset.capitalizedAt));
        if (data?.fixedAsset.inServiceAt && !pieces.length)
            return dateFormatter.format(parseDate(data?.fixedAsset.inServiceAt));

        const postedDates = pieces
            .map((piece) => piece.detail?.postedAt || piece.billLine?.createdAt)
            .filter((piece) => !!piece);

        const uniquePostedDates = new Set(postedDates);

        let postedAtDisplay;

        if (uniquePostedDates.size === 0) {
            postedAtDisplay = "-";
        } else if (uniquePostedDates.size === 1) {
            postedAtDisplay = dateFormatter.format(parseAbsolute(postedDates[0]!, timeZone));
        } else {
            postedAtDisplay = "Multiple";
        }

        return postedAtDisplay;
    }, [data?.fixedAsset, dateFormatter, timeZone]);

    const associatedTransactions = useMemo(() => {
        const pieces = data?.fixedAsset.pieces ?? [];

        const transactions = pieces.map((piece) => piece.detail).filter((piece) => !!piece);

        return transactions as TransactionDetail[];
    }, [data?.fixedAsset]);

    const associatedBillLines = useMemo(() => {
        const pieces = data?.fixedAsset.pieces ?? [];

        const billLines = pieces.map((piece) => piece.billLine).filter((piece) => !!piece);

        return billLines as BillLine[];
    }, [data?.fixedAsset]);

    return (
        <Drawer open={open} onOpenChange={onOpenChanged} className={fixedAssetsDrawer}>
            <Box
                css={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                }}>
                <Box css={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: `${S["1h"]} ${S["2"]} ${S["1h"]} ${S["3"]}`,
                    alignItems: "center",
                    borderBottom: `1px solid ${color.mauve600}`,
                }}>
                    <Box css={{ display: "inline-flex", gap: S["2"] }}>
                        <Text
                            variant="bodyM"
                            weight="bold">
                            Fixed asset
                        </Text>
                    </Box>
                    <div>
                        <IconButton onClick={() => onOpenChanged(false)} autoFocus>
                            <Close color="currentColor" size={12} />
                        </IconButton>
                    </div>
                </Box>
                <ScrollArea css={{ display: "flex", width: "100%", height: "100%", flex: 1 }}>
                    <ScrollArea.Viewport>
                        <Stack className={styledStack}>
                            {data?.fixedAsset.status === FixedAssetStatus.Draft && (
                                <PendingDetails form={pendingAssetForm} asset={data?.fixedAsset} />
                            )}

                            {data?.fixedAsset.status !== FixedAssetStatus.Draft && (
                                <DescriptionList
                                    direction="horizontal"
                                    termWidth={"175px"}
                                    items={[
                                        ["Status", data?.fixedAsset.status ? <StatusTag status={data?.fixedAsset.status} /> : "-"],
                                        ["Purchase date", purchasedAt],
                                        [
                                            "Cost",
                                            formatMoney({
                                                currency: "USD",
                                                amount: data?.fixedAsset.originalValue ?? 0,
                                            }),
                                        ],
                                        ["Description", data?.fixedAsset.description ?? "-"],
                                        ["Asset type", data?.fixedAsset.type ? startCase(data?.fixedAsset.type) : "-"],
                                        [
                                            "In-service date",
                                            data?.fixedAsset.inServiceAt ? dateFormatter.format(parseDate(data?.fixedAsset.inServiceAt)) : "-",
                                        ],
                                        [
                                            "Useful life",
                                            data?.fixedAsset.usefulLifeMonths
                                                ? `${pluralize(data?.fixedAsset.usefulLifeMonths, "month")}`
                                                : "-",
                                        ],
                                        [
                                            "Depreciation start",
                                            data?.fixedAsset.inServiceAt
                                                ? dateFormatter.format(parseDate(data?.fixedAsset.inServiceAt))
                                                : "-",
                                        ],
                                        [
                                            "Method",
                                            startCase(
                                                data?.fixedAsset.depreciationMethod ?? FixedAssetDepreciationMethod.StraightLine
                                            ),
                                        ],
                                        ["First month convention", "Half-month"],
                                    ]}
                                />
                            )}
                            {associatedTransactions.length > 0 ? (
                                <div>
                                    <Text variant="bodyS" style={{ marginBottom: S["1"] }}>
                                        {pluralize(associatedTransactions.length, "Transaction", undefined, false)}
                                    </Text>
                                    {associatedTransactions.map((transaction) => {
                                        if (!transaction) return null;
                                        return (
                                            <AssociatedTransaction
                                                key={transaction?.id}
                                                transactionDetail={transaction}
                                            />
                                        );
                                    })}
                                </div>
                            ) : null}
                            {associatedBillLines.length > 0 ? (
                                <div>
                                    <Text variant="bodyS" style={{ marginBottom: S["1"] }}>
                                        {pluralize(associatedBillLines.length, "Bill line", undefined, false)}
                                    </Text>
                                    {associatedBillLines.map((billLine) => {
                                        if (!billLine) return null;
                                        return <AssociatedBillLine key={billLine.id} billLine={billLine} />;
                                    })}
                                </div>
                            ) : null}
                        </Stack>
                        {data?.fixedAsset.status && ![FixedAssetStatus.Void].includes(data?.fixedAsset.status) && (
                            <Stack className={depreciationScheduleStack}>
                                <Text variant="bodyS">Depreciation schedule</Text>
                                <DepreciationScheduleTable asset={data?.fixedAsset} />
                            </Stack>
                        )}
                        <ScrollAnchor />
                    </ScrollArea.Viewport>
                    <ScrollArea.Scrollbar orientation="vertical" css={{ width: "10px !important" }}>
                        <ScrollArea.Thumb variant="shadowed" />
                    </ScrollArea.Scrollbar>
                </ScrollArea>
                {
                    data ? (
                        <ActionBar
                            form={pendingAssetForm}
                            queryResult={data}
                            onCompleted={() => onOpenChanged(false)}
                            onDeleted={onDeleteAsset}
                            onItemUpdated={onItemUpdated} />
                    ) : null
                }
            </Box>
        </Drawer>
    );
};
