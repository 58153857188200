import React, { useMemo, useState } from "react";

import { Button, Text, S, color } from "ve";

import { Confirmed, Undo, Unlink2, Linked2 } from "@puzzle/icons";

import { FullTransactionFragment } from "../graphql.generated";
import { DetailConfirmedState } from "graphql/types";

import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { UnlinkTransactionModal } from "components/transactions/UnlinkTransactionModal";
import { useToggleFinalizedState } from "components/dashboard/Transactions/hooks/useSingleTransaction";
import { FinalizeCCModal } from "components/transactions/FinalizeCCModal";
import { isCreditCard } from "components/transactions/utils";

import {
  statusButtonContainerStyle,
  statusButtonBadgeStyle,
  fBadgeStyle,
  fTextStyle,
} from "./actionButtons.css";

const StatusActionButton = ({
  statusIcon,
  statusText,
  buttonIcon,
  buttonText,
  onClick,
}: {
  statusIcon: React.ReactElement;
  statusText: string;
  buttonIcon: React.ReactElement;
  buttonText: string;
  onClick: () => void;
}) => {
  return (
    <div className={statusButtonContainerStyle}>
      <div className={statusButtonBadgeStyle}>
        {statusIcon}
        <Text variant="headingS" color="white" weight="bold">
          {statusText}
        </Text>
      </div>
      <Button variant="secondaryAlt" size="compact2" prefixElement={buttonIcon} onClick={onClick}>
        <Text variant="headingS" color="white" weight="bold">
          {buttonText}
        </Text>
      </Button>
    </div>
  );
};

const LinkedTransactionActionButton = ({
  transaction,
}: {
  transaction: FullTransactionFragment;
}) => {
  const { company } = useActiveCompany<true>();
  const [showModal, setShowModal] = useState(false);
  const linked = useMemo(() => Boolean(transaction && transaction.links.length), [transaction]);

  if (!linked) {
    return null;
  }

  return (
    <>
      <StatusActionButton
        statusIcon={<Linked2 size={16} color={color.greenalpha} />}
        statusText="Linked transaction"
        buttonIcon={<Unlink2 size={16} color={color.white70} />}
        buttonText="Unlink"
        onClick={() => setShowModal(true)}
      />
      <UnlinkTransactionModal
        transactionId={transaction.id}
        companyId={company.id}
        open={showModal}
        onOpenChange={setShowModal}
      />
    </>
  );
};

const UndoFinalizeActionButton = ({ transaction }: { transaction: FullTransactionFragment }) => {
  const toggleFinalizedState = useToggleFinalizedState(transaction);

  return (
    <StatusActionButton
      statusIcon={<Confirmed size={16} color={color.greenalpha} />}
      statusText="Finalized"
      buttonIcon={<Undo size={16} color={color.white70} />}
      buttonText="Undo"
      onClick={toggleFinalizedState}
    />
  );
};

const FinalizeActionButton = ({ transaction }: { transaction: FullTransactionFragment }) => {
  const toggleFinalizedState = useToggleFinalizedState(transaction);
  const [isConfirmCreditCard, setIsConfirmCreditCard] = useState(false);

  const creditCard = useMemo(
    () => transaction && isCreditCard(transaction, transaction?.detail.category),
    [transaction]
  );

  const handleFinalize = () => {
    if (creditCard) {
      setIsConfirmCreditCard(true);
      return;
    }

    toggleFinalizedState();
  };

  return (
    <>
      <Button
        variant="secondaryAlt"
        size="compact2"
        fullWidth={true}
        fullWidthContent={true}
        prefixElement={<Confirmed size={20} />}
        /* Restore once the "F" hotkey is available
        suffixElement={
          <div className={fBadgeStyle}>
            <span className={fTextStyle}>F</span>
          </div>
        }*/
        onClick={handleFinalize}
      >
        <Text variant="headingS" color="white" weight="bold" style={{ marginLeft: S.$1 }}>
          Finalize
        </Text>
      </Button>
      {creditCard && (
        <FinalizeCCModal
          open={isConfirmCreditCard}
          onConfirm={() => {
            toggleFinalizedState();
            setIsConfirmCreditCard(false);
          }}
          onCancel={() => setIsConfirmCreditCard(false)}
        />
      )}
    </>
  );
};

export const PrimaryStatusActionButton = ({
  transaction,
}: {
  transaction: FullTransactionFragment;
}) => {
  const linked = useMemo(() => Boolean(transaction && transaction.links.length), [transaction]);
  const isFinalized = transaction?.detail.confirmedState === DetailConfirmedState.Finalized;

  if (linked) {
    return <LinkedTransactionActionButton transaction={transaction} />;
  }

  if (isFinalized) {
    return <UndoFinalizeActionButton transaction={transaction} />;
  }

  return <FinalizeActionButton transaction={transaction} />;
};
