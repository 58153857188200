import React from "react";
import { IconProps } from "./Icon";

export default function CheckCircle({
  viewBox = "0 0 16 16",
  size = 16,
  color = "currentColor",
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={viewBox}
      fill="none"
    >
      <path
        d="M9.99998 6.33333L6.99998 10L5.66665 8.66666M14.1666 8C14.1666 11.4058 11.4057 14.1667 7.99998 14.1667C4.59422 14.1667 1.83331 11.4058 1.83331 8C1.83331 4.59424 4.59422 1.83333 7.99998 1.83333C11.4057 1.83333 14.1666 4.59424 14.1666 8Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
