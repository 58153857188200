import React from "react";
import { Button, Stack, Text, S, color } from "ve";
import { PendingAssetForm, assetSchema } from "components/dashboard/Accounting/FixedAssetsV2/hooks/usePendingAssetForm";
import { FixedAssetStatus } from "graphql/types";
import { useActiveCompany } from "components/companies";
import { isEditorRole } from "lib/roles";
import { useWatch } from "react-hook-form";
import { InServiceAssetActionCell } from "./InServiceActionCell";
import { GetFixedAssetByIdQuery } from "../../graphql.generated";

export const ACTIONBAR_TEST_IDS = {
  ROOT_COMPONENT: "action-bar-component",
  PENDING_FIXED_ASSET_CONTAINER: "pending-fixed-asset-container",
  IN_SERVICE_ACTIONS_CELL: "in-service-asset-action-cell"
}

const NON_ACTIONABLE_STATUSES = [
  FixedAssetStatus.Draft,
  FixedAssetStatus.Capitalized,
  FixedAssetStatus.InService,
  FixedAssetStatus.OnHold,
  FixedAssetStatus.Disposed
]

type ActionBarProps = {
  form: PendingAssetForm,
  queryResult: GetFixedAssetByIdQuery,
  onCompleted: () => void;
  onDeleted?: () => void;
  onItemUpdated: () => void;
}

export const ActionBar = ({ form, queryResult: asset, onCompleted, onItemUpdated, onDeleted }: ActionBarProps) => {
  const { membershipRole } = useActiveCompany<true>();
  const isEditor = isEditorRole(membershipRole);
  const formAsset = useWatch({ control: form.control });

  if (!NON_ACTIONABLE_STATUSES.includes(asset.fixedAsset.status)) {
    return null;
  }

  const isValidAsset = assetSchema.isValidSync(formAsset);
  const isSubmitting = form.submittingAssetId === asset.fixedAsset.id;

  return (
    <Stack
      data-testid={ACTIONBAR_TEST_IDS.ROOT_COMPONENT}
      direction="horizontal"
      css={{
        padding: S["3"],
        borderTop: `1px solid ${color.mauve600}`,
        justifyContent: asset.fixedAsset.status === FixedAssetStatus.Draft ? "space-between" : "flex-end",
        alignItems: "center",
      }}
    >
      {asset.fixedAsset.status === FixedAssetStatus.Draft && (
        <div data-testid={ACTIONBAR_TEST_IDS.PENDING_FIXED_ASSET_CONTAINER}>
          <Text>Pending fixed asset</Text>
          <Stack direction="horizontal" gap="1">
            <Button
              variant="outline"
              color="negative"
              size="compact"
              onClick={() => {
                form.onDeleteAsset(asset.fixedAsset.id);
                onDeleted ? onDeleted() : undefined;
              }}
            >
              Remove
            </Button>
            <Button
              size="compact"
              variant="primary"
              disabled={!isEditor || !isValidAsset}
              loading={isSubmitting}
              onClick={() => {
                form.onSubmitAsset(asset.fixedAsset.id);
              }}
            >
              Submit
            </Button>
          </Stack>
        </div>
      )}
      {asset.fixedAsset.status !== FixedAssetStatus.Draft && (
        <InServiceAssetActionCell
          data-testid={ACTIONBAR_TEST_IDS.IN_SERVICE_ACTIONS_CELL}
          asset={asset}
          triggerStyle="button"
          onCompleted={onCompleted}
          onItemUpdated={onItemUpdated}
        />
      )}
    </Stack>
  );
};
