import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Undo({
  viewBox = "0 0 16 16",
  color = "currentColor",
  stroke = color,
  width = 16,
  height = 16,
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} {...iconProps} viewBox={viewBox} fill="none">
      <path
        d="M4.16664 3.33301L2.20708 5.29257C1.81655 5.68309 1.81655 6.31626 2.20708 6.70678L4.16664 8.66634M2.66664 5.99967H11.0833C12.7862 5.99967 14.1666 7.38013 14.1666 9.08301C14.1666 10.7859 12.7862 12.1663 11.0833 12.1663H7.8333"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
