import { CalendarDateString, sumAmounts } from "@puzzle/utils";
import Big from "big.js";

export type ManualEntries = {
  amount: string;
  effectiveDate: CalendarDateString;
}[];

export const useRemainingToRecognize = ({
  amount,
  entries,
}: {
  amount: string;
  entries?: ManualEntries;
}) => {
  const amountRecognized = entries?.length
    ? sumAmounts(entries.filter((entry) => !!entry.amount).map((entry) => entry.amount))
    : 0;

  const remainingToRecognize = Big(amount).minus(amountRecognized);

  return remainingToRecognize;
};
