import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Upload({
  viewBox = "0 0 17 16",
  width = 17,
  height = 16,
  fill = "none",
  color = "currentColor",
  stroke = color,
  ...iconProps
}: IconProps) {
  return (
    <Icon height={height} width={width} fill={fill} {...iconProps} viewBox={viewBox}>
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity="0.8"
        strokeWidth="1.5"
        d="M8.5 2.5V10m0-7.5l3 3m-3-3l-3 3m8.5 3v3a2 2 0 01-2 2H5a2 2 0 01-2-2v-3"
      ></path>
    </Icon>
  );
}
