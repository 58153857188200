import posthog from "posthog-js";

export enum FeatureFlag {
  SetupChecklist = "setup-checklist",
  FixedAssetsAutoDepreciation = "fixed-assets-auto-depreciation",
  InexactBillMatch = "inexact-bill-match",
  ARAging = "ar-aging-by-customer",
  ClassesAndDeptsCols = "classes-and-depts-cols",
  ClassesAndDeptsM1 = "classes-and-depts-m1",
  DashboardSpotlightShowGroupingDropdown = "dashboard-spotlight-show-grouping-dropdown",
  ShowAddonPuzzleAI = "show-addon-puzzleai",
  SubscriptionYearly = "subscription-yearly",
  AutomationSettings = "automation-settings",
  EnableEventBreakout = "enable-event-breakout",
  UnlinkBills = "unlink-bills",
  PostOnboardModalV3Invitee = "post-onboard-modal-v3-invitee",
  DashboardShowPercentageChanged = "dashboard-show-percentage-changed",
  InvoicingV2 = "invoicing-v2",
  ApolloCachePersistor = "apollo-cache-persistor",
  OnboardingConsolidatedConnections = "onboarding_consolidated_connections",
  StripeTraceability = "stripe-traceability",
  DashboardChartBurnComparison = "dashboard-chart-burn-comparison",
  Payments = "payments",
  RipplingIntegrationConnection = "rippling-integration-connection",
  VendorsV2 = "vendors-v2",
  SetStripeEpoch = "set-stripe-epoch",
  CustomersPage = "customers-page",
  EditPostedBill = "edit-posted-bills",
  EditPostedBillWithSchedules = "edit-posted-bills-with-schedules",
  FStoFilteredGL = "fs_to_filtered_gl",
  TransactionsTableDescriptionHover = "transactions-table-description-hover",
  Z = "zindex",
  AccountingAutoBalanceBanner = "accounting-auto-balance-banner",
  TransactionsTablePagination = "transactions-table-pagination",
  HeaderHelpMenu = "header-help-menu",
  GeneralLedgerMRT = "general-ledger-mrt",
  RevenueRecognitionOnboarding = "rev-rec-onboarding",
  ProductsPage = "products-page",
  HistoricalBooksV2 = "historical-books-v2",
  TrialExpiredModalV2 = "trial-expired-modal-v2",
  BillingPlansPageV2 = "billing-plans-page-v2",
  SubscriptionUpdatedModal = "subscription-updated-modal",
  TransactionsTableHotkeys = "transactions-table-hotkeys",
  ReqIngestOprionOnReconnectIntegration = "req-ingest-oprion-on-reconnect-integration",
}

// Manually override flags to be enabled on local + dev for testing
export const FLAGS_ENABLED_ON_DEV = [
  FeatureFlag.TrialExpiredModalV2,
  FeatureFlag.FixedAssetsAutoDepreciation,
  FeatureFlag.ARAging,
  FeatureFlag.ClassesAndDeptsM1,
  FeatureFlag.UnlinkBills,
  FeatureFlag.ApolloCachePersistor,
  FeatureFlag.StripeTraceability,
  FeatureFlag.SetStripeEpoch,
  FeatureFlag.TransactionsTableDescriptionHover,
  FeatureFlag.TransactionsTablePagination,
  FeatureFlag.GeneralLedgerMRT,
  FeatureFlag.TransactionsTableHotkeys,
  FeatureFlag.Z, // show new z-index values in development
  /*

    Regarding FeatureFlag.Z:

    You might be here because you now see a z-index problem in development
    that has leaked into an area you are working on.
    Don't panic. The fix is easy. Go to utils/src/zIndex/index.ts:
    Find the element name you need or add a new element where it should be in the stack.
    The zIndexElements array is ordered in the way that the elements will be indexed in the DOM.

  */
  FeatureFlag.RipplingIntegrationConnection, // leaving on cause dev isn't showing correct value
  // FeatureFlag.DashboardChartBurnComparison, // for testing Net Burn MoM card
  FeatureFlag.RevenueRecognitionOnboarding,
  // FeatureFlag.HistoricalBooksV2, // for testing new historical books
];

// Manually override flags to be enabled on staging
// Careful! staging is used for demos, make sure the feature is suited for a demo environment
export const FLAGS_ENABLED_ON_STAGING = [
  // This is so that Rippling can test the integratino in a demo company before we release it more broadly in prod
  FeatureFlag.RipplingIntegrationConnection,
];

export const isPosthogFeatureFlagEnabled = (featureFlag: FeatureFlag) => {
  // this allows for setting permanent feature flags in the ENV (local or otherwise)
  // presence of the var == flag set globally
  if (process.env["FFLAG_" + (featureFlag as string).replace("-", "_").toUpperCase()]) {
    return true;
  }
  return posthog.isFeatureEnabled(featureFlag, { send_event: false });
};
