import React, { useRef, useEffect, useCallback } from "react";
import { styled, IconButton, ScrollArea, hotkeys } from "@puzzle/ui";
import { Close } from "@puzzle/icons";
import Loader from "components/common/Loader";
import { Separator } from "ve";
import { useHideIntercomOnMount } from "components/common/hooks/useHideIntercomOnMount";
import CommentBox from "./CommentBox";
import { useDetailPaneContext } from "./DetailPaneContext";
import { useTransactionsPage } from "components/dashboard/Transactions/TransactionsProvider";

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
});

const Header = styled("header", {
  display: "flex",
  justifyContent: "space-between",
  padding: "$1h $2 $1h $3",
  alignItems: "center",
  fontSize: "15px",
  lineHeight: "22px",
  letterSpacing: "0.2px",
  fontWeight: "$bold",
  color: "$gray500",
  borderBottom: "1px solid $mauve550",
});

const Body = styled(ScrollArea.Viewport, {
  padding: "$3",
});

const HeaderActions = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
});

const ScrollAnchor = styled("div", {
  marginTop: -10,
  height: 10,
  zIndex: -1,
});

const DetailLayout = ({
  children,
  onNext,
  onPrevious,
}: {
  children: React.ReactNode;
  onNext?: () => void;
  onPrevious?: () => void;
}) => {
  const { setActiveTransaction } = useTransactionsPage();
  const { transaction, loading, close, isNewAssetDrawerOpen, isScheduleDrawerOpen } =
    useDetailPaneContext<true>();
  useHideIntercomOnMount();

  const handleClose = useCallback(() => {
    // If a drawer is open when escape is pressed, ONLY let that drawer close, NOT the panel.
    // If no drawer is open, close the panel.
    if (close && !isNewAssetDrawerOpen && !isScheduleDrawerOpen) {
      // unhighlight transaction row
      setActiveTransaction(null);
      // close the drawer
      close();
    }
  }, [close, isNewAssetDrawerOpen, isScheduleDrawerOpen]);

  // Handle escape key to close the drawer separate from the hotkeys so that we can properly check drawer state.
  useEffect(() => {
    const handleEscape = (event: any) => {
      if (event.key === "Escape") {
        handleClose();
      }
    };

    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [handleClose]);

  useEffect(() => {
    const unsubscribe = hotkeys(
      window,
      {
        J: () => {
          onNext && onNext();
        },
        ArrowDown: () => {
          onNext && onNext();
        },
        K: () => {
          onPrevious && onPrevious();
        },
        ArrowUp: () => {
          onPrevious && onPrevious();
        },
      },
      { ignore: { elements: ["input", "textarea", "select"] } }
    );
    return () => {
      unsubscribe();
    };
  }, [onNext, onPrevious]);

  const scrollAnchorRef = useRef<HTMLDivElement | null>(null);

  return (
    <Container data-testid="transactions_details">
      <Header>
        <HeaderActions>
          <IconButton onClick={handleClose} autoFocus>
            <Close color="currentColor" size={12} />
          </IconButton>
        </HeaderActions>
      </Header>

      <ScrollArea css={{ flex: 1 }}>
        <Body>
          {loading ? <Loader size={48} /> : children}

          <ScrollAnchor ref={scrollAnchorRef} />
        </Body>

        <ScrollArea.Scrollbar orientation="vertical" css={{ width: "10px !important" }}>
          <ScrollArea.Thumb variant="shadowed" />
        </ScrollArea.Scrollbar>
      </ScrollArea>

      <Separator />

      {transaction && (
        <CommentBox
          transaction={transaction}
          onSave={() => {
            scrollAnchorRef.current?.scrollIntoView({ behavior: "smooth" });
          }}
        />
      )}
    </Container>
  );
};

export default DetailLayout;
