import React, { useCallback, useMemo } from "react";

import { useActiveCompany } from "components/companies";
import { FullTransactionFragment } from "../graphql.generated";
import useAssignments from "../hooks/useAssignments";
import useTransactionPageUsers from "../hooks/useTransactionPageUsers";
import useSelf from "components/users/useSelf";

import { Button, Text } from "ve";

import { container, assignmentInfo, assignmentActions } from "./assignmentSection.css";

export const AssignmentsSection = React.memo(function AssignmentsSection({
  transaction,
  assignmentTypeFilter,
}: {
  transaction: FullTransactionFragment;
  assignmentTypeFilter: (type: string) => boolean;
}) {
  const { company } = useActiveCompany<true>();
  const { self } = useSelf();
  const { activeUsers } = useTransactionPageUsers({ companyId: company.id });

  const {
    currentAssignment,
    pendingAssignment,
    completeAssignmentLoading,
    cancelAssignmentLoading,
    assignUserLoading,
    saveAssignment,
    updateAssignmentText,
    defaultRequests,
    setCurrentlyAssigningTo,
    startNewAssignment,
    markAssignmentAsComplete,
    markAssignmentAsCanceled,
  } = useAssignments(activeUsers, transaction);

  const confirmAssignment = useCallback(() => {
    saveAssignment();
  }, [saveAssignment]);

  const handleAssignmentComplete = useCallback(async () => {
    await markAssignmentAsComplete();
  }, [markAssignmentAsComplete]);

  const handleAssignmentCancel = useCallback(async () => {
    await markAssignmentAsCanceled();
  }, [markAssignmentAsCanceled]);

  const content = useMemo(() => {
    if (!(completeAssignmentLoading || cancelAssignmentLoading) && currentAssignment) {
      // TODO: Can possibly use optimisticResponse during mutations here instead of loader?
      return (
        <div className={container}>
          <div className={assignmentInfo}>
            <Text variant="bodyS" color="white" weight="bold">
              {currentAssignment.createdByUser ? currentAssignment.createdByUser.name : "System"}{" "}
            </Text>
            <Text variant="bodyS" color="gray400">
              requested{" "}
            </Text>
            <Text variant="bodyS" color="white" weight="bold">
              {currentAssignment.request}
            </Text>
            <Text variant="bodyS" color="gray400">
              {" "}
              from{" "}
            </Text>
            <Text variant="bodyS" color="white" weight="bold">
              {currentAssignment.targetUser?.name}
            </Text>
          </div>

          <div className={assignmentActions}>
            <Button variant="primary" size="small" onClick={handleAssignmentComplete}>
              Completed
            </Button>
            <Button variant="secondary" size="small" onClick={handleAssignmentCancel}>
              Unassign
            </Button>
          </div>
        </div>
      );
    }
  }, [
    completeAssignmentLoading,
    cancelAssignmentLoading,
    currentAssignment,
    assignUserLoading,
    defaultRequests,
    pendingAssignment,
    activeUsers,
    confirmAssignment,
    handleAssignmentComplete,
    handleAssignmentCancel,
    startNewAssignment,
    updateAssignmentText,
    setCurrentlyAssigningTo,
    self,
  ]);

  if (!assignmentTypeFilter(currentAssignment?.request || "")) {
    return null;
  }

  return <>{content}</>;
});
