import React from "react";
import { IconProps } from "./Icon";

const Store = ({ size = 16, viewBox = "0 0 16 16", color = "currentColor" }: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox={viewBox} fill="none">
    <path
      d="M14.1667 5.16779L12.4114 2.88965C12.2221 2.64394 11.9294 2.5 11.6192 2.5H4.38083C4.07065 2.5 3.77802 2.64394 3.5887 2.88965L1.83337 5.16779M14.1667 5.16779V5.83474C14.1667 6.42732 13.9021 6.95973 13.4815 7.3261M14.1667 5.16779H1.83337M1.83337 5.16779V5.83474C1.83337 6.42732 2.09803 6.95973 2.51856 7.3261M10.0556 5.83474C10.0556 6.93977 10.9759 7.83558 12.1112 7.83558C12.6376 7.83558 13.1179 7.64293 13.4815 7.3261M10.0556 5.83474V5.16779M10.0556 5.83474C10.0556 6.93977 9.13529 7.83558 8.00004 7.83558C6.86479 7.83558 5.94449 6.93977 5.94449 5.83474M5.94449 5.83474C5.94449 6.93977 5.02418 7.83558 3.88893 7.83558C3.36246 7.83558 2.88223 7.64293 2.51856 7.3261M5.94449 5.83474V5.16779M9.3704 13.5L9.37041 11.4992C9.37041 10.7625 8.75688 10.1653 8.00004 10.1653C7.24321 10.1653 6.62968 10.7625 6.62968 11.4992L6.62967 13.5M2.51856 7.3261V12.5C2.51856 13.0523 2.96627 13.5 3.51856 13.5H12.4815C13.0338 13.5 13.4815 13.0523 13.4815 12.5V7.3261"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Store;
