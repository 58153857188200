import React from "react";
import Image from "next/image";
import links from "lib/links";

import { Dialog } from "@puzzle/ui";
import { Button, Stack, Text, vars, S } from "ve";
import { Exclamation, PaidAddOn } from "@puzzle/icons";
import RipplingLogo from "./Logo";
import { RipplingStepCard } from "./ConnectRipplingStepCard";
import { useActiveCompany } from "components/companies";
import { IntegrationConnectionStatus, IntegrationType } from "graphql/types";
import { useRippling } from "./useRippling";
import useMonetization from "components/monetization/useMonetization";
import { AddOnNames, SubscriptionBillingCycle } from "components/monetization/types";
import { RIPPLING_COST_PER_SEAT } from "components/monetization/PricingModal/plans";

import { warningPane, importantRow, imageContainer } from "./connectRipplingModal.css";

import ripplingCreateAccount from "public/integrations/rippling/ripplingCreateAccount.png";

export type RipplingStep = {
  title: string;
  subText?: string;
  onClick?: () => void;
};

const connectRipplingSteps: RipplingStep[] = [
  { title: "Connect to Rippling", subText: `$${RIPPLING_COST_PER_SEAT}/employee/month` },
  {
    title: "Connect to Rippling",
    subText: `The $${RIPPLING_COST_PER_SEAT}/employee/month rate is a passthrough cost for accessing the Rippling API.`,
  },
  { title: "Upgrade to a paid plan" },
];

export const ConnectRipplingModal = ({
  open,
  onOpenChange,
  showSteps = true,
  forceShowWarning = false,
  explainCosts = false,
}: {
  open: boolean;
  onOpenChange: () => void;
  showSteps?: boolean;
  forceShowWarning?: boolean;
  explainCosts?: boolean;
}) => {
  const { company, isOnPaidPlan } = useActiveCompany<true>();
  const { onClickConnect } = useRippling({ companyId: company.id });
  const { showUpgradeModal, setSelectedCycle, updatePreSelectedAddOns } = useMonetization();
  const hasRipplingConnection = Boolean(
    company?.integrationConnections?.find(
      (c) => c.type === IntegrationType.Rippling && c.status === IntegrationConnectionStatus.Ok
    )
  );

  const _showUpgradeModal = async () => {
    await setSelectedCycle(SubscriptionBillingCycle.Monthly);
    updatePreSelectedAddOns([AddOnNames.ADDON_RIPPLING]);
    showUpgradeModal();
  };

  const buttonText = hasRipplingConnection ? "Upgrade" : "Continue";
  const buttonVariant = hasRipplingConnection ? "gold" : "primary";
  const buttonOnClick = hasRipplingConnection ? _showUpgradeModal : onClickConnect;
  const showWarning = forceShowWarning ? true : !hasRipplingConnection;

  return (
    <Dialog size="small" open={open} onOpenChange={onOpenChange}>
      <Dialog.Title>
        <Stack direction={"horizontal"} style={{ alignItems: "center", gap: S.$1 }}>
          <div style={{ width: "40px", height: "40px", marginRight: S.$1 }}>
            <RipplingLogo circular={true} />
          </div>
          <Text variant="headingM" color="white" weight="heavy">
            Connect to Rippling
          </Text>
          <PaidAddOn width={18} height={18}></PaidAddOn>
        </Stack>
      </Dialog.Title>
      <Dialog.Body style={{ padding: vars.space["2h"] }}>
        <Stack direction="vertical">
          <Text variant="headingS" color="gray100">
            Gain a more complete view of your financials by connecting your payroll data in Rippling
            to Puzzle.
          </Text>
          {showSteps && (
            <Text variant="headingS" color="gray100">
              To start ingesting your Rippling data:
            </Text>
          )}
          {showSteps && (
            <RipplingStepCard
              step={explainCosts ? connectRipplingSteps[1] : connectRipplingSteps[0]}
              stepNumber={1}
              href={"#"}
              onClick={onClickConnect}
              completed={hasRipplingConnection}
            />
          )}
          {showWarning && (
            <div className={warningPane}>
              <div className={importantRow}>
                <Exclamation size={16} />
                <Text variant="bodyM" color="brown800" weight="bold">
                  Important:
                </Text>
              </div>
              <Text variant="bodyM" color="brown800" weight="bold">
                When you connect to Rippling you will be asked who should automatically get access
                to Puzzle. We strongly recommend that you select "Don't create accounts; I will
                manually select who should get access".
              </Text>
              <div className={imageContainer}>
                <Image
                  src={ripplingCreateAccount}
                  alt="Rippling create account"
                  width={400}
                  style={{ borderRadius: vars.radii[2] }}
                />
              </div>
            </div>
          )}
          {showSteps && (
            <RipplingStepCard
              step={connectRipplingSteps[2]}
              stepNumber={2}
              href={"#"}
              onClick={_showUpgradeModal}
              completed={isOnPaidPlan && !!company.features.ripplingAddOnSelected}
            />
          )}
          {explainCosts && (
            <div>
              <Text variant="headingS" color="gray100">
                <b>NOTE:</b> If you'd like to avoid the paid requirement of the Rippling
                integration, you can upload your Rippling data manually.{" "}
                <a
                  href={links.manualPayrollHowTo}
                  target="_blank"
                  style={{ textDecoration: "none" }} rel="noreferrer"
                >
                  <Text color="blue500">Learn how</Text>
                </a>
              </Text>
            </div>
          )}
        </Stack>
      </Dialog.Body>
      <Dialog.Footer>
        <Button variant={buttonVariant} onClick={() => buttonOnClick()}>
          {buttonText}
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
};
