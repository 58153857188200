import React, { useMemo } from "react";

import { Stack, Text, color, S } from "ve";

import { Linked2, Sparkle3 } from "@puzzle/icons";

import { FullTransactionFragment, TransactionLinkFragment } from "../graphql.generated";
import {
  TransactionDetailActorType,
  TransactionCategorized,
  TransactionMemoType,
} from "graphql/types";

const Icon = ({
  linked,
  actorType,
}: {
  linked: boolean;
  actorType: TransactionDetailActorType;
}) => {
  if (linked) {
    return <Linked2 size={16} color={color.greenalpha} />;
  }
  if (actorType === TransactionDetailActorType.SystemActor) {
    return <Sparkle3 size={16} color={color.purple500} />;
  }
  return null;
};

function getLinkedText(linkedBills: TransactionLinkFragment[]) {
  if (linkedBills.length === 1) return "a bill";
  if (linkedBills.length > 1) return "multiple bills";

  // default text if there is a link and none of the links
  // are to a bill
  return "another transaction";
}

export const CategorizedBy = ({
  transaction,
  css,
}: {
  transaction: FullTransactionFragment;
  css?: React.CSSProperties;
}) => {
  const activity = transaction.activity?.activity;
  const linked = Boolean(transaction && transaction.links.length);
  const linksToBills = transaction.links.filter((l) => l.bill);
  const linkedItemText = getLinkedText(linksToBills);

  const { actorType, categorizedByText } = useMemo(() => {
    if (!activity || activity.length < 1) {
      return { actorType: null, actorName: null };
    }

    const lastCategorizationActivity = activity.findLast((activity) =>
      [
        TransactionMemoType.TransactionCategorized,
        // TransactionMemoType.TransactionImplicitlyCategorized, // this might mean "rule", follow up on how to handle this later
      ].includes(activity.type)
    ) as TransactionCategorized;

    const actorType = lastCategorizationActivity?.detail?.actorType;
    const actorName = lastCategorizationActivity?.actor?.name || "a user";

    const categorizedByText = linked
      ? `Linked to ${linkedItemText}`
      : actorType === TransactionDetailActorType.SystemActor
      ? "System suggested category"
      : `Categorized by ${actorName}`;

    return { actorType, categorizedByText };
  }, [activity, linked, linkedItemText]);

  if (!actorType) {
    return null;
  }

  return (
    <Stack direction="horizontal" gap={S["1"]} css={css}>
      <Icon linked={linked} actorType={actorType} />
      <Text variant="bodyXS" color="white70">
        {categorizedByText}
      </Text>
    </Stack>
  );
};

/***
 * For rules, possibly:
  <div className={rowStyle}>
        <Rule2 size={16} color={color.blue500} />
        <Text variant="bodyXS" color="white70">
          Categorized by a <b style={{ color: color.white }}>rule</b>
        </Text>
      </div>
 */
