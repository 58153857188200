import React, { useMemo, useState } from "react";
import { Column } from "react-table";

import { styled, Text } from "@puzzle/ui";
import { DataTable } from "components/common/DataTable";
import Link from "components/common/Link";
import { Route } from "lib/routes";

import {
  AccountTransactionDetailJournalFragment,
  AccountTransactionDetailJournalEntryFragment,
  useGetCompanyJournalQuery,
} from "./graphql.generated";
import { glAmountToDisplayString } from "./LedgerAccountViewBody";
import { LedgerAccountFragment } from "../shared/chartOfAccounts.graphql.generated";
import { useActiveCompany, useCompanyDateFormatter } from "components/companies";
import { parseAbsolute } from "@internationalized/date";
import { DetailDrawer } from "components/dashboard/Transactions/DetailPane";
import TransactionsPageProvider from "components/dashboard/Transactions/TransactionsProvider";
import { compact } from "lodash";
import Loader from "components/common/Loader";
import { FixedAssetDetailsDrawer } from "./Drawers/FixedAssetDetaisDrawer/FixedAssetDetailsDrawer";

type EntryRow = { row: { original: AccountTransactionDetailJournalEntryFragment } };

const StyledEntry = styled("div", {
  position: "relative",
  padding: "$4h 0",
  margin: "0 15% 0 2%", // Same as first and last columns of parent table so they are aligned
});

const StyledFrame = styled("div", {
  background: "$gradients$labelLight",
  borderRadius: "$1",
  color: "$gray400",
  marginBottom: "$5",
  padding: "$1 $2",
});

const MutedHeader = styled("div", {
  color: "$gray400",
  fontSize: "$bodyS",
});

const FooterCell = styled("div", {
  color: "$gray300",
  fontSize: "$body",
  fontWeight: "$bold",
  textAlign: "right",
  padding: "$0h 0",
});

const StyledMetadata = styled("div", {
  border: "1px solid $mauve650",
  display: "flex",
});

const StyledBox = styled("div", {
  padding: "$3",
});

const StyledLabel = styled(Text, {
  color: "$gray500",
  fontWeight: "$bold",
  marginRight: "$1",
  width: "108px",
});

const StyledRow = styled("div", {
  alignItems: "center",
  display: "flex",
  marginBottom: "$2",

  "&:last-of-type": {
    marginBottom: 0,
  },
});

const Root = styled("div", {
  marginBottom: "$4",
  table: {
    "thead, tbody": {
      th: {
        border: "none",
        backgroundColor: "inherit",
      },
    },
    tfoot: {
      td: {
        borderLeft: "none",
        borderRight: "none",
        borderBottom: "1px solid $tableRowBorder",

        "&:first-of-type": {
          borderBottom: "none",
          position: "relative",

          "&::after": {
            content: "",
            position: "absolute",
            right: 0,
            bottom: -1,
            height: "1.5px",
            width: "50px",
            background: "$tableRowBorder",
          },
        },
      },
    },
  },
});

const RelatedEvents = ({
  event,
  onOpenTransaction,
  onOpenFixedAssetId,
}: {
  event: AccountTransactionDetailJournalFragment["event"];
  onOpenTransaction: (id: string) => void;
  onOpenFixedAssetId: (id: string) => void;
}) => {
  const events = useMemo(() => {
    return compact([
      event.type === "manual_journal_entry" && {
        href: `${Route.manualJournals}/${event.externalId}`,
        children: `JE-${event.shortExternalId}`,
      },

      event.type === "historical_journal_entry" && {
        href: `${Route.manualJournals}/${event.externalId}`,
        children: `JE-${event.shortExternalId}`,
      },

      event.bill?.id && {
        href: `${Route.bills}/${event.bill.id}`,
        children: `BILL-${event.bill.id.slice(-6).toUpperCase()}`,
      },

      event.transaction?.id && {
        onClick: () => onOpenTransaction(event.transaction!.id),
        children: event.transaction.descriptor,
      },

      event.invoice?.id && {
        href: `${Route.invoices}/${event.invoice.id}`,
        children: `INV-${event.invoice.id.slice(-6).toUpperCase()}`,
      },

      event.type === "payroll" && {
        href: `${Route.payroll}/${event.externalId}`,
        children: `PAYROLL-${event.shortExternalId}`,
      },

      event.fixedAsset?.id && {
        onClick: () => {
          onOpenFixedAssetId(event.fixedAsset!.id);
        },
        children: `FA-${event.fixedAsset?.id.slice(-6).toUpperCase()}`
      },

      event.prepaidExpense?.id && {
        onClick: () => { },
        children: `PE-${event.prepaidExpense?.id.slice(-6).toUpperCase()}`
      },
    ]);
  }, [event, onOpenTransaction]);

  return (
    <div>
      {events.map((event, i) => {
        return (
          <div key={`${event.children}-${i}`}>
            <Link css={{ whiteSpace: "nowrap" }} target="_blank" underline {...event} />
            {i !== events.length - 1 && (
              <Text color="gray500" css={{ marginRight: "$0h" }}>
                ,{" "}
              </Text>
            )}
          </div>
        );
      })}
    </div>
  );
};

export const LedgerAccountTransactionJournal = ({
  journalId,
  chartOfAccountsById,
  onItemUpdated
}: {
  journalId: string;
  chartOfAccountsById: Record<string, LedgerAccountFragment>;
  onItemUpdated: () => void;
}) => {
  const [transactionId, setTransactionId] = useState<string>();

  const [fixedAssetId, setFixedAssetId] = useState<string | null>(null);

  const { timeZone, company } = useActiveCompany<true>();

  const { data, loading, error } = useGetCompanyJournalQuery({
    skip: !company,
    variables: { companyId: company.id, journalId },
  });

  const journal = data?.company?.journal;
  const dateFormatter = useCompanyDateFormatter({
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  const subtotals: Record<string, number> = useMemo(() => {
    if (!journal?.entries) return { credit: 0, debit: 0 };

    return journal?.entries.reduce(
      (acc, entry) => {
        const value = Number(entry.amount);
        const type = value > 0 ? "debit" : "credit";
        acc[type] += Math.abs(value);
        return acc;
      },
      { credit: 0, debit: 0 }
    );
  }, [journal?.entries]);

  const columns: Column<AccountTransactionDetailJournalEntryFragment>[] = [
    {
      Header: () => <MutedHeader>Account</MutedHeader>,
      Cell: ({ row }: EntryRow) => {
        const account = chartOfAccountsById[row.original.accountId as string];
        return (
          <>
            <Text css={{ marginRight: "$3" }}>{account.displayId}</Text>
            <Text>{account.displayName}</Text>
          </>
        );
      },
      style: { borderRight: "none" },
      id: "lineAccountId",
      align: "left",
      width: "62.7%",
      Footer: () => <FooterCell>Total</FooterCell>,
    },
    {
      Header: () => <MutedHeader>Debit</MutedHeader>,
      id: "lineDebit",
      accessor: (row) => {
        const value: number = Number(row.amount) > 0 ? Number(row.amount) : 0;
        return value > 0 ? value : "";
      },
      Cell: ({ value }: { value: string }) => glAmountToDisplayString(value),
      align: "right",
      width: "10%",
      Footer: () => <FooterCell>{glAmountToDisplayString(subtotals.debit || 0)}</FooterCell>,
    },
    {
      Header: () => <MutedHeader>Credit</MutedHeader>,
      id: "lineCredit",
      accessor: (row) => {
        const value: number = Number(row.amount) < 0 ? Number(row.amount) * -1 : 0;
        return value > 0 ? value : "";
      },
      Cell: ({ value }: { value: string }) => glAmountToDisplayString(value),
      align: "right",
      width: "10%",
      Footer: () => <FooterCell>{glAmountToDisplayString(subtotals.credit || 0)}</FooterCell>,
    },
  ];

  const handleFixedAssetUpdated = async () => {
    setFixedAssetId(null);

    onItemUpdated();
  };

  if (loading && !journal) {
    return (
      <StyledBox>
        <Loader />
      </StyledBox>
    );
  }

  if (!journal || error) {
    return (
      <StyledBox>
        <p>Error loading journal, please refresh</p>
      </StyledBox>
    );
  }

  return (
    <StyledEntry>
      <TransactionsPageProvider>
        <DetailDrawer
          id={transactionId}
          onClose={() => {
            setTransactionId(undefined);
          }}
        />
      </TransactionsPageProvider>

      {
        fixedAssetId ? (
          <FixedAssetDetailsDrawer
            open={!!fixedAssetId}
            onOpenChanged={(open) => {
              if (!open) setFixedAssetId(null);
            }}
            fixedAssetId={fixedAssetId}
            onItemUpdated={handleFixedAssetUpdated} />
        ) : null
      }

      <StyledFrame>
        Journal entry #
        {journal.event.type &&
          ["manual_journal_entry", "historical_journal_entry"].includes(journal.event.type)
          ? journal.event.shortExternalId
          : journal.shortId}
      </StyledFrame>
      <Root>
        <DataTable<AccountTransactionDetailJournalEntryFragment>
          data={journal.entries}
          columns={columns}
          bordered
        />
      </Root>
      <StyledMetadata>
        <StyledBox css={{ flex: 1, borderRight: "1px solid $mauve650" }}>
          <StyledRow>
            <StyledLabel>Posted date</StyledLabel>
            <Text>{dateFormatter.format(parseAbsolute(journal.postedAt, timeZone))}</Text>
          </StyledRow>
          <StyledRow>
            <StyledLabel>Effective date</StyledLabel>
            <Text>{dateFormatter.format(parseAbsolute(journal.effectiveAt, timeZone))}</Text>
          </StyledRow>
          {journal.reversedJournal?.id && (
            <StyledRow>
              <StyledLabel>Reversed by</StyledLabel>
              <Link
                href={`${Route.manualJournals}/${journal.reversedJournal.id}`}
                target="_blank"
                underline
              >
                {journal.reversedJournal.shortId}
              </Link>
            </StyledRow>
          )}
        </StyledBox>
        <StyledBox css={{ flex: 1.5 }}>
          <StyledRow>
            <StyledLabel>Related events</StyledLabel>
          </StyledRow>
          <StyledRow>
            <RelatedEvents event={journal.event} onOpenTransaction={setTransactionId} onOpenFixedAssetId={setFixedAssetId} />
          </StyledRow>
        </StyledBox>
      </StyledMetadata>
    </StyledEntry>
  );
};
