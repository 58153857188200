import { useMemo } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useActiveCompany } from "components/companies";
import { today, CalendarDate } from "@puzzle/utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { CategoryFragment } from "graphql/types";

export type ManualImportFormValues = {
  items: {
    date: CalendarDate;
    description: string;
    amount: number;
    category?: CategoryFragment;
  }[];
};

export const validationSchema = yup.object({
  items: yup.array().of(
    yup.object({
      date: yup.object().required(),
      description: yup.string().required(),
      amount: yup.number().required(),
      category: yup.object().required(),
    })
  ),
});

export const useManualImportForm = () => {
  const { timeZone } = useActiveCompany<true>();
  const form = useForm<ManualImportFormValues>({
    mode: "onChange",
    defaultValues: {
      items: [
        {
          description: "",
          amount: 0,
          date: today(timeZone),
          category: undefined,
        },
      ],
    },
    resolver: yupResolver(validationSchema),
  });

  return useMemo(() => ({ form }), [form]);
};
