import { Button, DateInput, Drawer, IconButton, Input, Select, Stack, Text } from "@puzzle/ui";
import React from "react";
import { Controller } from "react-hook-form";
import { formatMoney, parseDate, toCalendarDate, zIndex } from "@puzzle/utils";
import { CATEGORY_OPTIONS, USEFUL_LIFE_OPTIONS } from "./hooks/usePendingAssetsForm";
import { FixedAssetDepreciationMethod } from "graphql/types";
import DescriptionList from "components/common/DescriptionList";
import { Close } from "@puzzle/icons";
import { TransactionForFixedAssetsFragment } from "./graphql.generated";
import { useCompanyDateFormatter } from "components/companies";
import { Box, S, color } from "ve";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import { useNewFixedAssetFromTransactionForm } from "./hooks/useNewFixedAssetFromTransactionForm";

type Props = {
  transaction: TransactionForFixedAssetsFragment;
  open?: boolean;
  onOpenChange?(open: boolean): void;
  onComplete?(): void;
};

export const NewAssetFromTransactionDrawer = ({
  transaction,
  open,
  onOpenChange,
  onComplete,
}: Props) => {
  const form = useNewFixedAssetFromTransactionForm(transaction, onComplete);
  const dateFormatter = useCompanyDateFormatter({
    month: "numeric",
    day: "numeric",
    year: "numeric",
  });

  if (!transaction) return null;

  return (
    <Drawer
      open={open}
      onOpenChange={onOpenChange}
      css={{
        padding: 0,
        width: 850,
        zIndex: isPosthogFeatureFlagEnabled(FeatureFlag.Z) ? zIndex("drawer") : "auto",
      }}
    >
      <div>
        <Box
          css={{
            display: "flex",
            justifyContent: "space-between",
            padding: `${S["1h"]} ${S["2"]} ${S["1h"]} ${S["3"]}`,
            alignItems: "center",
            borderBottom: `1px solid ${color.mauve600}`,
            flex: 1,
          }}
        >
          <Box css={{ display: "inline-flex", gap: S["2"] }}>
            <Text
              css={{
                fontSize: "$bodyM",
                lineHeight: "$headingL",
                letterSpacing: "$bodyXS",
                fontWeight: "$bold",
              }}
            >
              Fixed asset
            </Text>
          </Box>
          <IconButton onClick={() => onOpenChange?.(false)} autoFocus>
            <Close color="currentColor" size={12} />
          </IconButton>
        </Box>
        <Stack css={{ padding: "$3" }} gap="3">
          <DescriptionList
            css={{
              zIndex: isPosthogFeatureFlagEnabled(FeatureFlag.Z) ? zIndex("drawerMenu") : "auto",
            }}
            items={[
              ["Purchase date", dateFormatter.format(parseDate(transaction.date))],
              [
                "Total cost",
                formatMoney({
                  currency: "USD",
                  amount: transaction.amount,
                }),
              ],
              [
                "Description",
                <Controller
                  control={form.control}
                  key="description"
                  name="description"
                  render={() => {
                    return <Input {...form.register("description")} size="mini" />;
                  }}
                />,
              ],
              [
                "Asset type",
                <Controller
                  control={form.control}
                  key="type"
                  name="type"
                  render={({ field }) => {
                    return (
                      <Select
                        menuCss={{
                          zIndex: isPosthogFeatureFlagEnabled(FeatureFlag.Z)
                            ? zIndex("drawerMenu")
                            : "auto",
                        }}
                        css={{
                          width: "100%",
                        }}
                        options={CATEGORY_OPTIONS}
                        onSelectionChange={(value) => {
                          field.onChange({
                            target: { value },
                          });
                        }}
                        value={field.value}
                        size="mini"
                      />
                    );
                  }}
                />,
              ],
              [
                "In-service date",
                <Controller
                  control={form.control}
                  key="inServiceAt"
                  name="inServiceAt"
                  render={({ field }) => {
                    return (
                      <DateInput
                        size="small"
                        placeholder="Pick a date"
                        value={field.value ? parseDate(field.value) : undefined}
                        onChange={(value) => {
                          field.onChange({
                            target: {
                              value: value && toCalendarDate(value).toString(),
                              name: field.name,
                            },
                          });
                        }}
                      />
                    );
                  }}
                />,
              ],
              [
                "Useful life",
                <Controller
                  control={form.control}
                  key="usefulLifeYears"
                  name="usefulLifeYears"
                  render={({ field }) => {
                    return (
                      <Select
                        menuCss={{
                          zIndex: isPosthogFeatureFlagEnabled(FeatureFlag.Z)
                            ? zIndex("drawerMenu")
                            : "auto",
                        }}
                        options={USEFUL_LIFE_OPTIONS}
                        value={field.value?.toString()}
                        size="small"
                        onSelectionChange={(duration) => {
                          field.onChange(duration);
                        }}
                      />
                    );
                  }}
                />,
              ],
              ["Method", FixedAssetDepreciationMethod.StraightLine],
              ["First-month convention", "Half-month"],
            ]}
          />
          <Stack direction="horizontal" css={{ justifyContent: "flex-end" }}>
            <Button size="compact" variant="minimal" onClick={() => onOpenChange?.(false)}>
              Cancel
            </Button>
            <Button
              size="compact"
              loading={form.formState.isSubmitting}
              onClick={async () => {
                await form.onSubmitAsset();
                onOpenChange?.(false);
              }}
              disabled={!form.formState.isValid}
            >
              Submit
            </Button>
          </Stack>
        </Stack>
      </div>
    </Drawer>
  );
};
