import React, { useCallback, useState } from "react";
import { Button, IconButton, Collapse, S, color, Stack, Text } from "ve";
import { useSetState } from "react-use";
import { Tag, useToasts } from "@puzzle/ui";
import { Add, ChevronDown, Edit, RemoveCircle } from "@puzzle/icons";
import { formatMoney, parseDate } from "@puzzle/utils";
import Link from "components/common/Link";
import { InvoiceStatus } from "graphql/types";
import { Route } from "lib/routes";
import { useCompanyDateFormatter } from "components/companies";
import {
  FullTransactionFragment,
  useRemoveCustomerBalanceMatchMutation,
  useRemoveCustomerBalanceTransactionsMutation,
} from "../graphql.generated";
import { subSection, numberBadge, invoiceRow, removeButton } from "./accountingSection.css";
import { InvoicePaymentWarningModal, RemoveInput } from "./InvoicePaymentWarningModal";

export const InvoicePaymentSubsection = ({
  transaction,
  invoiceSubsectionCollapsed,
  setInvoiceSubsectionCollapsed,
  companyId,
  onComplete,
}: {
  transaction: FullTransactionFragment;
  invoiceSubsectionCollapsed: boolean;
  setInvoiceSubsectionCollapsed: (value: boolean) => void;
  companyId: string;
  onComplete?: () => void;
}) => {
  const [removeCustomerBalanceMatch, { loading }] = useRemoveCustomerBalanceMatchMutation();
  const [removeCustomerBalanceTransactions, { loading: removeLoading }] =
    useRemoveCustomerBalanceTransactionsMutation();
  const { toast } = useToasts();
  const paymentUrl = `${Route.newPayment}?selectedTransactionId=${transaction.id}&isFromTransaction=true`;
  const [removeOptions, setRemoveOptions] = useSetState<RemoveInput>({
    id: undefined,
    groupId: undefined,
  });
  const [showWarningModal, setShowWarningModal] = useState(false);

  const getTagCss = (status: InvoiceStatus) => {
    if (status === InvoiceStatus.Paid) {
      return { backgroundColor: color.forest100, color: color.forest600 };
    }
    if (status === InvoiceStatus.Posted) {
      return { backgroundColor: color.yellow100, color: color.yellow800 };
    }
    return undefined;
  };
  const dateFormatter = useCompanyDateFormatter({
    dateStyle: "short",
  });

  const removeMatch = useCallback(
    (id?: string, groupId?: string) => {
      const input = { companyId, id: id ?? undefined, groupId: groupId ?? undefined };
      if (transaction.payment && (groupId || transaction.payment?.matches?.length <= 1)) {
        return removeCustomerBalanceTransactions({
          variables: {
            input: {
              companyId,
              customerBalanceTransactionIds: [transaction.payment.id],
            },
          },
          onCompleted: () => {
            onComplete?.();
            toast({
              message: "Successfully removed.",
              status: "success",
            });
          },
          onError: () => {
            toast({
              message:
                "Something went wrong, and our team has been notified. We apologize for the inconvenience. Please try again in a few minutes.",
              status: "warning",
            });
          },
        });
      }
      return removeCustomerBalanceMatch({
        variables: {
          input: {
            companyId,
            id: id ?? undefined,
          },
        },
        onCompleted: () => {
          onComplete?.();
          toast({
            message: "Successfully removed.",
            status: "success",
          });
        },
        onError: () => {
          toast({
            message:
              "Something went wrong, and our team has been notified. We apologize for the inconvenience. Please try again in a few minutes.",
            status: "warning",
          });
        },
      });
    },
    [
      companyId,
      removeCustomerBalanceMatch,
      onComplete,
      removeCustomerBalanceTransactions,
      transaction.payment,
      toast,
    ]
  );

  return (
    <div className={subSection}>
      <Stack direction="horizontal" gap={S.$1} css={{ alignItems: "center" }}>
        <Text variant="bodyS" color="white" weight="heavy">
          Invoice payment
        </Text>

        {!transaction.payment ? (
          <div className={numberBadge}>
            <Link href={paymentUrl}>
              <Add color={color.white80} size={12} />
            </Link>
          </div>
        ) : (
          <div className={numberBadge}>
            <Link href={paymentUrl}>
              <Edit color={color.white80} size={12} />
            </Link>
          </div>
        )}
        {transaction.payment && (
          <IconButton
            size="medium"
            onClick={() => {
              setInvoiceSubsectionCollapsed(!invoiceSubsectionCollapsed);
            }}
          >
            <ChevronDown
              width={10}
              height={10}
              rotate={invoiceSubsectionCollapsed ? 0 : -90}
              color={color.white70}
            />
          </IconButton>
        )}
        {transaction?.payment?.matches && transaction.payment.matches.length > 1 && (
          <div
            className={removeButton}
            // all matches have the same group id
            onClick={() => {
              setRemoveOptions({ groupId: transaction.payment?.matches?.[0].groupId });
              setShowWarningModal(true);
            }}
          >
            <RemoveCircle color={color.white80} size={12} />
            <Text>Remove all</Text>
          </div>
        )}
      </Stack>
      {transaction.payment && (
        <Collapse open={invoiceSubsectionCollapsed} style={{ width: "100%" }}>
          {transaction.payment.matches.length > 0 ? (
            <>
              {transaction.payment.matches.map((match) =>
                match.invoice ? (
                  <Link
                    href={`${Route.invoices}/${match.invoice.id}`}
                    target="_blank"
                    key={match.invoice.id}
                  >
                    <div className={invoiceRow} key={match.id}>
                      <Text variant="bodyS">
                        {dateFormatter.format(parseDate(match.invoice.issueDate))}
                      </Text>
                      <Text variant="bodyS">{match.invoice.customer?.name} </Text>
                      <Tag css={getTagCss(match.invoice.status)}>{match.invoice.status}</Tag>
                      <Stack
                        direction="horizontal"
                        style={{
                          alignItems: "center",
                          gap: S["1"],
                          marginLeft: "auto",
                        }}
                      >
                        <Text variant="bodyS">
                          {formatMoney({ amount: match.invoice.amount.amount, currency: "USD" })}
                        </Text>
                        <IconButton
                          size="medium"
                          onClick={(e) => {
                            e.preventDefault();
                            setRemoveOptions({ id: match.id });
                            setShowWarningModal(true);
                          }}
                        >
                          <RemoveCircle />
                        </IconButton>
                      </Stack>
                    </div>
                  </Link>
                ) : null
              )}
            </>
          ) : (
            <>
              <div className={invoiceRow}>
                <Text variant="bodyS">
                  {dateFormatter.format(parseDate(transaction.payment.date))}
                </Text>
                <Text variant="bodyS">{transaction.payment.customer?.name} </Text>
                <Stack
                  direction="horizontal"
                  style={{
                    alignItems: "center",
                    gap: S["1"],
                    marginLeft: "auto",
                  }}
                >
                  <Text variant="bodyS">
                    {formatMoney({ amount: transaction.payment.amount.amount, currency: "USD" })}
                  </Text>
                  <IconButton
                    size="medium"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowWarningModal(true);
                    }}
                  >
                    <RemoveCircle />
                  </IconButton>
                </Stack>
              </div>
            </>
          )}
        </Collapse>
      )}
      <InvoicePaymentWarningModal
        open={showWarningModal}
        onOpenChange={setShowWarningModal}
        handleRemove={removeMatch}
        removeOptions={removeOptions}
      />
    </div>
  );
};
