import React from "react";
import { IconProps } from "./Icon";

export default function RemoveCircle({ viewBox = "0 0 16 16", size = 16 }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={viewBox}
      fill="none"
    >
      <path
        d="M10.8286 8.00028H5.17174M14.1668 7.99998C14.1668 11.4057 11.4059 14.1666 8.00016 14.1666C4.59441 14.1666 1.8335 11.4057 1.8335 7.99998C1.8335 4.59422 4.59441 1.83331 8.00016 1.83331C11.4059 1.83331 14.1668 4.59422 14.1668 7.99998Z"
        stroke="white"
        strokeOpacity="0.7"
        strokeLinecap="round"
      />
    </svg>
  );
}
