import React, { useCallback, useMemo, useState } from "react";
import Big from "big.js";
import { truncate, uniqBy } from "lodash";

import { Text, Checkbox, Stack, Tag, Dialog, Button, Menu, Input, ScrollArea } from "@puzzle/ui";
import { formatMoney, parseDate, sumAmounts, pluralize, zIndex } from "@puzzle/utils";

import { BasicTransactionFragment } from "components/dashboard/Transactions/graphql.generated";
import { useActiveCompany, useCompanyDateFormatter } from "components/companies";
import Loader from "components/common/Loader";
import { useChartOfAccounts } from "components/dashboard/Accounting/shared/useChartOfAccounts";
import Link from "components/common/Link";
import {
  PagedBillForMatchingFragment,
  useGetBillsForMatchingQuery,
} from "components/dashboard/Accounting/Bills/graphql.generated";
import NoData from "components/dashboard/NoData";
import { useLinkBillsToTransaction } from "components/dashboard/Transactions/hooks/useSingleTransaction";
import useSearchOptions from "components/dashboard/Transactions/hooks/useSearchOptions";
import { CategoryFragment } from "graphql/types";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import { Box, S, color } from "ve";
import { billStyle } from "./MatchToBills.css";

const padding = { padding: `0 ${S["3"]}` };
const MAX_TAGS = 3;
const TRUNCATE_LENGTH = MAX_TAGS * 6;
const MAX_TOTAL_TAG_LENGTH = TRUNCATE_LENGTH * 3;

const MatchToBills = ({ transaction }: { transaction: BasicTransactionFragment }) => {
  const { company } = useActiveCompany<true>();
  const { categoriesByPermaKey } = useChartOfAccounts();
  const { categories } = useSearchOptions();

  const isInexactMatchEnabled = isPosthogFeatureFlagEnabled(FeatureFlag.InexactBillMatch);

  const [unmatchedSplitDescription, setUnmatchedSplitDescription] = useState("");
  const [unmatchedCoaKey, setUnmatchedCoaKey] = useState("no_category");

  const linkBillsToTransaction = useLinkBillsToTransaction(transaction);
  const [checkedBills, setCheckedBills] = useState<Map<string, PagedBillForMatchingFragment>>(
    new Map()
  );

  const updateChecked = useCallback(
    (isChecked: boolean, bill: PagedBillForMatchingFragment) => {
      isChecked ? checkedBills.set(bill.id, bill) : checkedBills.delete(bill.id);
      setCheckedBills(new Map(checkedBills));
    },
    [checkedBills]
  );

  const totalAmountSelected = useMemo(
    () => sumAmounts(Array.from(checkedBills.values(), ({ amount }) => amount.amount)),
    [checkedBills]
  );

  const allHaveSameVendor = useMemo(() => {
    const arr = Array.from(checkedBills.values());
    return arr.every(({ vendor }) => vendor!.id === arr[0].vendor!.id);
  }, [checkedBills]);

  const diff = Big(-transaction.amount).minus(Big(totalAmountSelected).abs());
  const isDiff = !diff.eq(Big(0));

  const { data, loading } = useGetBillsForMatchingQuery({
    skip: !company?.id,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
    variables: {
      companyId: company.id,
      page: { count: 100 },
      filterBy: {
        isVoided: false,
        isLinked: false,
        isSubmitted: true,
        matchContext: {
          date: transaction.date,
          amount: transaction.amount,
        },
      },
    },
  });
  const billsData = useMemo(() => data?.company?.bills.items ?? [], [data?.company?.bills.items]);

  const dateFormatter = useCompanyDateFormatter({
    month: "2-digit",
    day: "2-digit",
    year: "2-digit",
  });

  return (
    <div>
      <Box css={padding}>
        <Text css={{ display: "flex", padding: "$2 0 $3 0" }}>
          Select bill(s) paid by this transaction:
        </Text>
      </Box>
      <ScrollArea
        css={{
          ...padding,
          height: 220,
          marginBottom: "$1",
        }}
      >
        {loading && <Loader size={32} />}

        {billsData.length > 0 && (
          <Menu.Label css={{ background: "$gradients$labelDark", paddingLeft: "$1" }}>
            {checkedBills.size > 0
              ? `${pluralize(checkedBills.size, "bill")} selected`
              : "Select a bill"}
          </Menu.Label>
        )}

        <ScrollArea.Viewport>
          <Box css={{ marginBottom: S["3"] }}>
            {!loading && billsData.length === 0 && <NoData title="No unpaid bills" />}

            {billsData.map((bill) => {
              const tags = uniqBy(
                bill.lines.map(({ coaKey }) => categoriesByPermaKey[coaKey!]),
                ({ coaKey }) => coaKey
              );

              const numChars = tags.reduce((acc, cur) => cur.displayName.length + acc, 0);

              return (
                <Box
                  key={bill.id}
                  onClick={() => updateChecked(!checkedBills.has(bill.id), bill)}
                  className={billStyle}
                >
                  <div>
                    <Checkbox
                      checked={checkedBills.has(bill.id)}
                      onCheckedChange={(isChecked) => {
                        updateChecked(!isChecked, bill);
                      }}
                    />
                  </div>
                  <div>
                    <Text color="gray400">{dateFormatter.format(parseDate(bill.issueDate))}</Text>
                  </div>
                  <div>
                    <Stack css={{ gap: "$0" }}>
                      <Text>{bill.vendor!.name}</Text>
                      <Box
                        css={{
                          display: "inline-flex",
                          maxWidth: 400,
                          alignItems: "center",
                        }}
                      >
                        {tags.slice(0, MAX_TAGS).map(({ id, displayName }) => (
                          <Box key={id}>
                            <Tag variant="pill" css={{ margin: "$0h $0h 0 0" }}>
                              {numChars > MAX_TOTAL_TAG_LENGTH
                                ? truncate(displayName, { length: TRUNCATE_LENGTH })
                                : displayName}
                            </Tag>
                          </Box>
                        ))}
                        {tags.length > MAX_TAGS && (
                          <Text size="bodyXS">+ {tags.length - MAX_TAGS} more</Text>
                        )}
                      </Box>
                    </Stack>
                  </div>
                  <Box css={{ marginLeft: "auto" }}>
                    <Text color="gray400">
                      {formatMoney({ currency: "USD", amount: Big(bill.amount.amount).abs() })}
                    </Text>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </ScrollArea.Viewport>
        <ScrollArea.Scrollbar orientation="vertical">
          <ScrollArea.Thumb variant="shadowed" />
        </ScrollArea.Scrollbar>
      </ScrollArea>
      <Box css={padding}>
        <Text
          size="bodyS"
          color="gray300"
          css={{ display: "block", padding: "$2 0 $3 0", fontSize: "smaller" }}
        >
          Can&apos;t find your unpaid bills above? Go to{" "}
          <Link href="/accounting/bills" underline css={{ color: "$gray200" }}>
            Accounting
          </Link>{" "}
          to create and manage bills.
        </Text>
      </Box>
      <Box
        css={{
          margin: `0 ${S["3"]}`,
          padding: S["1"],
          backgroundColor: "#44445C",
          borderRadius: S["2"],
          fontSize: "smaller",
        }}
      >
        Transaction Amount (Money Out):{" "}
        {formatMoney({ currency: "USD", amount: Big(transaction.amount).abs() })}
      </Box>
      {isDiff && isInexactMatchEnabled && (
        <>
          {isDiff}
          <Box
            css={{
              paddingLeft: S["4"],
              marginTop: S["2"],
              color: color.gray300,
              fontSize: "smaller",
            }}
          >
            Categorize the remaining amount of the transaction
          </Box>
          <Box
            css={{
              ...padding,
              marginTop: S["1"],
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Menu
              css={{
                zIndex: isPosthogFeatureFlagEnabled(FeatureFlag.Z) ? zIndex("modalMenu") : 999,
              }}
              trigger={
                <div style={{ width: "170px" }}>
                  <Tag
                    role="button"
                    variant="pill"
                    css={{ display: "inline-block", padding: "10px", maxWidth: 170 }}
                  >
                    {categoriesByPermaKey[unmatchedCoaKey]?.displayName ?? "No Category"}
                  </Tag>
                </div>
              }
            >
              <ScrollArea css={{ height: "350px" }}>
                <ScrollArea.Viewport>
                  <Menu.RadioGroup
                    value={unmatchedCoaKey}
                    onValueChange={(newValue) => setUnmatchedCoaKey(newValue)}
                  >
                    {categories.map((cat: CategoryFragment) => {
                      return (
                        <Menu.RadioItem
                          key={cat.coaKey!}
                          value={cat.coaKey!}
                          onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            if (cat.coaKey) {
                              setUnmatchedCoaKey(cat.coaKey!);
                            }
                          }}
                        >
                          {cat.displayName}
                        </Menu.RadioItem>
                      );
                    })}
                  </Menu.RadioGroup>
                </ScrollArea.Viewport>
                <ScrollArea.Scrollbar orientation="vertical">
                  <ScrollArea.Thumb variant="shadowed" />
                </ScrollArea.Scrollbar>
              </ScrollArea>
            </Menu>
            <Input
              css={{ display: "inline-block", width: 320 }}
              value={unmatchedSplitDescription}
              placeholder="Split Description"
              size="mini"
              onChange={(e) => setUnmatchedSplitDescription(e.target.value)}
            />
            <Box css={{ padding: S["1"], width: "100px", textAlign: "right" }}>
              {formatMoney({ currency: "USD", amount: diff })}
            </Box>
          </Box>
        </>
      )}
      <Dialog.Footer align="right">
        <Dialog.Actions>
          <Dialog.Close asChild>
            <Button variant="minimal">Cancel</Button>
          </Dialog.Close>
          <Button
            variant="primary"
            disabled={
              !allHaveSameVendor || checkedBills.size === 0 || (!isInexactMatchEnabled && isDiff)
            }
            onClick={() => {
              linkBillsToTransaction(
                Array.from(checkedBills.values()),
                isDiff ? unmatchedCoaKey : undefined,
                isDiff ? unmatchedSplitDescription : undefined
              );
            }}
          >
            Match
          </Button>
        </Dialog.Actions>
      </Dialog.Footer>
    </div>
  );
};

export default MatchToBills;
