import React from "react";
import Icon, { IconProps } from "./Icon";

export default function File({
  viewBox = "0 0 16 16",
  size = 16,
  color = "currentColor",
  ...iconProps
}: IconProps) {
  return (
    <Icon size={size} viewBox={viewBox} {...iconProps}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox={viewBox}
        fill="none"
      >
        <path
          d="M6.49996 9.16634H9.49996M6.66663 6.09969L7.46663 6.89969L9.33329 5.03303M3.16663 3.83301V13.2684C3.16663 13.5221 3.43883 13.6828 3.66099 13.5603L5.28165 12.6661C5.47086 12.5617 5.69893 12.5555 5.89354 12.6494L7.71013 13.5264C7.89324 13.6148 8.10668 13.6148 8.28979 13.5264L10.1064 12.6494C10.301 12.5555 10.5291 12.5617 10.7183 12.6661L12.3389 13.5603C12.5611 13.6828 12.8333 13.5221 12.8333 13.2684V3.83301C12.8333 2.72844 11.9379 1.83301 10.8333 1.83301H5.16663C4.06206 1.83301 3.16663 2.72844 3.16663 3.83301Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
}
