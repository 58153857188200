import React from "react";

import { Route } from "lib/routes";

import { Text } from "ve";

import Link from "components/common/Link";

import { LedgerView } from "graphql/types";
import { TransactionLedgerEntryFragment } from "../graphql.generated";

import { cardRoot } from "./journalsWithStatus.css";

export const JournalsWithStatus = ({
  view,
  title,
  journals,
}: {
  view: LedgerView;
  title: string;
  journals?: TransactionLedgerEntryFragment[];
}) => {
  if (!journals || journals.length <= 0) {
    return null;
  }

  return (
    <>
      {journals.map((entry) => {
        if (entry.journal.view === view) {
          const href = `${Route.generalLedger}?accountId=${entry.accountId}&journalId=${entry.journal.id}`;
          return (
            <Link href={href} key={href}>
              <div className={cardRoot}>
                <Text variant="bodyXS" color="gray300" weight="bold">
                  {title}
                </Text>
                <Text variant="bodyXS" color="white" weight="bold">
                  {entry.journal.shortId}
                </Text>
              </div>
            </Link>
          );
        }
      })}
    </>
  );
};
