import React, { useMemo, useState } from "react";

import { parseDate, formatMoney } from "@puzzle/utils";
import { Unlink2 } from "@puzzle/icons";
import { Route } from "lib/routes";

import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { useCompanyDateFormatter } from "components/companies";
import Link from "components/common/Link";

import { Text, color, IconButton } from "ve";

import { FullTransactionFragment } from "../graphql.generated";

import { UnlinkTransactionModal } from "components/transactions/UnlinkTransactionModal";

import {
  container,
  cardRoot,
  categoryBadge,
  dateTextStyle,
  vendorTextStyle,
  amountTextStyle,
  categoryTextStyle,
} from "./linkedTransactions.css";

const LinkedTransactionCard = ({
  transaction,
  onUnlink,
}: {
  transaction: FullTransactionFragment;
  onUnlink: () => void;
}) => {
  const dateFormatter = useCompanyDateFormatter({ dateStyle: "short" });

  if (!transaction) {
    return;
  }

  const { id } = transaction;
  const date = dateFormatter.format(parseDate(transaction.date));
  const vendor = transaction.detail?.vendor?.name;
  const amount = formatMoney({ amount: transaction.detail?.amount });
  const category = transaction.detail?.category?.displayName;

  return (
    <div className={cardRoot}>
      <Link href={`${Route.transactions}/${id}`} css={{ width: "100%", display: "contents" }}>
        {date && (
          <Text variant="bodyXS" weight="bold" color="white" className={dateTextStyle}>
            {date}
          </Text>
        )}
        {vendor && (
          <Text variant="bodyXS" weight="bold" color="white70" className={vendorTextStyle}>
            {vendor}
          </Text>
        )}
        {amount && (
          <Text variant="bodyXS" weight="bold" color="white" className={amountTextStyle}>
            {amount}
          </Text>
        )}
        {category && (
          <div className={categoryBadge}>
            <Text variant="bodyXS" weight="bold" color="white" className={categoryTextStyle}>
              {category}
            </Text>
          </div>
        )}
      </Link>
      <IconButton css={{ marginLeft: "auto" }} onClick={onUnlink}>
        <Unlink2 size={16} color={color.white50} />
      </IconButton>
    </div>
  );
};

export const LinkedTransactions = ({
  transaction,
  refetch,
}: {
  transaction: FullTransactionFragment;
  refetch?: () => void;
}) => {
  const { company } = useActiveCompany<true>();
  const [bundleId, setBundleId] = useState<string | null>(null);
  const { id } = transaction;
  const linked = useMemo(() => Boolean(transaction && transaction.links.length), [transaction]);

  if (!linked) {
    return;
  }

  return (
    <div className={container}>
      {transaction.links.map((link: any) => (
        <>
          {link.receivedTransaction && id !== link.receivedTransaction?.id && (
            <LinkedTransactionCard
              key={link.id}
              transaction={link.receivedTransaction}
              onUnlink={() => setBundleId(link.bundleId)}
            />
          )}
          {link.sentTransaction && id !== link.sentTransaction?.id && (
            <LinkedTransactionCard
              key={link.id}
              transaction={link.sentTransaction}
              onUnlink={() => {
                setBundleId(link.bundleId);
              }}
            />
          )}
        </>
      ))}
      {!!bundleId && (
        <UnlinkTransactionModal
          transactionId={transaction.id}
          companyId={company.id}
          bundleId={bundleId}
          refetch={refetch}
          open={!!bundleId}
          onOpenChange={(open) => {
            if (!open) {
              setBundleId(null);
            }
          }}
        />
      )}
    </div>
  );
};
