import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  ButtonGroup,
  Dialog,
  DateInput,
  Text,
  styled,
  useToasts,
  Stack,
  OptionCard,
  Tag,
  CheckboxField,
  Tooltip,
  Help,
  ScrollArea,
} from "@puzzle/ui";
import { TransactionSortOrder, useSetEpochsForAccountsMutation } from "graphql/types";
import { CalendarDate, parseDate, toCalendarDate, today } from "@internationalized/date";
import { useActiveCompany, useCompanyDateFormatter } from "components/companies";
import useTransactions from "components/dashboard/Transactions/hooks/useTransactions";
import { reportError } from "lib/errors";
import useAppRouter from "lib/useAppRouter";
import { CalendarDateString, formatAccountName } from "@puzzle/utils";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";

type SelectionType = "custom" | "last_transaction_date" | "none";
type EpochInput = {
  selectionType: SelectionType;
  cutoffBefore: CalendarDate | null;
  accountId: string;
  shouldUpdateHistory: boolean;
};

const Option = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "$1",
  marginTop: "-2px",
});

type AccountSummary = {
  id: string;
  name: string;
  mask?: string | null;
  financialInstitution: {
    name: string;
  };
  epoch?: {
    cutoffBefore?: CalendarDateString | null;
    cutoffAtOrAfter?: CalendarDateString | null;
  } | null;
};

type SelectAccountDateModalProps = React.ComponentPropsWithoutRef<typeof Dialog> & {
  companyId: string;
  accounts: AccountSummary[];
  isReconnect?: boolean;
  accountsLoading?: boolean;
  connectionId?: string;
  onDatesSet?: () => void;
  successToastMessageOverride?: string;
};

type SelectStartForSingleAccountProps = {
  account: AccountSummary;
  handleSetEpoch: (epoch: EpochInput) => void;
  currentEpoch?: EpochInput;
  isReconnect?: boolean;
};

const SelectStartForSingleAccount = ({
  account,
  handleSetEpoch,
  currentEpoch,
  isReconnect,
}: SelectStartForSingleAccountProps) => {
  const { timeZone, company } = useActiveCompany<true>();
  const isOptionRequired = isPosthogFeatureFlagEnabled(
    FeatureFlag.ReqIngestOprionOnReconnectIntegration
  );
  const minDate = useMemo(
    () => (company.startIngestionDate ? parseDate(company.startIngestionDate) : undefined),
    [company.startIngestionDate]
  );

  const [customDate, setCustomDate] = useState(
    minDate ??
      (account.epoch?.cutoffBefore ? parseDate(account.epoch.cutoffBefore) : today(timeZone))
  );
  const [shouldUpdateHistory, setShouldUpdateHistory] = useState(false);

  const dateFormatter = useCompanyDateFormatter({
    month: "short",
    day: "numeric",
    year: "numeric",
  });
  const companyId = company.id;
  const { loading: lastTransactionsLoading, transactions: lastTransactions } = useTransactions({
    companyId: companyId,
    sortBy: TransactionSortOrder.DateDesc,
    filterBy: {
      accountIds: [account.id],
    },
    page: { count: 1, after: null },
  });

  const lastTransactionDate = lastTransactions?.length
    ? parseDate(lastTransactions[0].date)
    : undefined;

  const loadingCheck = lastTransactionsLoading;

  const hasUnrestrictedOption =
    (!account.epoch?.cutoffBefore && !isOptionRequired) ||
    (!account.epoch?.cutoffBefore && isOptionRequired && !isReconnect);

  const initialState: EpochInput = useMemo(() => {
    if (lastTransactionDate) {
      return {
        accountId: account.id,
        cutoffBefore: lastTransactionDate,
        selectionType: "last_transaction_date",
        shouldUpdateHistory,
      };
    } else if (hasUnrestrictedOption) {
      return {
        accountId: account.id,
        cutoffBefore: null,
        selectionType: "none",
        shouldUpdateHistory,
      };
    } else {
      return {
        accountId: account.id,
        cutoffBefore: customDate,
        selectionType: "custom",
        shouldUpdateHistory,
      };
    }
  }, [account, customDate, lastTransactionDate, shouldUpdateHistory, hasUnrestrictedOption]);

  useEffect(() => {
    if (!loadingCheck && !currentEpoch) {
      handleSetEpoch(initialState);
    }
  }, [loadingCheck, initialState, handleSetEpoch, currentEpoch]);

  if (!currentEpoch) {
    return null;
  }

  return (
    <Stack direction="vertical" key={account.id} gap="1">
      <Text variant="bodyM" color="$gray200" weight="heavy">
        {formatAccountName(account)}
      </Text>

      {lastTransactionDate ? (
        <OptionCard
          onClick={() =>
            handleSetEpoch({
              selectionType: "last_transaction_date",
              cutoffBefore: lastTransactionDate,
              accountId: account.id,
              shouldUpdateHistory,
            })
          }
          checked={currentEpoch.selectionType === "last_transaction_date"}
          css={{ alignItems: "flex-start" }}
        >
          <Option>
            <Stack
              direction="horizontal"
              css={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <Text color="$gray100">Last known transaction from this account is on:</Text>
              <Tag css={{ height: 16 }} variant="filledPill">
                Recommended
              </Tag>
            </Stack>
            <Text variant="body">
              {dateFormatter.format(lastTransactionDate)} Ingest data from this date
            </Text>
          </Option>
        </OptionCard>
      ) : null}

      {hasUnrestrictedOption && (
        <OptionCard
          onClick={() =>
            handleSetEpoch({
              selectionType: "none",
              cutoffBefore: null,
              accountId: account.id,
              shouldUpdateHistory: false,
            })
          }
          checked={currentEpoch.selectionType === "none"}
          css={{ alignItems: "flex-start" }}
        >
          <Option>
            <Stack direction="horizontal" css={{ alignItems: "center" }}>
              <Text color="$gray100">Ingest data for all dates for this account</Text>
              {!lastTransactionDate && (
                <Tag css={{ height: 16 }} variant="filledPill">
                  Recommended
                </Tag>
              )}
            </Stack>
          </Option>
        </OptionCard>
      )}

      <OptionCard
        onClick={() => {
          if (currentEpoch.selectionType !== "custom") {
            handleSetEpoch({
              selectionType: "custom",
              cutoffBefore: customDate,
              accountId: account.id,
              shouldUpdateHistory,
            });
          }
        }}
        checked={currentEpoch.selectionType === "custom"}
        css={{ alignItems: "flex-start" }}
      >
        <Option>
          <Text color="$gray100">Restricted: Do not ingest any data prior to</Text>
          <DateInput
            size="small"
            css={{ height: 36, width: 152 }}
            value={customDate}
            minDate={
              account.epoch?.cutoffBefore
                ? parseDate(account.epoch.cutoffBefore)
                : minDate ?? undefined
            }
            onChange={(value) => {
              if (value) {
                const dateValue = toCalendarDate(value);
                setCustomDate(dateValue);
                handleSetEpoch({
                  selectionType: "custom",
                  cutoffBefore: dateValue,
                  accountId: account.id,
                  shouldUpdateHistory,
                });
              }
            }}
            footer={
              minDate &&
              `You previously elected to start ingesting data across all external accounts on ${company.startIngestionDate}. You cannot select a date for this account prior to that cutoff date.`
            }
          />
        </Option>
      </OptionCard>

      <Option>
        <CheckboxField
          label={
            <Text>
              Update historical data{" "}
              <Tooltip
                content={
                  "By opting to update historical data, any data associated with this account within the restricted range will be removed. This includes both ingested data and manually created data."
                }
              >
                <span>
                  <Help />
                </span>
              </Tooltip>
            </Text>
          }
          checked={shouldUpdateHistory}
          onCheckedChange={(checked) => setShouldUpdateHistory(checked)}
          disabled={currentEpoch.selectionType === "none"}
        />
      </Option>
    </Stack>
  );
};

export const SelectStartDateModal = ({
  open,
  accounts,
  companyId,
  isReconnect,
  onOpenChange,
  accountsLoading,
  connectionId,
  onDatesSet,
  successToastMessageOverride,
  ...props
}: SelectAccountDateModalProps) => {
  const [setEpochsForAccountsMutation, { loading }] = useSetEpochsForAccountsMutation();
  const { goToTransactions } = useAppRouter();
  const { toast } = useToasts();
  const isOptionRequired = isPosthogFeatureFlagEnabled(
    FeatureFlag.ReqIngestOprionOnReconnectIntegration
  );

  const [accountEpochs, setAccountEpochs] = useState<EpochInput[]>([]);

  const handleSetAccountEpochs = useCallback(
    (epochInput: EpochInput) => {
      if (!accountEpochs.find((a) => a.accountId === epochInput.accountId)) {
        return setAccountEpochs([...accountEpochs, epochInput]);
      }
      const newSet = accountEpochs.map((epoch) => {
        if (epoch.accountId === epochInput.accountId) {
          return { ...epochInput };
        }
        return epoch;
      });
      setAccountEpochs(newSet);
    },
    [accountEpochs, setAccountEpochs]
  );

  const hasAllAccountsSetCuttoffBefore = useMemo(() => {
    return accountEpochs.every((accountEpoch) => !!accountEpoch.cutoffBefore);
  }, [accountEpochs]);

  const onConfirm = useCallback(async () => {
    if (isOptionRequired && isReconnect && !hasAllAccountsSetCuttoffBefore) {
      return;
    }
    return setEpochsForAccountsMutation({
      variables: {
        input: {
          connectionId: connectionId ?? "",
          epochs: accountEpochs.map((epoch) => {
            return {
              accountId: epoch.accountId,
              cutoffBefore: epoch.cutoffBefore?.toString(),
              shouldUpdateHistory: epoch.shouldUpdateHistory,
            };
          }),
        },
      },
      onCompleted() {
        toast({
          message:
            successToastMessageOverride ??
            `Restricted ranges have been set! We will start syncing your accounts now. Data ingestion may take a couple minutes.`,
          onAction: () => {
            goToTransactions();
          },
          actionText: "You will need to refresh to see the results.",
          status: "success",
        });
        onOpenChange?.(false);
        setAccountEpochs([]);
        onDatesSet?.();
      },
      onError(error) {
        reportError(error);
      },
    });
  }, [isOptionRequired, isReconnect, hasAllAccountsSetCuttoffBefore, setEpochsForAccountsMutation, connectionId, accountEpochs, toast, successToastMessageOverride, onOpenChange, onDatesSet, goToTransactions]);

  return (
    <Dialog size="medium" {...props} open={open}>
      <Dialog.Title
        onClose={() => onOpenChange?.(false)}
        showClose={!(isOptionRequired && isReconnect)}
        css={{ padding: isOptionRequired && isReconnect ? "$2h $1h $2 $3" : undefined }}
      >
        Create a restricted range for data ingestion {!isReconnect && "(optional)"}
      </Dialog.Title>
      <Dialog.Body css={{ textVariant: "$bodyS" }}>
        <Stack direction="vertical" gap="2" css={{ marginBottom: "$2" }}>
          <Text>You have the option to set restricted ranges for the following account(s).</Text>
          <Text>
            For the associated dates of a restricted range, Puzzle will not ingest new data from any
            external source for the associated account.
          </Text>
        </Stack>
        <ScrollArea css={{ height: 400 }}>
          <ScrollArea.Viewport css={{ padding: "$2" }}>
            <Stack direction="vertical" gap="4">
              {accounts.map((account) => (
                <SelectStartForSingleAccount
                  key={account.id}
                  account={account}
                  currentEpoch={accountEpochs.find((a) => a.accountId === account.id)}
                  handleSetEpoch={handleSetAccountEpochs}
                  isReconnect={isReconnect}
                />
              ))}
            </Stack>
          </ScrollArea.Viewport>
          <ScrollArea.Scrollbar orientation="vertical">
            <ScrollArea.Thumb variant="shadowed" />
          </ScrollArea.Scrollbar>
        </ScrollArea>
      </Dialog.Body>
      <Dialog.Footer>
        <ButtonGroup>
          <Button
            variant="primary"
            onClick={onConfirm}
            loading={loading || accounts.length === 0}
            disabled={isOptionRequired && isReconnect && !hasAllAccountsSetCuttoffBefore}
          >
            Confirm
          </Button>
        </ButtonGroup>
      </Dialog.Footer>
    </Dialog>
  );
};
