import React from "react";
import Icon, { IconProps } from "./Icon";

export default function CreditCard3({
  viewBox = "0 0 12 12",
  color = "currentColor",
  stroke = color,
  width = 12,
  height = 12,

  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} {...iconProps} viewBox={viewBox} fill="none">
      <path
        d="M1.375 4.87551V8.62356C1.375 9.17584 1.82272 9.62356 2.375 9.62356L9.62305 9.62356C10.1753 9.62356 10.623 9.17584 10.623 8.62356V4.87551M1.375 4.87551V3.37695C1.375 2.82467 1.82272 2.37695 2.375 2.37695H9.624C10.1751 2.37695 10.6224 2.8229 10.6228 3.37405C10.6232 3.87454 10.623 4.37502 10.623 4.87551M1.375 4.87551H10.623M3.375 6.62549H4.875"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </Icon>
  );
}
