import React, { useState } from "react";
import { parseDate } from "@internationalized/date";

import { styled } from "@puzzle/ui";
import { formatAccountNameWithInstitution } from "@puzzle/utils";
import { OffsetArrow } from "@puzzle/icons";

import { Button, Separator } from "ve";

import DescriptionList from "components/common/DescriptionList";

import { AccountType } from "graphql/types";

import useSingleTransaction, {
  UpdateCategoryMetricsLocations,
  UpdateCategoryMetricsView,
  useExtraTransactionState,
  useUpdateCategory,
} from "../hooks/useSingleTransaction";
import {
  CreateRuleInput,
  isUserCategorizationRule,
} from "components/dashboard/Transactions/Rules/hooks";

import { RuleModal } from "components/dashboard/Transactions/Rules/RuleModal";
import useSearchOptions from "../hooks/useSearchOptions";
import { useDetailPaneContext } from "./DetailPaneContext";
import { isCreditCard } from "components/transactions/utils";
import { CCOffsetModal, CCOffsetSteps } from "components/transactions/CCOffsetModal";
import { CategoryFragment } from "graphql/fragments/category.generated";
import { Alert } from "@puzzle/ui";
import useSelf from "components/users/useSelf";

import { HeaderSection } from "./HeaderSection";
import { DetailsSection } from "./DetailsSection";
import { AccountingSection } from "./AccountingSection";
import { ActivitySection } from "./ActivitySection";

const Sections = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "$3",

  [`${DescriptionList}`]: {
    "&[data-direction='horizontal']": {
      $$itemGap: "$space$3",
      $$termWidth: "135px",
    },
  },
});
const CreditCardSection = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  contentAlign: "center",
});

const isCreatingRule = (input: CreateRuleInput | null | string): input is CreateRuleInput => {
  return !!input && typeof input !== "string";
};

const AI_AUTO_ASSIGN_SEEN_KEY = "ai-auto-assign-by-comment-seen";

const DetailBody = () => {
  const [editingRule, setEditingRule] = useState<CreateRuleInput | string | null>(null);

  const { messageSettings, upsertMessageSettings } = useSelf();

  const [showAIAutoAssign, setShowAIAutoAssign] = useState(
    false
    // !messageSettings[AI_AUTO_ASSIGN_SEEN_KEY] && company.features.aiAssistedCategorizationEnabled
  );

  const { transaction, basicTransaction, isEditingSplit } = useDetailPaneContext<true>();

  const updateCategory = useUpdateCategory(transaction);

  const onCategoryChange = (c: CategoryFragment) => {
    if (canEdit && c)
      updateCategory({
        category: c,
        metrics: {
          location: UpdateCategoryMetricsLocations.TransactionsDrawer,
          component: UpdateCategoryMetricsView.CategoryModal,
        },
      });
  };

  const dismissAIAutoAssign = async () => {
    setShowAIAutoAssign(false);
    await upsertMessageSettings(AI_AUTO_ASSIGN_SEEN_KEY, true);
  };

  const {
    // FIXME: useExtraTransactionState
    canEdit,
    categorizedByRule,

    // FIXME: Individually load these mutation hooks.
    // They create state which will re-render the whole sidebar.
  } = useSingleTransaction({ id: transaction?.id });

  const [addOffset, setAddOffset] = useState<boolean>(false);
  const [autoSuggestRule, setAutoSuggestRule] = useState<boolean>(false);

  // todo: loading indication
  if (!transaction) return null;

  const openCreateRule = () => {
    setEditingRule({
      ledgerCoaKey: transaction.detail.category.coaKey!,
      vendorId: transaction.detail.vendor?.id,
      effectiveAt: parseDate(transaction.date).toString(),
      rule: transaction.descriptor,
      matchEntireString: true,
      accountTypes: [transaction.account.type],
    });
  };

  const openEditRule = (id: string) => {
    setEditingRule(id);
  };

  const clearEditingRule = () => {
    setAutoSuggestRule(false);
    setEditingRule(null);
  };

  const handleModalTriggerClick = (ruleId = "", autoSuggestLocation = false) => {
    setAutoSuggestRule(autoSuggestLocation);

    if (hasEditableRule) {
      openEditRule(ruleId);
    } else {
      openCreateRule();
    }
  };

  // Is this a revenue txn or an expense txn?
  // todo these !s can be removed soon
  if (transaction.detail.category.__typename !== "LedgerCategory") {
    throw new Error("Wrong category type");
  }

  const isRevenueTransaction = transaction.detail.category.defaultCashlike === "REVENUE";
  const creditCard = isCreditCard(transaction);
  const hasEditableRule = categorizedByRule && isUserCategorizationRule(categorizedByRule);

  return (
    <>
      <RuleModal
        open={Boolean(editingRule)}
        onOpenChange={(open) => !open && clearEditingRule()}
        initialValues={isCreatingRule(editingRule) ? editingRule : undefined}
        id={!editingRule || isCreatingRule(editingRule) ? undefined : editingRule}
        originTransaction={transaction}
        location={autoSuggestRule ? "transactionDrawerAutoSuggest" : "transactionSideDrawer"}
        onEscapeKeyDown={(e) => e.stopPropagation()}
      />
      <Sections>
        <HeaderSection
          transaction={transaction}
          basicTransaction={basicTransaction || null}
          isRevenueTransaction={isRevenueTransaction}
        />

        <DetailsSection transaction={transaction} />

        <Separator />

        <AccountingSection
          transaction={transaction}
          isRevenueTransaction={isRevenueTransaction}
          hasEditableRule={!!hasEditableRule}
          showRuleModal={() => handleModalTriggerClick(categorizedByRule?.id)}
        />

        <Separator />

        {creditCard && transaction.account.type === AccountType.Depository && (
          <DescriptionList
            direction="horizontal"
            items={[
              [
                "Credit account paid",
                <CreditCardSection key="credit-account-paid">
                  {canEdit ? (
                    <>
                      <CCOffsetModal
                        open={addOffset}
                        onOpenChange={setAddOffset}
                        trigger={
                          <Button variant="minimal" size="small" prefixElement={<OffsetArrow />}>
                            Add offsetting transaction
                          </Button>
                        }
                        isFinalizing
                        transaction={transaction}
                        initialStep={CCOffsetSteps.Offset}
                      />
                    </>
                  ) : (
                    <>
                      {transaction.detail.linkedTransaction && (
                        <>
                          {" "}
                          {formatAccountNameWithInstitution(
                            transaction.detail.linkedTransaction.account
                          )}
                        </>
                      )}
                    </>
                  )}
                </CreditCardSection>,
              ],
            ]}
          />
        )}

        {showAIAutoAssign && (
          <Alert onClose={dismissAIAutoAssign}>
            When you’re assigned a category review, you can write any context in the comment box and
            Puzzle AI will assign the category based on that comment.
          </Alert>
        )}

        <ActivitySection transaction={transaction} />
      </Sections>
    </>
  );
};

export default DetailBody;
