import React, { useMemo } from "react";
import TagEntity, {
  ClassSegment,
  TaggableEntity,
} from "components/common/Classifications/TagEntity";

import { Text } from "ve";

import { subSection, topRow, itemsContainer } from "./classificationsSubsection.css";

const ClassificationsSubsection = ({
  entity,
  readOnly = false,
  hideAddButton = readOnly,
  onSegmentChange,
  classSegments,
}: {
  entity?: TaggableEntity;
  readOnly?: boolean;
  hideAddButton?: boolean;
  onSegmentChange?: (newValue: ClassSegment[]) => void;
  classSegments?: ClassSegment[];
}) => {
  const displaySegments = useMemo(() => {
    if (classSegments && classSegments.length > 0) return classSegments;
    const segments = entity && entity.classSegments.length > 0 ? [...entity.classSegments] : [];
    segments.sort((a, b) => (a.reportingClass.name < b.reportingClass.name ? -1 : 1));
    return segments;
  }, [entity, classSegments]);

  return (
    <div className={subSection}>
      <div className={topRow}>
        <Text variant="bodyS" color="white" weight="heavy">
          Classifications
        </Text>
        {!hideAddButton && (
          <TagEntity
            key="tagger"
            entity={entity}
            hideClassIds={displaySegments.map((reportingClassSegment) => {
              return reportingClassSegment.reportingClass.id;
            })}
            onSegmentChange={onSegmentChange}
            classSegments={classSegments}
            createButtonStyle="smallAdd"
          />
        )}
      </div>

      {displaySegments.length > 0 && (
        <div className={itemsContainer}>
          {displaySegments.map((segment) => {
            return (
              <TagEntity
                data-testid={`classification_tag_${segment.name}`}
                key="tagger"
                entity={entity}
                activeSegment={segment}
                readOnly={readOnly}
                onSegmentChange={onSegmentChange}
                classSegments={classSegments}
                tagStyle="jigsaw2"
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ClassificationsSubsection;
