import React from "react";
import { Alert } from "@puzzle/ui";
import { useIntercom } from "react-use-intercom";
import Link from "components/common/Link";
import { Text } from "ve";

const DisclaimerContent = () => {
  const intercom = useIntercom();
  return (
    <Alert kind="neutral" css={{ padding: "$1 $1h", marginBottom: "$2", width: "100%" }}>
      <Text color="gray300" variant="bodyS">
        The Classifications feature is currently in beta and at this time only supports expenses.
        Please provide us feedback{" "}
        <Link color="blue400" onClick={() => intercom.showNewMessage()}>
          via Intercom
        </Link>{" "}
        or through help@puzzle.io.
      </Text>
    </Alert>
  );
};

export default DisclaimerContent;
