import React from "react";

import {
  CommentBoxMode,
  DetailPaneContextProvider,
  useDetailPaneContextValue,
} from "./DetailPaneContext";
import DetailLayout from "./DetailLayout";
import DetailBody from "./DetailBody";
import { FilePreviewModal } from "components/common/FilePreviewModal";
import { BasicTransactionFragment } from "components/dashboard/Transactions/graphql.generated";
import { useFile } from "components/common/files";

export const DetailPane = ({
  id,
  transactions,
  onClose,
  commentBoxMode,
  splitsOpenByDefault,
}: {
  commentBoxMode?: CommentBoxMode;
  id: string;
  /**
   * List of all transactions visible in the table. Used for next/previous navigation,
   * and prepopulates a few fields.
   */
  transactions?: BasicTransactionFragment[];
  onClose?: () => void;
  splitsOpenByDefault?: boolean;
}) => {
  const contextValue = useDetailPaneContextValue({
    commentBoxMode,
    transactionId: id,
    transactions,
    onClose,
    splitsOpenByDefault,
  });

  const { downloadInfo } = useFile({ file: contextValue.previewFile });

  return (
    <DetailPaneContextProvider value={contextValue}>
      <DetailLayout onPrevious={contextValue.onPrevious} onNext={contextValue.onNext}>
        <DetailBody />
      </DetailLayout>

      {contextValue.previewFile && (
        <FilePreviewModal
          open={Boolean(contextValue.previewFile)}
          contentType={contextValue.previewFile.contentType}
          filename={contextValue.previewFile.filename}
          src={downloadInfo?.signedUrl ?? ""}
          onOpenChange={(open) => {
            if (!open) {
              contextValue.setPreviewFile(undefined);
            }
          }}
        />
      )}
    </DetailPaneContextProvider>
  );
};
