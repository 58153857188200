import React, { CSSProperties, useMemo } from "react";
import { styled, AutocompleteMenu } from "@puzzle/ui";

import { LockedSelectPlaceholder } from "components/common/LockedSelectPlaceholder";

const FREE_SOLO_ID = "add";
const EMPTY_ID = "clear";

export const DropdownButton = styled("button", {
  all: "unset",
  border: "1px solid $gray700",
  borderRadius: "8px",
  width: "200px",
  paddingLeft: "$1h",
  paddingRight: "$1h",
  fontSize: "13px",
  lineHeight: "18px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  cursor: "pointer",
  height: "40px",

  "&:hover": {
    borderColor: "$gray500",
  },

  "&:focus": {
    borderColor: "$gray300",
  },

  transition: "all 0.1s ease-in",
  transitionProperty: "background, border, color, box-shadow",

  variants: {
    light: {
      true: {
        background: "$mauve800",
        color: "$gray100",
        border: "none",
      },
      false: {
        background: "$blackA16",
        color: "$gray100",
      },
    },
  },
  defaultVariants: {
    light: false,
  },
});

type ObjectWithId = { id: string };

export type DropdownFieldProps<T extends ObjectWithId> = {
  onSelect: (value: T | null) => void;
  items: T[];
  value?: T | null;
  canEdit?: boolean;
  onCreate: (name: string) => void;
  label?: string;
  placeholder?: string;
  filter?: string;
  setFilter?: (filter: string) => void;
  // TODO getFreeSoloOption is not an ideal API
  getOptionLabel: (option: T) => string;
  getFreeSoloOption: (value: string) => Omit<T, "id">;
  getEmptyOption?: () => Omit<T, "id">;
  loading?: boolean;
  emptyState?: React.ReactElement;
  customTrigger?: React.ReactElement;
  onBlur?: () => void;
  light?: boolean;
  fullWidth?: boolean;
  menuCss?: CSSProperties;
};

function DropdownField<T extends ObjectWithId>({
  onSelect,
  items,
  canEdit = true,
  onCreate,
  value,
  label,
  placeholder = "Change to...",
  getOptionLabel,
  getEmptyOption,
  getFreeSoloOption,
  filter,
  setFilter,
  loading,
  emptyState,
  customTrigger,
  onBlur,
  menuCss,
  light = false,
  fullWidth = false,
}: DropdownFieldProps<T>) {
  const emptyOption = useMemo(
    () => getEmptyOption && ({ ...getEmptyOption(), id: EMPTY_ID } as T),
    [getEmptyOption]
  );
  const emptyOptionLabel = emptyOption ? getOptionLabel(emptyOption) : "N/A";
  const valueLabel = (value && getOptionLabel(value)) || emptyOptionLabel;

  const trigger = useMemo(() => {
    if (customTrigger) {
      return customTrigger;
    }

    if (emptyState && !value) {
      return emptyState;
    }

    return (
      <DropdownButton
        light={light}
        css={{
          color: valueLabel === emptyOptionLabel ? "$gray500" : "$gray100",
          width: fullWidth ? "100%" : undefined,
        }}
      >
        {valueLabel}
      </DropdownButton>
    );
  }, [emptyState, value, valueLabel, customTrigger, light, emptyOptionLabel]);

  if (!canEdit) {
    return <LockedSelectPlaceholder title={valueLabel} fullWidth={fullWidth} />;
  }

  return (
    <AutocompleteMenu<T, false, false, true>
      inputValue={filter}
      onInputChange={(event, value) => {
        if (event) {
          setFilter?.(value);
        }
      }}
      getFreeSoloOption={
        loading ? undefined : (value) => ({ ...getFreeSoloOption(value), id: FREE_SOLO_ID } as T)
      }
      emptyOption={loading || filter ? undefined : emptyOption}
      getOptionLabel={getOptionLabel}
      getOptionKey={(o) => o.id}
      isOptionEqualToValue={(a, b) => a.id === b.id}
      placeholder={placeholder}
      options={items}
      value={value}
      label={label}
      onChange={(e, value) => {
        // Ignore clear button
        if (!value) {
          return;
        }

        if (typeof value === "string") {
          onCreate(value);
        } else if (value.id === FREE_SOLO_ID) {
          onCreate(getOptionLabel(value));
        } else if (value.id === EMPTY_ID) {
          onSelect(null);
        } else {
          onSelect(value);
        }

        e.stopPropagation();
        // TODO this causes flickers while closing.. delay it or do it on open?
        setFilter?.("");
      }}
      autoHighlight
      freeSolo
      loading={loading}
      onBlur={onBlur}
      css={menuCss}
    >
      {trigger}
    </AutocompleteMenu>
  );
}

export default DropdownField;
