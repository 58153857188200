import React, { useMemo, useState } from "react";
import Big from "big.js";
import { uniqBy } from "lodash";

import { IconButton, styled, Tooltip } from "@puzzle/ui";
import { Add, RemoveCircle } from "@puzzle/icons";
import { parseDate, formatMoney } from "@puzzle/utils";

import { CategoryFragment } from "graphql/types";
import { CategoryModal, CategorySteps } from "components/transactions";
import { useCompanyDateFormatter } from "components/companies";
import Link from "components/common/Link";
import { Route } from "lib/routes";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import { DetachTransactionModal } from "components/dashboard/Accounting/Bills/BillForm/DetachTransactionModal";

import { FullTransactionFragment } from "../graphql.generated";
import useSingleTransaction from "../hooks/useSingleTransaction";
import { Stack, Text } from "ve";

import {
  subSection,
  topRow,
  cardRoot,
  cardSection,
  categoryContainer,
} from "./matchBillsSubsection.css";

import { numberBadge } from "./accountingSection.css";

const Row = styled("div", {
  display: "flex",
  flex: "1 0 100%",
  gap: "$3",
});

export const MatchBillsSubsection = React.memo(function MatchBillsSection({
  transaction,
  categories,
  categoriesByPermaKey,
  canEdit,
  onCategoryChange,
}: {
  transaction: FullTransactionFragment;
  categories: CategoryFragment[];
  categoriesByPermaKey?: Record<string, CategoryFragment>;
  canEdit: boolean;
  onCategoryChange: (c: CategoryFragment) => void;
}) {
  const dateFormatter = useCompanyDateFormatter({
    month: "2-digit",
    day: "2-digit",
    year: "2-digit",
  });
  const [showDetachModal, setShowDetachModal] = useState(false);
  const { refetch } = useSingleTransaction(transaction);

  const hasBills = transaction.linkedBills.length > 0;

  const list = useMemo(
    () => (
      <Stack gap="0">
        {transaction.linkedBills.map((bill) => {
          const categories = categoriesByPermaKey
            ? uniqBy(
                bill.lines.flatMap(({ coaKey }) => (coaKey ? categoriesByPermaKey[coaKey] : [])),
                ({ coaKey }) => coaKey
              )
            : [];

          const bundleId = transaction.links.find((link) => link.bill?.id === bill.id)?.bundleId;

          return (
            <>
              <div className={cardRoot} key={bill.id}>
                <Link
                  target="_blank"
                  href={`${Route.bills}/${bill.id}`}
                  css={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "12px" }}
                >
                  <Text variant="bodyXS" color="white" weight="bold">
                    {dateFormatter.format(parseDate(bill.issueDate))}
                  </Text>

                  <Text variant="bodyXS" color="gray300" weight="bold">
                    {bill.vendor?.name}
                  </Text>

                  {categories.map(({ name }) => (
                    <div className={categoryContainer} key={name}>
                      <Text color="gray200" variant="bodyXS" weight="heavy">
                        {name}
                      </Text>
                    </div>
                  ))}
                </Link>
                <div className={cardSection}>
                  <Text variant="bodyXS" color="white" weight="bold">
                    {formatMoney({
                      currency: "USD",
                      amount: Big(bill.amount.amount).abs(),
                    })}
                  </Text>
                  {isPosthogFeatureFlagEnabled(FeatureFlag.UnlinkBills) && (
                    <Tooltip content="Detach">
                      <IconButton
                        css={{ marginLeft: "auto", marginTop: "-4px" }}
                        onClick={() => setShowDetachModal(true)}
                      >
                        <RemoveCircle />
                      </IconButton>
                    </Tooltip>
                  )}{" "}
                </div>
              </div>

              <DetachTransactionModal
                billId={bill.id}
                bundleId={bundleId}
                open={showDetachModal}
                onOpenChange={setShowDetachModal}
                refetch={refetch}
              />
            </>
          );
        })}
      </Stack>
    ),
    [transaction, categories, categoriesByPermaKey, dateFormatter, showDetachModal, refetch]
  );

  return (
    <div className={subSection}>
      <div className={topRow}>
        <Text variant="bodyS" color="white" weight="heavy">
          Bills
        </Text>
        {!hasBills && (
          <CategoryModal
            trigger={
              <div className={numberBadge} style={{ marginLeft: "4px" }}>
                <Add color="rgba(255, 255, 255, 0.8)" size={12} />
              </div>
            }
            categories={categories}
            initialValue={transaction.detail.category}
            transaction={transaction}
            initialStep={CategorySteps.MatchToBills}
            onChange={onCategoryChange}
          />
        )}
      </div>
      {hasBills && list}
    </div>
  );
});
