import { NextApiRequest, NextApiResponse, NextPageContext } from "next";
import { setCookie, destroyCookie, parseCookies } from "nookies";
import { getLocalTimeZone, now } from "@internationalized/date";
import { IntegrationType } from "graphql/types";
import config from "./config";

export enum Cookies {
  ApiPartnerLinkTokenConfig = "pz:api_partner_link_token_config",
  Verification = "pz:verification",
  RequestedIntegration = "pz:requested_integration",
  OldPartnerAuth = "auth",
  AuthMode = "a0:mode",
  ThirdPartyLogin = "pz:third_party_login",
  ApiPartnerOAuthRequestId = "pz:oauth_request_id",
  ApiPartnerAuthType = "pz:api_partner_auth_type",
  ApiPartnerSettings = "pz:api_partner_settings",
  PartnerOnboardingId = "pz:partner_onboarding_id",
  InvitationSecret = "pz:invitation",
  PassedFitTest = "pz:passed_fit_test",
  DismissedSiteStatusMessage = "pz:dismissed_site_status_message",

  // used to determine where to send the user
  // after being redirected back after connecting and integration
  SavedPage = "pz:saved_page",
  PostLoginRedirectUrl = "pz:post_login_redirect_url",
  NewWelcomeTour = "pz:new_welcome_tour",

  // Ad targeting and tracking
  FacebookClickId = "_fbc",
  FacebookPixelId = "_fbp",

  /**
   * @deprecated
   */
  VerificationOld = "verification",

  // Referral and Affliate Tracking
  PartnerStackXid = "ps_xid",
  PartnerStackPartnerKey = "growSumoPartnerKey",
}

export const saveRequestedIntegrationCookie = (
  ctx: NextPageContext | { req: NextApiRequest; res: NextApiResponse } | null,
  value: IntegrationType
) => {
  return setCookie(ctx, Cookies.RequestedIntegration, value, {
    maxAge: 10 * 60, // only needed briefly
  });
};

export const destroyRequestedIntegrationCookie = (
  ctx?: NextPageContext | { req: NextApiRequest; res: NextApiResponse }
) => {
  return destroyCookie(ctx, Cookies.RequestedIntegration);
};

type SavedRoute = { route: string; domain: string };
export const savePageToReturnTo = (route: string) => {
  const currentDomain = config.ROOT_URL;
  const toSave: SavedRoute = { route, domain: currentDomain };
  return setCookie(null, Cookies.SavedPage, JSON.stringify(toSave), {
    httpOnly: false,
    path: "/",
  });
};

export const getSavedPageToReturnTo = (ctx?: NextPageContext): SavedRoute | null => {
  const saved = parseCookies(ctx)[Cookies.SavedPage];
  return saved ? JSON.parse(saved) : null;
};

export const destroySavedPage = (
  ctx?: NextPageContext | { req: NextApiRequest; res: NextApiResponse }
) => {
  return destroyCookie(ctx, Cookies.SavedPage);
};

export const savePostLoginRedirectUrl = (redirectUrl: string) => {
  return setCookie(null, Cookies.PostLoginRedirectUrl, redirectUrl, {
    httpOnly: false,
    path: "/",
    expires: now(getLocalTimeZone()).add({ minutes: 15 }).toDate(),
  });
};

export const getPostLoginRedirectUrl = (ctx?: NextPageContext): string | undefined => {
  const redirectUrl = parseCookies(ctx)[Cookies.PostLoginRedirectUrl];
  return redirectUrl;
};

export const destroyPostLoginRedirectUrl = (
  ctx?: NextPageContext | { req: NextApiRequest; res: NextApiResponse }
) => {
  return destroyCookie(ctx, Cookies.PostLoginRedirectUrl);
};

export const dismissSiteStatusMessage = (id: string) => {
  return setCookie(null, `${Cookies.DismissedSiteStatusMessage}-${id}`, "true", {
    httpOnly: false,
    path: "/",
    expires: now(getLocalTimeZone()).add({ months: 1 }).toDate(),
  });
};

export const isSiteStatusMessageDismissed = (id: string) => {
  const dismissed = parseCookies()[`${Cookies.DismissedSiteStatusMessage}-${id}`];
  return !!dismissed;
};

export const getWelcomeTourCookie = () => {
  return parseCookies()[Cookies.NewWelcomeTour] === "true";
};

export const setWelcomeTourCookie = (enabled = true) => {
  setCookie(null, Cookies.NewWelcomeTour, String(enabled));
};
