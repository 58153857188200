import React, { useCallback, useState } from "react";
import { useActiveCompany } from "components/companies";
import { Button, Stack, vars } from "ve";
import { DateInput, Dialog, useToasts } from "@puzzle/ui";
import { CalendarDate, parseDate, toCalendarDate } from "@puzzle/utils";
import { today } from "@internationalized/date";
import { useDisposeFixedAssetMutation } from "components/dashboard/Accounting/FixedAssetsV2/graphql.generated";
import Analytics from "lib/analytics";
import { GetFixedAssetByIdQuery } from "../../graphql.generated";
import { zIndex } from "@puzzle/utils";

type DisposeFixedAssetDialogProps = {
    open: boolean;
    asset: GetFixedAssetByIdQuery;
    onCancel: () => void;
    onFixedAssetDisposed: () => void
}

export const DisposeFixedAssetDialog = ({
    open,
    asset,
    onCancel,
    onFixedAssetDisposed
}: DisposeFixedAssetDialogProps) => {
    const { timeZone } = useActiveCompany<true>();
    const { toast } = useToasts();
    const [disposedAt, setDisposedAt] = useState<CalendarDate>(today(timeZone));
    const [disposeFixedAsset, { loading: isDisposing }] = useDisposeFixedAssetMutation();

    const minDate = asset.fixedAsset.inServiceAt
        ? toCalendarDate(parseDate(asset.fixedAsset.inServiceAt))
        : undefined;

    const handleDispose = useCallback(async () => {
        if (isDisposing || !disposedAt) return;

        return await disposeFixedAsset({
            variables: {
                input: {
                    id: asset.fixedAsset.id,
                    disposedAt: disposedAt.toString(),
                }
            },
            onCompleted: ({ disposeFixedAsset }) => {
                Analytics.fixedAssetDisposed({ fixedAssetId: disposeFixedAsset.id });

                toast({
                    status: "success",
                    message: "Fixed asset disposed of successfully. The changes are being processed and will be reflected on the general ledger page shortly.",
                });

                onFixedAssetDisposed();
            },
            onError: () => {
                toast({
                    message: `Something went wrong, and our team has been notified. We apologize for the inconvenience. Please try again later.`,
                    status: "warning",
                });
            }
        });
    }, [disposeFixedAsset, onFixedAssetDisposed]);

    return (
        <Dialog
            style={{ zIndex: zIndex("modal") }}
            size="small"
            open={open}
            onOpenChange={onCancel}
        >
            <Dialog.Title>Assets disposal</Dialog.Title>
            <Dialog.Body>
                <Stack direction="horizontal" gap="2">
                    <DateInput
                        css={{ width: "fit-content" }}
                        size="small"
                        value={disposedAt}
                        onChange={(value) => {
                            if (value) {
                                setDisposedAt(toCalendarDate(value));
                            }
                        }}
                        minDate={minDate}
                    />
                    <Button
                        variant="primary"
                        loading={isDisposing}
                        onClick={handleDispose}
                        disabled={!disposedAt}
                    >
                        Submit
                    </Button>
                </Stack>
            </Dialog.Body>
        </Dialog>
    );
};
