import React, { useMemo } from "react";
import { Dialog, Button, styled } from "@puzzle/ui";
import { Download } from "@puzzle/icons";
import { usePrevious } from "react-use";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import { zIndex } from "@puzzle/utils";

const Frame = styled("iframe", {
  width: "100%",
  height: "800px",
});

const NoPreview = styled("div", {
  width: "100%",
  height: "200px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",

  img: {
    width: "100%",
    height: "auto",
    margin: 0,
  },

  "& > *": {
    marginTop: "$2",
  },
});

// This is probably extractable. Don't use context here.
export const FilePreviewModal = ({
  filename,
  contentType,
  src,
  ...props
}: React.ComponentProps<typeof Dialog> & {
  filename?: string | null;
  contentType?: string;
  src?: string;
}) => {
  const preview = useMemo(() => {
    const isPdf = contentType?.match(`pdf`);
    const isImage = contentType?.match(`image`);

    if (isPdf) {
      // pdf viewer has its only download built in
      return <Frame src={src} />;
    } else if (isImage) {
      return (
        <a href={src} download={filename}>
          <Container>
            <img src={src} />
          </Container>
        </a>
      );
    }

    return <NoPreview>No preview available for this file.</NoPreview>;
  }, [contentType, filename, src]);

  const content = useMemo(() => {
    return (
      <>
        <Dialog.Title basic showClose>
          {src && (
            // our IconButton definition is incorrect/inconsistent
            <Button
              variant="minimal"
              css={{
                width: "32px",
                height: "32px",
                justifyContent: "center",
              }}
              as="a"
              href={src}
              download={filename}
            >
              <Download />
            </Button>
          )}
        </Dialog.Title>
        <Dialog.Body css={{ padding: "$3", paddingTop: 0 }}>{preview}</Dialog.Body>
      </>
    );
  }, [filename, preview, src]);

  const lastContent = usePrevious(content);

  return (
    <Dialog
      style={{ zIndex: isPosthogFeatureFlagEnabled(FeatureFlag.Z) ? zIndex("modal") : "auto" }}
      {...props}
      // TODO remove after Drawer uses Dialog
      onEscapeKeyDown={(e) => {
        e.stopPropagation();
        props.onOpenChange?.(false);
      }}
    >
      {src ? content : lastContent}
    </Dialog>
  );
};
