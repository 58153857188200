import * as Types from '../../../../graphql/types';

import { CalendarMonthString, CalendarDateTimeString, CalendarDateString } from "scalars";
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountTransactionDetailQueryVariables = Types.Exact<{
  input: Types.AccountTransactionDetailInput;
}>;


export type AccountTransactionDetailQuery = { __typename?: 'Query', accountTransactionDetail: { __typename?: 'AccountTransactionDetails', accountTransactionDetails: Array<{ __typename?: 'AccountTransactionDetail', id: string, createdAt?: CalendarDateTimeString | null, effectiveAt?: CalendarDateTimeString | null, postedAt?: CalendarDateTimeString | null, updatedAt?: CalendarDateTimeString | null, ledger?: Types.LedgerType | null, view?: Types.View | null, companyId: string, entityId?: string | null, eventId: string, type?: string | null, amount?: string | null, currency?: string | null, description?: string | null, accountId?: string | null, displayName?: string | null, displayId?: string | null, tags?: Array<string> | null, reversedEntryId?: string | null, correctionEventId?: string | null, departmentId?: string | null, productId?: string | null, counterpartyId?: string | null, counterpartyType?: string | null, transaction?: { __typename?: 'Transaction', descriptor: string } | null, journal: { __typename?: 'LedgerJournal', id: string, description?: string | null }, event: { __typename?: 'LedgerEvent', invoice?: { __typename?: 'Invoice', description?: string | null } | null, prepaidExpense?: { __typename?: 'Prepaid', description?: string | null } | null, fixedAsset?: { __typename?: 'FixedAsset', description?: string | null } | null } }> } };

export type AccountTransactionDetailForCsvQueryVariables = Types.Exact<{
  input: Types.AccountTransactionDetailInput;
}>;


export type AccountTransactionDetailForCsvQuery = { __typename?: 'Query', accountTransactionDetailForCSV: { __typename?: 'FileDownloadInfo', signedUrl: string } };

export type AccountTransactionDetailFragment = { __typename?: 'AccountTransactionDetail', id: string, createdAt?: CalendarDateTimeString | null, effectiveAt?: CalendarDateTimeString | null, postedAt?: CalendarDateTimeString | null, updatedAt?: CalendarDateTimeString | null, ledger?: Types.LedgerType | null, view?: Types.View | null, companyId: string, entityId?: string | null, eventId: string, type?: string | null, amount?: string | null, currency?: string | null, description?: string | null, accountId?: string | null, displayName?: string | null, displayId?: string | null, tags?: Array<string> | null, reversedEntryId?: string | null, correctionEventId?: string | null, departmentId?: string | null, productId?: string | null, counterpartyId?: string | null, counterpartyType?: string | null, transaction?: { __typename?: 'Transaction', descriptor: string } | null, journal: { __typename?: 'LedgerJournal', id: string, description?: string | null }, event: { __typename?: 'LedgerEvent', invoice?: { __typename?: 'Invoice', description?: string | null } | null, prepaidExpense?: { __typename?: 'Prepaid', description?: string | null } | null, fixedAsset?: { __typename?: 'FixedAsset', description?: string | null } | null } };

export type AccountTransactionDetailJournalFragment = { __typename?: 'LedgerJournal', id: string, description?: string | null, effectiveAt: CalendarDateTimeString, postedAt: CalendarDateTimeString, shortId: string, reversedJournal?: { __typename?: 'LedgerJournal', id: string, shortId: string } | null, event: { __typename?: 'LedgerEvent', externalId: string, shortExternalId: string, type?: string | null, transaction?: { __typename?: 'Transaction', id: string, descriptor: string } | null, bill?: { __typename?: 'Bill', id: string, description?: string | null } | null, fixedAsset?: { __typename?: 'FixedAsset', id: string, description?: string | null } | null, invoice?: { __typename?: 'Invoice', id: any, description?: string | null } | null, prepaidExpense?: { __typename?: 'Prepaid', id: any, description?: string | null } | null }, entries: Array<{ __typename?: 'LedgerEntry', id: string, accountId: string, amount?: string | null }> };

export type AccountTransactionDetailJournalEntryFragment = { __typename?: 'LedgerEntry', id: string, accountId: string, amount?: string | null };

export type GetCompanyJournalQueryVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
  journalId: Types.Scalars['ID'];
}>;


export type GetCompanyJournalQuery = { __typename?: 'Query', company?: { __typename?: 'Company', id: string, journal: { __typename?: 'LedgerJournal', id: string, description?: string | null, effectiveAt: CalendarDateTimeString, postedAt: CalendarDateTimeString, shortId: string, reversedJournal?: { __typename?: 'LedgerJournal', id: string, shortId: string } | null, event: { __typename?: 'LedgerEvent', externalId: string, shortExternalId: string, type?: string | null, transaction?: { __typename?: 'Transaction', id: string, descriptor: string } | null, bill?: { __typename?: 'Bill', id: string, description?: string | null } | null, fixedAsset?: { __typename?: 'FixedAsset', id: string, description?: string | null } | null, invoice?: { __typename?: 'Invoice', id: any, description?: string | null } | null, prepaidExpense?: { __typename?: 'Prepaid', id: any, description?: string | null } | null }, entries: Array<{ __typename?: 'LedgerEntry', id: string, accountId: string, amount?: string | null }> } } | null };

export type GetFixedAssetByIdQueryVariables = Types.Exact<{
  fixedAssetId: Types.Scalars['FixedAssetID'];
}>;


export type GetFixedAssetByIdQuery = { __typename?: 'Query', fixedAsset: { __typename?: 'FixedAsset', id: string, createdAt: CalendarDateTimeString, updatedAt: CalendarDateTimeString, removedAt?: CalendarDateTimeString | null, status: Types.FixedAssetStatus, description?: string | null, type?: Types.FixedAssetType | null, originalValue?: string | null, salvageValue?: string | null, capitalizedAt?: CalendarDateString | null, inServiceAt?: CalendarDateString | null, disposedAt?: CalendarDateString | null, usefulLifeMonths?: number | null, depreciationMethod?: Types.FixedAssetDepreciationMethod | null, proceeds?: string | null, pieces: Array<{ __typename?: 'FixedAssetPiece', id: any, createdAt: CalendarDateTimeString, updatedAt: CalendarDateTimeString, removedAt?: CalendarDateTimeString | null, status: Types.FixedAssetStatus, description?: string | null, billLine?: { __typename?: 'BillLine', id: any, description?: string | null, createdAt: CalendarDateTimeString, coaKey?: string | null, amount: { __typename?: 'Money', amount: string, currency: any }, bill: { __typename?: 'Bill', id: string, vendor?: { __typename?: 'Vendor', name: string } | null } } | null, detail?: { __typename?: 'TransactionDetail', id: string, transactionId: string, amount: string, descriptor: string, ledgerCoaKey: string, postedAt?: CalendarDateTimeString | null, vendor?: { __typename?: 'Vendor', name: string } | null, category: { __typename?: 'GatewayCategory', name: string, permaKey: string, coaDisplayId?: string | null, coaKey?: string | null, displayName?: string | null, displayId?: string | null, shortName?: string | null } | { __typename?: 'LedgerCategory', name: string, permaKey: string, coaDisplayId?: string | null, coaKey: string, displayName: string, displayId: string, shortName: string } } | null }> } };

export type GetFixedAssetDeprecionScheduleQueryVariables = Types.Exact<{
  input: Types.FixedAssetDepreciationSchedulePreviewInput;
}>;


export type GetFixedAssetDeprecionScheduleQuery = { __typename?: 'Query', fixedAssetDepreciationSchedulePreview: { __typename?: 'fixedAssetDepreciationSchedulePreview', startDate: CalendarDateString, endDateInclusive: CalendarDateString, method: Types.FixedAssetDepreciationMethod, firstMonthConvention: Types.FirstMonthConvention, scheduleItems: Array<{ __typename?: 'fixedAssetDepreciationSchedulePreviewItems', period: CalendarMonthString, fixedAssetBookValue: string, depreciationExpense: string, accumulatedDepreciation: string }> } };

export const AccountTransactionDetailFragmentDoc = gql`
    fragment accountTransactionDetail on AccountTransactionDetail {
  id
  createdAt
  effectiveAt
  postedAt
  updatedAt
  ledger
  view
  companyId
  entityId
  eventId
  type
  amount
  currency
  description
  accountId
  displayName
  displayId
  tags
  reversedEntryId
  correctionEventId
  departmentId
  productId
  counterpartyId
  counterpartyType
  transaction {
    descriptor
  }
  journal {
    id
    description
  }
  event {
    invoice {
      description
    }
    prepaidExpense {
      description
    }
    fixedAsset {
      description
    }
  }
}
    `;
export const AccountTransactionDetailJournalEntryFragmentDoc = gql`
    fragment accountTransactionDetailJournalEntry on LedgerEntry {
  id
  accountId
  amount
}
    `;
export const AccountTransactionDetailJournalFragmentDoc = gql`
    fragment accountTransactionDetailJournal on LedgerJournal {
  id
  description
  effectiveAt
  postedAt
  shortId @client
  reversedJournal {
    id
    shortId @client
  }
  event {
    externalId
    shortExternalId @client
    type
    transaction {
      id
      descriptor
    }
    bill {
      id
      description
    }
    fixedAsset {
      id
      description
    }
    invoice {
      id
      description
    }
    prepaidExpense {
      id
      description
    }
  }
  entries {
    ...accountTransactionDetailJournalEntry
  }
}
    ${AccountTransactionDetailJournalEntryFragmentDoc}`;
export const AccountTransactionDetailDocument = gql`
    query accountTransactionDetail($input: AccountTransactionDetailInput!) {
  accountTransactionDetail(input: $input) {
    accountTransactionDetails {
      ...accountTransactionDetail
    }
  }
}
    ${AccountTransactionDetailFragmentDoc}`;

/**
 * __useAccountTransactionDetailQuery__
 *
 * To run a query within a React component, call `useAccountTransactionDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountTransactionDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountTransactionDetailQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccountTransactionDetailQuery(baseOptions: Apollo.QueryHookOptions<AccountTransactionDetailQuery, AccountTransactionDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountTransactionDetailQuery, AccountTransactionDetailQueryVariables>(AccountTransactionDetailDocument, options);
      }
export function useAccountTransactionDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountTransactionDetailQuery, AccountTransactionDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountTransactionDetailQuery, AccountTransactionDetailQueryVariables>(AccountTransactionDetailDocument, options);
        }
export type AccountTransactionDetailQueryHookResult = ReturnType<typeof useAccountTransactionDetailQuery>;
export type AccountTransactionDetailLazyQueryHookResult = ReturnType<typeof useAccountTransactionDetailLazyQuery>;
export type AccountTransactionDetailQueryResult = Apollo.QueryResult<AccountTransactionDetailQuery, AccountTransactionDetailQueryVariables>;
export const AccountTransactionDetailForCsvDocument = gql`
    query accountTransactionDetailForCSV($input: AccountTransactionDetailInput!) {
  accountTransactionDetailForCSV(input: $input) {
    signedUrl
  }
}
    `;

/**
 * __useAccountTransactionDetailForCsvQuery__
 *
 * To run a query within a React component, call `useAccountTransactionDetailForCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountTransactionDetailForCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountTransactionDetailForCsvQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccountTransactionDetailForCsvQuery(baseOptions: Apollo.QueryHookOptions<AccountTransactionDetailForCsvQuery, AccountTransactionDetailForCsvQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountTransactionDetailForCsvQuery, AccountTransactionDetailForCsvQueryVariables>(AccountTransactionDetailForCsvDocument, options);
      }
export function useAccountTransactionDetailForCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountTransactionDetailForCsvQuery, AccountTransactionDetailForCsvQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountTransactionDetailForCsvQuery, AccountTransactionDetailForCsvQueryVariables>(AccountTransactionDetailForCsvDocument, options);
        }
export type AccountTransactionDetailForCsvQueryHookResult = ReturnType<typeof useAccountTransactionDetailForCsvQuery>;
export type AccountTransactionDetailForCsvLazyQueryHookResult = ReturnType<typeof useAccountTransactionDetailForCsvLazyQuery>;
export type AccountTransactionDetailForCsvQueryResult = Apollo.QueryResult<AccountTransactionDetailForCsvQuery, AccountTransactionDetailForCsvQueryVariables>;
export const GetCompanyJournalDocument = gql`
    query getCompanyJournal($companyId: ID!, $journalId: ID!) {
  company(id: $companyId) {
    id
    journal(id: $journalId) {
      ...accountTransactionDetailJournal
    }
  }
}
    ${AccountTransactionDetailJournalFragmentDoc}`;

/**
 * __useGetCompanyJournalQuery__
 *
 * To run a query within a React component, call `useGetCompanyJournalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyJournalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyJournalQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      journalId: // value for 'journalId'
 *   },
 * });
 */
export function useGetCompanyJournalQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyJournalQuery, GetCompanyJournalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyJournalQuery, GetCompanyJournalQueryVariables>(GetCompanyJournalDocument, options);
      }
export function useGetCompanyJournalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyJournalQuery, GetCompanyJournalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyJournalQuery, GetCompanyJournalQueryVariables>(GetCompanyJournalDocument, options);
        }
export type GetCompanyJournalQueryHookResult = ReturnType<typeof useGetCompanyJournalQuery>;
export type GetCompanyJournalLazyQueryHookResult = ReturnType<typeof useGetCompanyJournalLazyQuery>;
export type GetCompanyJournalQueryResult = Apollo.QueryResult<GetCompanyJournalQuery, GetCompanyJournalQueryVariables>;
export const GetFixedAssetByIdDocument = gql`
    query getFixedAssetById($fixedAssetId: FixedAssetID!) {
  fixedAsset(id: $fixedAssetId) {
    id
    createdAt
    updatedAt
    removedAt
    status
    description
    type
    originalValue
    salvageValue
    capitalizedAt
    inServiceAt
    disposedAt
    usefulLifeMonths
    depreciationMethod
    proceeds
    pieces {
      id
      createdAt
      updatedAt
      removedAt
      status
      description
      billLine {
        id
        description
        amount {
          amount
          currency
        }
        createdAt
        coaKey
        bill {
          id
          vendor {
            name
          }
        }
      }
      detail {
        id
        transactionId
        amount
        descriptor
        ledgerCoaKey
        postedAt
        vendor {
          name
        }
        category {
          name
          permaKey
          coaDisplayId
          coaKey
          displayName
          displayId
          shortName
        }
      }
    }
  }
}
    `;

/**
 * __useGetFixedAssetByIdQuery__
 *
 * To run a query within a React component, call `useGetFixedAssetByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFixedAssetByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFixedAssetByIdQuery({
 *   variables: {
 *      fixedAssetId: // value for 'fixedAssetId'
 *   },
 * });
 */
export function useGetFixedAssetByIdQuery(baseOptions: Apollo.QueryHookOptions<GetFixedAssetByIdQuery, GetFixedAssetByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFixedAssetByIdQuery, GetFixedAssetByIdQueryVariables>(GetFixedAssetByIdDocument, options);
      }
export function useGetFixedAssetByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFixedAssetByIdQuery, GetFixedAssetByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFixedAssetByIdQuery, GetFixedAssetByIdQueryVariables>(GetFixedAssetByIdDocument, options);
        }
export type GetFixedAssetByIdQueryHookResult = ReturnType<typeof useGetFixedAssetByIdQuery>;
export type GetFixedAssetByIdLazyQueryHookResult = ReturnType<typeof useGetFixedAssetByIdLazyQuery>;
export type GetFixedAssetByIdQueryResult = Apollo.QueryResult<GetFixedAssetByIdQuery, GetFixedAssetByIdQueryVariables>;
export const GetFixedAssetDeprecionScheduleDocument = gql`
    query getFixedAssetDeprecionSchedule($input: FixedAssetDepreciationSchedulePreviewInput!) {
  fixedAssetDepreciationSchedulePreview(input: $input) {
    startDate
    endDateInclusive
    method
    firstMonthConvention
    scheduleItems {
      period
      fixedAssetBookValue
      depreciationExpense
      accumulatedDepreciation
    }
  }
}
    `;

/**
 * __useGetFixedAssetDeprecionScheduleQuery__
 *
 * To run a query within a React component, call `useGetFixedAssetDeprecionScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFixedAssetDeprecionScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFixedAssetDeprecionScheduleQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetFixedAssetDeprecionScheduleQuery(baseOptions: Apollo.QueryHookOptions<GetFixedAssetDeprecionScheduleQuery, GetFixedAssetDeprecionScheduleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFixedAssetDeprecionScheduleQuery, GetFixedAssetDeprecionScheduleQueryVariables>(GetFixedAssetDeprecionScheduleDocument, options);
      }
export function useGetFixedAssetDeprecionScheduleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFixedAssetDeprecionScheduleQuery, GetFixedAssetDeprecionScheduleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFixedAssetDeprecionScheduleQuery, GetFixedAssetDeprecionScheduleQueryVariables>(GetFixedAssetDeprecionScheduleDocument, options);
        }
export type GetFixedAssetDeprecionScheduleQueryHookResult = ReturnType<typeof useGetFixedAssetDeprecionScheduleQuery>;
export type GetFixedAssetDeprecionScheduleLazyQueryHookResult = ReturnType<typeof useGetFixedAssetDeprecionScheduleLazyQuery>;
export type GetFixedAssetDeprecionScheduleQueryResult = Apollo.QueryResult<GetFixedAssetDeprecionScheduleQuery, GetFixedAssetDeprecionScheduleQueryVariables>;