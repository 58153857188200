import { useCallback, useMemo, useState, useRef } from "react";
import { SetOptional } from "type-fest";
import { uniqueId, compact, omit } from "lodash";
import {
  MutationHookOptions,
  QueryHookOptions,
  useFragment,
  useApolloClient,
} from "@apollo/client";

import { useActiveCompany } from "components/companies";
import { noVendor } from "components/transactions/vendors";

import {
  CreateVendorMutation,
  CreateVendorMutationVariables,
  GetVendorsQuery,
  GetVendorsQueryVariables,
  useCreateVendorMutation,
  useGetVendorsQuery,
  useGetVendorQuery,
} from "./graphql.generated";
import {
  CreateVendorInput,
  Vendor,
  VendorSource,
  VendorFragment,
  VendorFragmentDoc,
  VendorSortOrder,
  VendorStatus,
  VendorType,
  YesNoUnknown,
} from "graphql/types";
import { UseQueryStatesKeysMap, queryTypes, useQueryStates } from "next-usequerystate";
import { useSetState, useUpdateEffect } from "react-use";
import { SortingState } from "@tanstack/react-table";
import { mergeDeep } from "@apollo/client/utilities";

const DEFAULT_PER_PAGE = 50;

export const makePermaName = (name: string): string => {
  return name.replace(/[^a-zA-Z0-9]/gi, "_").toLowerCase();
};

export const makeOptimisticVendor = (vendor: Vendor): VendorFragment => ({
  __typename: "Vendor",
  id: uniqueId("vendor"),
  name: vendor.name,
  permaName: makePermaName(vendor.name),
  is1099Vendor: vendor.is1099Vendor,
  type: vendor.type,
  status: vendor.status,
});

export function useVendorFragment(id?: string | null) {
  return useFragment<VendorFragment, VendorFragment>({
    fragment: VendorFragmentDoc,
    fragmentName: "vendor",
    from: `Vendor:${id}`,
  });
}

export function useCreateVendor(
  baseOptions?: MutationHookOptions<CreateVendorMutation, CreateVendorMutationVariables>
) {
  const { company } = useActiveCompany<true>();

  const [_createVendor, result] = useCreateVendorMutation({
    optimisticResponse: ({ input }) => ({
      createVendor: {
        vendor: makeOptimisticVendor(input as unknown as Vendor),
      },
    }),

    ...baseOptions,
  });
  const createVendor = useCallback(
    (input: Omit<CreateVendorInput, "companyId">) =>
      _createVendor({
        variables: {
          input: {
            ...input,
            companyId: company.id,
          },
        },
      }),
    [_createVendor, company.id]
  );

  return [createVendor, result] as const;
}

export function useVendors({
  variables,
  ...baseOptions
}: Omit<QueryHookOptions<GetVendorsQuery, GetVendorsQueryVariables>, "variables"> & {
  variables?: SetOptional<GetVendorsQueryVariables, "companyId">;
}) {
  const { company } = useActiveCompany<true>();

  return useGetVendorsQuery({
    ...baseOptions,

    variables: {
      companyId: company.id,
      ...variables,
    },
  });
}

export function useGetVendor(vendorId: string) {
  const { company } = useActiveCompany<true>();

  const { data, loading } = useGetVendorQuery({
    variables: {
      companyId: company.id,
      filterBy: {
        ids: [vendorId],
      },
    },
  });

  const items = data?.company?.vendors.items;
  const vendor = items && items.length > 0 ? items[0] : null;

  return { loading, vendor };
}

export interface FilterState {
  name?: string | null;
  hasBills?: boolean | null;
  type?: VendorType | null;
  status?: VendorStatus | null;
  source?: VendorSource | null;
  is1099Vendor?: YesNoUnknown | null;
  sort: VendorSortOrder;
}

const useQueryFilterState = () => {
  return useQueryStates<UseQueryStatesKeysMap>({
    name: queryTypes.string,
    hasBills: queryTypes.boolean,
    type: queryTypes.stringEnum<VendorType>(Object.values(VendorType)),
    status: queryTypes.stringEnum<VendorStatus>(Object.values(VendorStatus)),
    source: queryTypes.stringEnum<VendorSource>(Object.values(VendorSource)),
    is1099Vendor: queryTypes.stringEnum<YesNoUnknown>(Object.values(YesNoUnknown)),
    sort: queryTypes.stringEnum<VendorSortOrder>(Object.values(VendorSortOrder)),
  });
};

export const useVendorsFilter = () => {
  const [queryParamState, setQueryParamState] = useQueryFilterState();
  const initialState: FilterState = {
    hasBills: queryParamState.hasBills ?? undefined,
    sort: VendorSortOrder.NameAsc,
    ...(!queryParamState.hasBills
      ? {
          name: queryParamState.name ?? undefined,
          type: queryParamState.type ?? undefined,
          status: queryParamState.status ?? undefined,
          source: queryParamState.source ?? undefined,
          is1099Vendor: queryParamState.is1099Vendor ?? undefined,
        }
      : {}),
  };
  const [sortOptions, setSortOptions] = useState<SortingState>(() => {
    let desc = false;

    switch (queryParamState.sort) {
      case VendorSortOrder.NameAsc:
        desc = false;
        break;
      case VendorSortOrder.NameDesc:
        desc = true;
        break;
    }

    return [{ id: "name", desc }];
  });

  const sortBy: VendorSortOrder = useMemo(() => {
    const { desc } = sortOptions?.[0] || {};
    return desc ? VendorSortOrder.NameDesc : VendorSortOrder.NameAsc;
  }, [sortOptions]);

  const [filter, setFilter] = useSetState<FilterState>(initialState);

  useUpdateEffect(() => {
    setQueryParamState({ ...filter, sort: sortBy });
  }, [filter, sortBy]);

  return useMemo(() => {
    return {
      filter,
      setFilter,
      sortOptions,
      setSortOptions,
      sortBy,
    };
  }, [sortOptions, setSortOptions, sortBy, filter, setFilter]);
};

export const useVendorQuery = (filter: FilterState, sortBy?: VendorSortOrder) => {
  const queryVariables = useMemo(() => {
    return {
      sortBy,
      page: {
        count: DEFAULT_PER_PAGE,
      },
      filterBy: {
        ...omit(filter, "sort"),
        ...(filter?.hasBills ? { hasBills: true } : {}),
      },
    };
  }, [filter, sortBy]);

  const { data, loading, fetchMore, refetch } = useVendors({
    fetchPolicy: "cache-first",
    variables: {
      ...queryVariables,
    },
  });

  const hasMore = data?.company?.vendors.pageInfo.hasNextPage;
  const fetchNextPage = useCallback(() => {
    fetchMore({
      variables: mergeDeep(queryVariables, {
        page: {
          after: data?.company?.vendors.pageInfo.endCursor,
        },
      }),
    });
  }, [fetchMore, data, queryVariables]);

  return useMemo(() => {
    return {
      vendorsData: data?.company?.vendors?.items ?? [],
      vendorsLoading: loading,
      hasMore,
      fetchNextPage,
      refetch,
    };
  }, [data, loading, hasMore, fetchNextPage, refetch]);
};

export const useSortableVendors = () => {
  const { sortOptions, setSortOptions, sortBy, filter, setFilter } = useVendorsFilter();
  const { vendorsData, vendorsLoading, hasMore, fetchNextPage, refetch } = useVendorQuery(
    filter,
    sortBy
  );

  return useMemo(() => {
    return {
      vendorsData,
      vendorsLoading,
      hasMore,
      fetchNextPage,
      refetch,
      sortOptions,
      setSortOptions,
      sortBy,
      filter,
      setFilter,
    };
  }, [
    vendorsData,
    vendorsLoading,
    hasMore,
    fetchNextPage,
    refetch,
    sortOptions,
    setSortOptions,
    sortBy,
    filter,
    setFilter,
  ]);
};

export const useSelectedVendors = (vendorIds: string[]) => {
  const client = useApolloClient();
  const initialVendorsFetched = useRef<boolean>(vendorIds.length === 0);

  const { data: vendorsData } = useVendors({
    fetchPolicy: "cache-first",
    skip: initialVendorsFetched.current,

    variables: {
      filterBy: { ids: vendorIds },
    },
  });

  if (vendorsData) {
    initialVendorsFetched.current = true;
  }

  return compact(
    vendorIds.map((id) => {
      if (id === noVendor.id) {
        return noVendor;
      }
      return client.readFragment<VendorFragment>({
        id: `Vendor:${id}`,
        fragmentName: "vendor",
        fragment: VendorFragmentDoc,
      });
    })
  );
};
