import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Linked2({
  className,
  rotate,
  viewBox = "0 0 16 16",
  color = "currentColor",
  ...props
}: IconProps) {
  return (
    <Icon className={className} rotate={rotate} viewBox={viewBox} fill="none" {...props}>
      <path
        d="M6.50016 3.68215L7.14449 3.03782C8.7509 1.4314 11.3554 1.4314 12.9618 3.03782C14.5683 4.64424 14.5683 7.24875 12.9618 8.85516L12.3161 9.50092M3.68588 6.49642L3.03812 7.14418C1.43171 8.7506 1.43171 11.3551 3.03813 12.9615C4.64454 14.5679 7.24906 14.5679 8.85547 12.9615L9.4985 12.3185M6.33331 9.66634L9.66665 6.33301"
        stroke={color}
        strokeWidth="1.0"
        strokeLinecap="round"
      />
    </Icon>
  );
}
