import React, { useMemo, useState } from "react";
import { useActiveCompany } from "components/companies";
import { IntegrationType } from "graphql/types";

import Link from "components/common/Link";

import { Route } from "lib/routes";
import { connectionCardBannerStyle } from "./connectionCardStyles.css";
import { Crown } from "@puzzle/icons";
import { Button } from "ve/Button";
import { vars } from "ve/theme";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import { useRouter } from "next/router";
import { Alert } from "@puzzle/ui";
import { ConnectRipplingModal } from "../rippling/ConnectRipplingModal";

export const ConnectionCardBanner = ({
  financialInstitutionName,
}: {
  financialInstitutionName: string;
}) => {
  const { company, isOnPaidPlan } = useActiveCompany<true>();
  const router = useRouter();
  const hasRipplingConnection = useMemo(() => {
    return Boolean(
      company?.integrationConnections?.find((c) => c.type === IntegrationType.Rippling)
    );
  }, [company.integrationConnections]);

  const [showRipplingModal, setShowRipplingModal] = useState(false);

  if (
    financialInstitutionName === IntegrationType.QuickBooks &&
    !company.userProposedStartIngestionDate
  ) {
    return (
      <div className={connectionCardBannerStyle}>
        To begin the process of bringing your historical books into Puzzle, check{" "}
        <Link css={{ color: "$green600" }} href={Route.inbox}>
          {" "}
          your inbox
        </Link>
        .
      </div>
    );
  }
  if (financialInstitutionName === IntegrationType.Stripe) {
    return (
      <div className={connectionCardBannerStyle}>
        Note: Puzzle currently only supports USD invoices.{" "}
        <Link
          css={{ color: "$green600" }}
          href={"https://help.puzzle.io/en/articles/9797533-foreign-currency-invoices-in-stripe"}
        >
          Learn more here
        </Link>
      </div>
    );
  }
  if (
    financialInstitutionName === IntegrationType.Rippling &&
    isPosthogFeatureFlagEnabled(FeatureFlag.RipplingIntegrationConnection) &&
    hasRipplingConnection &&
    !isOnPaidPlan &&
    !company.features.ripplingAddOnSelected
  ) {
    const isOnboarding = router.pathname.includes(Route.intro);

    if (isOnboarding) {
      return (
        <Alert>
          Upgrade to a paid plan and select the Rippling add-on after onboarding to unlock your
          Rippling integration.
        </Alert>
      );
    }

    return (
      <>
        <div
          className={connectionCardBannerStyle}
          style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          Upgrade to a paid plan and select the Rippling add-on to unlock your Rippling integration
          <Button
            variant="gold"
            prefixElement={<Crown />}
            onClick={() => setShowRipplingModal(true)}
            css={{
              marginLeft: "auto",
              borderRadius: vars.space["0h"],
              padding: `${vars.space["0h"]} ${vars.space["$1"]}`,
            }}
            size="compact"
          >
            {"Upgrade"}
          </Button>
        </div>
        <ConnectRipplingModal
          open={showRipplingModal}
          onOpenChange={() => {
            setShowRipplingModal(false);
          }}
        />
      </>
    );
  }

  return null;
};
