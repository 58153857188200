import React, { useMemo } from "react";
import { isAfter } from "date-fns";

import { DataTable, createColumnHelper, useDataTable } from "@puzzle/ui";
import { formatMoney, parseCalendarMonth, today } from "@puzzle/utils";

import { formatPeriod } from "components/dashboard/Revenue/shared";
import { useActiveCompany, useCompanyDateFormatter } from "components/companies";
import { FixedAssetStatus } from "graphql/types";

import {
  FixedAssetV2DepreciationSchedulePreviewItemsFragment,
  PagedFixedAssetV2Fragment,
  useFixedAssetDepreciationSchedulePreviewQuery,
} from "./graphql.generated";

type Props = {
  asset?: PagedFixedAssetV2Fragment;
};

export const DepreciationScheduleTable = ({ asset }: Props) => {
  const { data, loading } = useFixedAssetDepreciationSchedulePreviewQuery({
    variables: {
      input: {
        id: asset?.id ?? "",
        ...(asset?.status === FixedAssetStatus.Draft
          ? {
              usefulLifeMonths: asset?.usefulLifeMonths,
              inServiceAt: asset?.inServiceAt,
              method: asset?.depreciationMethod,
            }
          : undefined),
      },
    },
  });

  const dateFormatter = useCompanyDateFormatter({
    month: "short",
    year: "2-digit",
  });

  const { timeZone } = useActiveCompany<true>();
  const currentDay = today(timeZone).toDate(timeZone);

  const tableData = useMemo(
    () => data?.fixedAssetDepreciationSchedulePreview.scheduleItems || [],
    [data?.fixedAssetDepreciationSchedulePreview.scheduleItems]
  );

  const columnHelper = createColumnHelper<FixedAssetV2DepreciationSchedulePreviewItemsFragment>();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("period", {
        size: 30,
        header: "Period",
        cell: ({ getValue }) => {
          return formatPeriod(parseCalendarMonth(getValue()), dateFormatter);
        },
      }),
      columnHelper.accessor("openingBookValue", {
        size: 75,
        header: "Beginning carrying value",
        cell: ({ getValue }) => formatMoney({ currency: "USD", amount: getValue() }),
        meta: {
          align: "right",
        },
      }),
      columnHelper.accessor("depreciationExpense", {
        size: 75,
        header: "Depreciation expense",
        cell: ({ getValue }) => formatMoney({ currency: "USD", amount: getValue() }),
        meta: {
          align: "right",
        },
      }),
      columnHelper.accessor("accumulatedDepreciation", {
        size: 75,
        header: "Accumulated depreciation",
        cell: ({ getValue }) => formatMoney({ currency: "USD", amount: getValue() }),
        meta: {
          align: "right",
        },
      }),
      columnHelper.accessor("fixedAssetBookValue", {
        size: 75,
        header: "Ending carrying value",
        cell: ({ getValue }) => formatMoney({ currency: "USD", amount: getValue() }),
        meta: {
          align: "right",
        },
      }),
    ];
  }, [columnHelper, dateFormatter]);

  const table = useDataTable({
    data: tableData,
    columns,
  });

  return (
    <DataTable
      css={{
        'tr[aria-disabled="true"] td': {
          backgroundColor: "$mauve900",
          color: "$gray400",
        },
        border: "1px solid $rhino600",
        borderRadius: "4px",
      }}
      hideSpacerRows
      stickyHeaders
      bordered
      density="small"
      table={table}
      loading={loading}
      getRowProps={({ original }) => {
        const periodDay = parseCalendarMonth(original.period).toDate(timeZone);
        return {
          "aria-disabled": isAfter(periodDay, currentDay),
        };
      }}
    />
  );
};
